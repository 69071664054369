import { BaseAPI } from "@vidazoo/ui-framework";

class SupervisorAPI extends BaseAPI {
    public deleteAuthAccount() {
        return this.request({
            method: "patch",
            url: `/api/supervisor/delete-auth-account`,
        });
    }
}

export default new SupervisorAPI((window as any).__BASE_API_URL__, true);
