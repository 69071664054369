import {IDictionary, ILabelValuePair} from "@vidazoo/ui-framework";
import {dashboardStore} from "../stores";

export const VerticalType: IDictionary = {
    PLAYER: "player",
    CONNECTION: "connection",
    DISPLAY: "display",
    OPEN_RTB: "open_rtb"
};

export enum UserRole {
    USER = "USER",
    ADMIN = "ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
    ROOT_ADMIN = "ROOT_ADMIN",
    MILKSHAKE_ADMIN = "MILKSHAKE_ADMIN",
    VIEW_AS = "VIEW_AS",
    VIEW_AS_STRICT = "VIEW_AS_STRICT"
}

export const VerticalLabel: IDictionary = {
    [VerticalType.PLAYER]: "Platform",
    [VerticalType.CONNECTION]: "Connection",
    [VerticalType.DISPLAY]: "Display",
    [VerticalType.OPEN_RTB]: "OpenRTB"
};

export const ActivityType: IDictionary = {
    IN_BANNER_VIDEO: "ibv",
    IN_READ: "inRead",
    VPAID: "vpaid",
    VAST: "vast",
    DISPLAY: "display",
    PREBID: "prebid",
    IN_STREAM: "inStream",
    MILKSHAKE_PREBID: "milkshakePrebid",
    MILKSHAKE_PRO: "milkshakePro",
    PREBID_SAAS: "prebidSaas",
    APS: "aps",
};

export const ActivityLabel: IDictionary = {
    [ActivityType.IN_BANNER_VIDEO]: "In-Banner Video Ad Units",
    [ActivityType.IN_READ]: "In-Read Ad Units",
    [ActivityType.VPAID]: "VPAID Tags",
    [ActivityType.VAST]: "VAST Tags",
    [ActivityType.DISPLAY]: "Display Ad Units",
    [ActivityType.PREBID]: "Prebid",
    [ActivityType.IN_STREAM]: "In-Stream",
    [ActivityType.MILKSHAKE_PREBID]: "Milkshake Prebid",
    [ActivityType.MILKSHAKE_PRO]: "Milkshake Pro",
    [ActivityType.PREBID_SAAS]: "Prebid Saas",
    [ActivityType.APS]: "APS",
};

export const ActivityToVerticalType = {
    [ActivityType.IN_BANNER_VIDEO]: VerticalType.PLAYER,
    [ActivityType.IN_READ]: VerticalType.PLAYER,
    [ActivityType.VPAID]: VerticalType.CONNECTION,
    [ActivityType.VAST]: VerticalType.CONNECTION,
    [ActivityType.DISPLAY]: VerticalType.DISPLAY,
    [ActivityType.PREBID]: VerticalType.OPEN_RTB,
    [ActivityType.IN_STREAM]: VerticalType.PLAYER,
    [ActivityType.MILKSHAKE_PREBID]: VerticalType.PLAYER,
    [ActivityType.MILKSHAKE_PRO]: VerticalType.PLAYER,
    [ActivityType.PREBID_SAAS]: VerticalType.PLAYER,
    [ActivityType.APS]: VerticalType.PLAYER,
};

export const ReportSchedules: ILabelValuePair[] = [
    {label: "Hourly", value: "hourly"},
    {label: "Daily", value: "daily"},
    {label: "Weekly", value: "weekly"},
    {label: "Monthly", value: "monthly"},
    {label: "Sundays", value: "sundays"},
    {label: "Mondays", value: "mondays"},
    {label: "Tuesdays", value: "tuesdays"},
    {label: "Wednesdays", value: "wednesdays"},
    {label: "Thursdays", value: "thursdays"},
    {label: "Fridays", value: "fridays"},
    {label: "Saturdays", value: "saturdays"}
];

export const ReportSchedulesSpecificHour = [
    {label: "00:00", value: "0"},
    {label: "01:00", value: "1"},
    {label: "02:00", value: "2"},
    {label: "03:00", value: "3"},
    {label: "04:00", value: "4"},
    {label: "05:00", value: "5"},
    {label: "06:00", value: "6"},
    {label: "07:00", value: "7"},
    {label: "08:00", value: "8"},
    {label: "09:00", value: "9"},
    {label: "10:00", value: "10"},
    {label: "11:00", value: "11"},
    {label: "12:00", value: "12"},
    {label: "13:00", value: "13"},
    {label: "14:00", value: "14"},
    {label: "15:00", value: "15"},
    {label: "16:00", value: "16"},
    {label: "17:00", value: "17"},
    {label: "18:00", value: "18"},
    {label: "19:00", value: "19"},
    {label: "20:00", value: "20"},
    {label: "21:00", value: "21"},
    {label: "22:00", value: "22"},
    {label: "23:00", value: "23"},
];

export const ReportDelimiters: ILabelValuePair[] = [
    {label: "Comma (,)", value: ","},
    {label: "Semicolon (;)", value: ";"},
    {label: "Pipe (|)", value: "|"},
    {label: "Tab (\\t)", value: "\\t"}
];

export const ReportDateRange: ILabelValuePair[] = [
    {label: "Yesterday", value: "yesterday"},
    {label: "Current Week", value: "currentWeek"},
    {label: "Last Seven Days", value: "lastSevenDays"},
    {label: "Last Week", value: "lastWeek"},
    {label: "Current Month", value: "currentMonth"},
    {label: "Last Thirty Days", value: "lastThirtyDays"},
    {label: "Last Month", value: "lastMonth"},
];

export enum AccountOnboardingStatus {
    INTRO = "intro",
    COMPANY_LEGAL = "company_legal",
    SUBMIT_SITELIST = "submit_sitelist",
    DEMO_WIDGET = "demo_widget",
    DONE = "done",
}

export enum AccountSource {
    MILKSHAKE = "milkshake",
}

export const AccountSourceLabelValue = [
    {value: "", label: "Null"},
    {value: AccountSource.MILKSHAKE, label: "Milkshake"},
];

export const AccountOnboardingStatusLabels = [
    {value: AccountOnboardingStatus.INTRO, label: "Intro"},
    {value: AccountOnboardingStatus.COMPANY_LEGAL, label: "Company and Legal Info"},
    {value: AccountOnboardingStatus.SUBMIT_SITELIST, label: "Submit Sitelist"},
    {value: AccountOnboardingStatus.DEMO_WIDGET, label: "Demo Widget"},
    {value: AccountOnboardingStatus.DONE, label: "Done"},
];

export const DomainApprovalStatusOptions = [
    {label: "PENDING", value: "PENDING"},
    {label: "REJECTED", value: "REJECTED"},
    {label: "APPROVED", value: "APPROVED"},
];

export enum DomainApprovalStatus {
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    ALL = "ALL",
}

export enum AdsTxtStatus {
    // In case all records implemented
    RECORDS_IMPLEMENTED = "Records Implemented",

    // In case all records missing
    RECORDS_NOT_IMPLEMENTED = "Records not Implemented",

    // In case records under review
    RECORDS_UNDER_REVIEW = "Records Under Review",

    // In case one or more records missing
    MISSING_RECORDS = "Missing Records",

    // In case it was not scanned yet
    PENDING_CHECK = "Pending Check",

    // In cases scanned but Error
    CHECK_ERROR = "Check Error",
}

export enum DashboardTypes {
    PERFORMANCE = "performance",
    EXPANDABLE = "expandable",
    CIRCLES = "circles"
}

export enum RevByOptions {
    COUNTRY = "country",
    DEVICE = "device",
    DOMAIN = "domain"
}

export const dateFields = ["created", "updated", "date", "from", "to"];

export enum CatalogOrder {
    Categories = "categories",
    Producers = "producer"
}

export enum TimeFormats {
    "hh:mm",
    "mm:ss",
    "hh:mm:ss"
}

export const SellerTypes = [
    {label: "PUBLISHER", value: "PUBLISHER"},
    {label: "INTERMEDIARY", value: "INTERMEDIARY"},
    {label: "BOTH", value: "BOTH"},
];

export const Browsers = [
    "Chrome",
    "Chrome Mobile",
    "Firefox",
    "IE",
    "Safari",
    "Opera",
    "Microsoft Edge",
    "Android Browser",
    "Samsung Internet",
    "Samsung Internet Mobile",
    "Firefox Mobile",
    "Safari Mobile",
    "Opera Mobile",
    "Firefox for iOS",
    "Safari Snapchat",
    "Safari Facebook",
    "Android Browser Facebook",
    "Android WebView Facebook",
    "Mobile Safari",
    "iOS WebView Facebook",
    "Samsung Browser",
    "Chrome WebView",
    "GSA",
    "Edge",
    "WebKit",
    "unknown",
    "Opera",
    "Line",
    "IE",
    "iOS WebView Instagram",
    "MIUI Browser",
    "Silk",
    "iOS WebView Snapchat",
    "Android Browser",
    "UCBrowser",
    "Chrome Headless",
    "PhantomJS",
    "Mozilla",
    "Yandex",
    "unknown WebView Facebook",
    "Puffin",
    "WeChat",
    "Vivaldi",
    "Maxthon",
    "Opera Touch",
    "Quark",
    "Chromium",
    "iOS WebView Twitter",
    "jasmine",
    "IceDragon",
    "Iceweasel",
    "unknown WebView Instagram",
    "IE Mobile",
    "IEMobile",
    "baiduboxapp",
    "QQBrowser",
    "Waterfox",
];


export const OsTypes = [
    "iOS",
    "Android",
    "Windows Phone",
    "Windows",
    "Mac OS",
    "Ubuntu",
    "Chrome OS",
    "Linux",
];

export const Languages: ILabelValuePair[] = [
    {value: "en", label: "English"},
    {value: "es", label: "Spanish"},
    {value: "it", label: "Italian"},
    {value: "fr", label: "France"},
    {value: "de", label: "German"},
];

export enum ReportingTimePresetLabels {
    today = "Today",
    yesterday = "Yesterday",
    last7days = "Last 7 Days",
    weektodate = "Week To Date",
    lastmonth = "Last Month",
    monthtodate = "Month To Date",
    now = "Now",
    custom = "Custom"
}

export enum Timezones {
    "America/New_York" = "America - New York",
    "America/Los_Angeles" = "America - Los Angeles",
    "Europe/London" = "Europe - London",
    "Europe/Berlin" = "Europe - Berlin",
    "Etc/GMT-12" = "GMT+12",
    "Etc/GMT-11" = "GMT+11",
    "Etc/GMT-10" = "GMT+10",
    "Etc/GMT-9" = "GMT+9",
    "Etc/GMT-8" = "GMT+8",
    "Etc/GMT-7" = "GMT+7",
    "Etc/GMT-6" = "GMT+6",
    "Etc/GMT-5" = "GMT+5",
    "Etc/GMT-4" = "GMT+4",
    "Etc/GMT-3" = "GMT+3",
    "Etc/GMT-2" = "GMT+2",
    "Etc/GMT-1" = "GMT+1",
    "Etc/GMT+0" = "GMT-0",
    "Etc/GMT+1" = "GMT-1",
    "Etc/GMT+2" = "GMT-2",
    "Etc/GMT+3" = "GMT-3",
    "Etc/GMT+4" = "GMT-4",
    "Etc/GMT+5" = "GMT-5",
    "Etc/GMT+6" = "GMT-6",
    "Etc/GMT+7" = "GMT-7",
    "Etc/GMT+8" = "GMT-8",
    "Etc/GMT+9" = "GMT-9",
    "Etc/GMT+10" = "GMT-10",
    "Etc/GMT+11" = "GMT-11",
    "Etc/GMT+12" = "GMT-12"
}

export enum OppositeTimezones {
    "America/New_York" = "America/New_York",
    "America/Los_Angeles" = "America/Los_Angeles",
    "Europe/London" = "Europe/London",
    "Europe/Berlin" = "Europe/Berlin",
    "Etc/GMT-12" = "Etc/GMT+12",
    "Etc/GMT-11" = "Etc/GMT+11",
    "Etc/GMT-10" = "Etc/GMT+10",
    "Etc/GMT-9" = "Etc/GMT+9",
    "Etc/GMT-8" = "Etc/GMT+8",
    "Etc/GMT-7" = "Etc/GMT+7",
    "Etc/GMT-6" = "Etc/GMT+6",
    "Etc/GMT-5" = "Etc/GMT+5",
    "Etc/GMT-4" = "Etc/GMT+4",
    "Etc/GMT-3" = "Etc/GMT+3",
    "Etc/GMT-2" = "Etc/GMT+2",
    "Etc/GMT-1" = "Etc/GMT+1",
    "Etc/GMT+0" = "Etc/GMT+0",
    "Etc/GMT+1" = "Etc/GMT-1",
    "Etc/GMT+2" = "Etc/GMT-2",
    "Etc/GMT+3" = "Etc/GMT-3",
    "Etc/GMT+4" = "Etc/GMT-4",
    "Etc/GMT+5" = "Etc/GMT-5",
    "Etc/GMT+6" = "Etc/GMT-6",
    "Etc/GMT+7" = "Etc/GMT-7",
    "Etc/GMT+8" = "Etc/GMT-8",
    "Etc/GMT+9" = "Etc/GMT-9",
    "Etc/GMT+10" = "Etc/GMT-10",
    "Etc/GMT+11" = "Etc/GMT-11",
    "Etc/GMT+12" = "Etc/GMT-12"
}

export enum TimezonesISO {
    "Etc/GMT-12" = "+12:00",
    "Etc/GMT-11" = "+11:00",
    "Etc/GMT-10" = "+10:00",
    "Etc/GMT-9" = "+09:00",
    "Etc/GMT-8" = "+08:00",
    "Etc/GMT-7" = "+07:00",
    "Etc/GMT-6" = "+06:00",
    "Etc/GMT-5" = "+05:00",
    "Etc/GMT-4" = "+04:00",
    "Etc/GMT-3" = "+03:00",
    "Etc/GMT-2" = "+02:00",
    "Etc/GMT-1" = "+01:00",
    "Etc/GMT+1" = "-01:00",
    "Etc/GMT+2" = "-02:00",
    "Etc/GMT+3" = "-03:00",
    "Etc/GMT+4" = "-04:00",
    "Etc/GMT+5" = "-05:00",
    "Etc/GMT+6" = "-06:00",
    "Etc/GMT+7" = "-07:00",
    "Etc/GMT+8" = "-08:00",
    "Etc/GMT+9" = "-09:00",
    "Etc/GMT+10" = "-10:00",
    "Etc/GMT+11" = "-11:00",
    "Etc/GMT+12" = "-12:00"
}

export enum CollectionStatus {
    Start = "start",
    Error = "error",
    Process = "process",
    Finish = "finish"
}

export enum CollectionStep {
    CheckNetwork = "checkNetwork",
    CreateBoNetwork = "createBoNetwork",
    CreateCmsNetwork = "createCmsNetwork",
    CreateCmsAccount = "createCmsAccount",
    CreateBoAccount = "createBoAccount",
    UpdateBoAuth = "updateBoAuth",
    UpdateCmsAuth = "updateCmsAuth",
    CreateAdvertiser = "createAdvertiser",
    CreateAdvertiserAdx = "createAdvertiserAdx",
    CreatePublisher = "createPublisher",
    CreateDemandPartnerAdx = "createDemandPartnerAdx",
    CreateDemandPartnerSsp = "createDemandPartnerSsp",
    CreateBlackImage = "createBlackImage",
    CreatePlayer = "createPlayer",
    CreateScenarios = "createScenarios",
    CreateTag = "createTag",
    CreatePlaylist = "createPlaylist",
    CreateWidget = "createWidget",
    Finish = "finish"
}

export const CollectionStepToEntityKey = {
    [CollectionStep.CreateBoNetwork]: "boNetwork",
    [CollectionStep.CreateCmsNetwork]: "cmsNetwork",
    [CollectionStep.CreateCmsAccount]: "cmsAccount",
    [CollectionStep.CreateBoAccount]: "boAccount",
    [CollectionStep.CreateAdvertiser]: "advertiser",
    [CollectionStep.CreateAdvertiserAdx]: "advertiserAdx",
    [CollectionStep.CreatePublisher]: "publisher",
    [CollectionStep.CreateDemandPartnerAdx]: "demandPartnerADX",
    [CollectionStep.CreateDemandPartnerSsp]: "demandPartnerSSP",
    [CollectionStep.CreateBlackImage]: "blackImage",
    [CollectionStep.CreatePlayer]: "player",
    [CollectionStep.CreateScenarios]: "createScenarios",
    [CollectionStep.CreateTag]: "tag",
    [CollectionStep.CreatePlaylist]: "playlist",
    [CollectionStep.CreateWidget]: "widget",
}

// We need this to know the order of the collection steps
export const CollectionSteps = [
    CollectionStep.CheckNetwork,
    CollectionStep.CreateBoNetwork,
    CollectionStep.CreateCmsNetwork,
    CollectionStep.CreateCmsAccount,
    CollectionStep.CreateBoAccount,
    CollectionStep.UpdateBoAuth,
    CollectionStep.UpdateCmsAuth,
    CollectionStep.CreateAdvertiser,
    CollectionStep.CreateAdvertiserAdx,
    CollectionStep.CreatePublisher,
    CollectionStep.CreateDemandPartnerAdx,
    CollectionStep.CreateDemandPartnerSsp,
    CollectionStep.CreateBlackImage,
    CollectionStep.CreatePlayer,
    CollectionStep.CreateScenarios,
    CollectionStep.CreateTag,
    CollectionStep.CreatePlaylist,
    CollectionStep.CreateWidget,
    CollectionStep.Finish,
];


export enum ConnectionCreateType {
    Banner = "banner",
    Video = "video",
    App = "app"
}

export const ConnectionCreateTypeLabelValue = [
    {value: ConnectionCreateType.Banner, label: "Banner"},
    {value: ConnectionCreateType.Video, label: "Video"},
    {value: ConnectionCreateType.App, label: "App"}
];
