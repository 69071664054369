import * as React from "react";
import * as PropTypes from "prop-types";
import { IDictionary } from "@vidazoo/ui-framework";
import {SortDirection, IDataGridHeader, DataGridRow, DataGridHeaderColumn, DataGrid} from "@vidazoo/ui";
import IMilkshakeUsers from "interfaces/IMilkshakeUsers";
import { MilkshakeUserRow } from "..";
import * as theme from "./theme.scss";
import {IDataGridHeaderList} from "../../../accounts/components/list/List";

const headers: IDataGridHeader[] = [{
    label: "Email",
    sortBy: "email",
    searchKey: "email"
}, {
    label: "First Name",
    sortBy: "firstName",
    searchKey: "firstName",
}, {
    label: "Last Name",
    sortBy: "lastName",
    searchKey: "lastName",
}, {
    label: "Account",
    sortBy: "accountName",
    searchKey: "accountName"
}, {
    label: "Last SignIn",
    sortBy: "lastLogin",
    searchKey: "lastLogin"
}, {
    label: "Created Date",
    sortBy: "created",
    searchKey: "created",
}];

export interface IListProps {
    items?: IMilkshakeUsers[];
    searchQueries: IDictionary<string>;
    sortBy: string;
    sortDirection: SortDirection;
    onSort: (sortBy: string) => void;
    onSearch: (searchKey: string, value: string) => void;
    hasAccountPermission?: (id: string) => boolean;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
}

export interface IListState {
}

export default class List extends React.Component<IListProps, IListState> {

    constructor(props, context) {
        super(props, context);
    }

    private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreesColumn, freezeColumnIndex) => {
        const {
            sortDirection,
            onSearch,
            sortBy,
            onSort,
            searchQueries,
        } = this.props;

        return (
            <DataGridRow columnsWidth={columnsWidth}>
                {headers.map((header: IDataGridHeaderList) => {
                    const props = {
                        blue: true,
                        label: header.label,
                        sortable: !!header.sortBy,
                        sortBy: header.sortBy,
                        sortActive: sortBy === header.sortBy,
                        searchKey: header.searchKey,
                        searchable: !!header.searchKey,
                        searchQuery: searchQueries[header.searchKey || ""],
                        freezeColumnIndex,
                        columnsWidth,
                        setFreesColumn,
                        sortDirection,
                        onSort,
                        onSearch,
                    };
                    return (
                        <DataGridHeaderColumn {...props}/>
                    );
                })}
            </DataGridRow>
        );
    };

    private onColumnResize = (columnsWidth) => {
        this.props.setColumnsWidth(columnsWidth);
    };

    private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
        const {
            items,
            sortBy,
            hasAccountPermission
        } = this.props;

        return (<MilkshakeUserRow
            item={items[index]}
            sortBy={sortBy}
            columnsWidth={columnsWidth}
            freezeColumnIndex={freezeColumnIndex}
            index={index}
            key={index}
            hasAccountPermission={hasAccountPermission}
        />);
    };

    public render() {
        const {items, getColumnsWidth} = this.props;
        const columnsWidth = getColumnsWidth();

        return (
            <DataGrid
                onColumnResize={this.onColumnResize}
                className={theme.list}
                extraData={items}
                headersCount={headers.length}
                itemsCount={items.length}
                renderRow={this.renderRow}
                rowHeight={60}
                columnsWidth={columnsWidth}
                renderHeader={this.renderHeaderAndFooter}/>
        );
    }
}
