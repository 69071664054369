enum ReportingFilterType {
    Device = "device",
    ConnectionName = "connectionName",
    Domain = "domain",
    Country = "country",
    PlayerName = "playerName",
    Os = "os",
    Browser = "browser",
    DemandPartner = "partnerTagId",
    Tag = "tagName",
    Video = "videoName",
    Publisher = "publisherName"
}

export default ReportingFilterType;
