import * as filters from "./handlers";
import {IDictionary, IReportingFilter} from "@vidazoo/ui-framework";
import IReportingFilterHanler from "./IReportingFilterHanler";
import ReportingFilterType from "./ReportingFilterType";
import BaseFilter from "./handlers/BaseFilter";

class ReportingFiltersManager {

    private filters: IDictionary<IReportingFilterHanler>;

    constructor() {
        this.filters = {};
    }

    public getFilter(type: string, activityId: string): IReportingFilterHanler {

        if (!this.filters[type + activityId]) {
            let Filter = this.getFilterClassByType(type as ReportingFilterType);

            if (!Filter) {
                Filter = BaseFilter;
            }

            this.filters[type + activityId] = new Filter();
        }

        return this.filters[type + activityId];
    }

    public getFilterListValueByLabel(filter: IReportingFilter, label: string, activityId: string): string {
        const handler = this.getFilter(filter.key, activityId);

        if (handler) {
            return handler.getValueByLabel(label);
        }

        return "";
    }

    public getFilterListLabelByValue(filter: IReportingFilter, value: string, activityId: string): string {
        const handler = this.getFilter(filter.key, activityId);

        if (handler) {
            return handler.getLabelByValue(value);
        }

        return "";
    }

    private getFilterClassByType(type: ReportingFilterType) {
        for (const key in filters) {
            if (filters[key].type === type) {
                return filters[key];
            }
        }
    }
}

export default new ReportingFiltersManager();
