import { action, observable } from "mobx";
import { decoder } from "@vidazoo/ui-framework";

export default class InitialState {
    @observable public pendingNewVersion: boolean;

    constructor() {
        this.reset();
    }

    @action private reset() {
        this.pendingNewVersion = false;
    }

    @action public setPendingNewVersion = (state: boolean) => {
        this.pendingNewVersion = state;
    }
}
