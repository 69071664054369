import MilkshakeUsers from "./MilkshakeUsers";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

export default inject((stores: any, props: any, context: any): any => ({
    items: stores.milkshakeUsersStore.filteredItems,
    isLoading: stores.milkshakeUsersStore.isLoading,
    searchQueries: stores.milkshakeUsersStore.searchQueries,
    onSearch: stores.milkshakeUsersStore.setSearchQuery,
    sortBy: stores.milkshakeUsersStore.sortBy,
    sortDirection: stores.milkshakeUsersStore.sortDirection,
    getItems: stores.milkshakeUsersStore.getItems,
    onSort: stores.milkshakeUsersStore.sort,
    hasAccountPermission: stores.sessionStore.hasAccountPermission,
    setColumnsWidth: stores.milkshakeUsersStore.setColumnsWidth,
    getColumnsWidth: stores.milkshakeUsersStore.getColumnsWidth,

}))(withRouter<any>(observer(MilkshakeUsers)));
