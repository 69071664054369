import BaseFilter from "./BaseFilter";
import ReportingFilterType from "../ReportingFilterType";

export default class BrowserFilter extends BaseFilter {

    public static type: ReportingFilterType = ReportingFilterType.Browser;

    constructor() {
        super();

        this.labelKey = "label";
        this.valueKey = "value";
        this.allowNew = true;
    }
}
