import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { scheduleReportsAPI } from "api";
import { IScheduleReport } from "interfaces/IScheduleReport";
import { currentUserStore, notificationsStore, navigationStore, sessionStore } from "stores";
import BaseScheduleReportStore, { IBaseScheduleReportStore } from "./BaseScheduleReportStore";
import { appUrlsService } from "services";

export interface ICreateScheduleReportStore extends IBaseScheduleReportStore {

}

export default class CreateScheduleReportStore extends BaseScheduleReportStore implements ICreateScheduleReportStore {

    constructor() {
        super();
    }

    @action public reset(params?: Partial<IScheduleReport>) {
        transaction(() => {
            super.reset(params);
        })
        if (currentUserStore.activities.length !== 0) {
            this.setActivity(currentUserStore.activities[0]._id);
            this.resetEntries();
        }
    }

        @action public submit() {
        const item = this.setAccounts();

        scheduleReportsAPI.create(this.submitify(item))
            .then((res) => navigationStore.push(appUrlsService.scheduleReports()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }
}
