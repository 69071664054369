import BaseFilter from "./BaseFilter";
import ReportingFilterType from "../ReportingFilterType";
import {OsTypes} from "../../../../common/enums";

export default class OsFilter extends BaseFilter {

    public static type: ReportingFilterType = ReportingFilterType.Os;

    constructor() {
        super();

        this.items = OsTypes.map((type) => ({value: type, label: type}));
        this.labelKey = "label";
        this.valueKey = "value";
        this.allowNew = true;
    }
}
