import {observable, action} from "mobx";
import {SortDirection} from "@vidazoo/ui";
import * as moment from "moment";
import { BaseListStore } from "@vidazoo/ui-framework";
import {IFilterLog, IReportingLog} from "../interfaces/ILog";
import reportingLogsAPI from "../api/reportingLogsAPI";
import {exportToCsvService, storageService} from "../services";
import {notificationsStore} from "./index";

export default class ReportingLogsStore extends BaseListStore<IReportingLog, any, string> {

    @observable public fromDate: moment.Moment;
    @observable public toDate: moment.Moment;
    @observable public search: string;
    @observable public results: IReportingLog[];
    private filter: IFilterLog;

    constructor() {
        super(notificationsStore, ["name", "userEmail", "organizationName", "reportLength", "date"], "log", "date");
        this.reset();
        // this.filter = filter;
    }

    @action
    protected reset(sort: string = "date") {
        this.search = "";
        this.sortDirection = SortDirection.ASC;
        this.sortBy = sort;
        this.fromDate = moment().utc().startOf("hour").subtract("2", "hours");
        this.toDate = moment().utc().subtract("hour");
    }

    @action
    public getItems = () => {
        this.isLoading = true;
        this.resetSearchQueries();

        reportingLogsAPI.getLogs(this.fromDate, this.toDate, this.search, this.filter)
            .then((res: any) => this.onLoadItemsSuccess(res))
            .catch((err) => this.onLoadItemsError(err));
    };

    @action
    public onDateChange(from: moment.Moment, to: moment.Moment) {
        this.fromDate = from;
        this.toDate = to;
    }

    @action
    public setSearch(value: string) {
        this.search = value;
    }

    @action public downloadLogReportCSV = (item: any) => {
        exportToCsvService.exportLogReport(JSON.parse(item.report));
    }

    @action public downloadLogResponseCSV = (item: any) => {
        exportToCsvService.exportLog(item);
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`reportingLogs`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`reportingLogs`);
    }
}
