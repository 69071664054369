import {action, observable, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {sitesAPI} from "api";
import {notificationsStore} from "..";
import ISite from "../../interfaces/ISite";
import {AdsTxtStatus, DomainApprovalStatus} from "../../common/enums";
import {exportToCsvService, socketService, storageService} from "../../services";

const SITE_SEARCHABLE_FIELDS = ["domain", "marketplaceApprovalStatus", "user.username", "adsTxtStatus", "publisherApprovalStatus"];

export const SitesCsvFields = [
    {name: "ID", key: "_id"},
    {name: "Domain", key: "domain"},
    {name: "Marketplace Approval Status", key: "marketplaceApprovalStatus"},
    {name: "Ads Txt Status", key: "adsTxtStatus"},
    {name: "Account", key: "user"},
    {name: "Date Added", key: "created"},
    {name: "Date Updated", key: "updated"},
];

export default class SitesStore extends BaseListStore<ISite, any, string> {
    private lastFetch: number;
    @observable public filterButton: string;
    @observable public adsTxtRecords: string;
    @observable public isWaitingForSitesToCsv: boolean;

    public bulkActionHandlers: any = {
        ...this.bulkActionHandlers,
        [`Marketplace ${DomainApprovalStatus.APPROVED}`]: () => this.setMarketplaceStatusValues(DomainApprovalStatus.APPROVED),
        [`Marketplace ${DomainApprovalStatus.REJECTED}`]: () => this.setMarketplaceStatusValues(DomainApprovalStatus.REJECTED),
        [`Publisher ${DomainApprovalStatus.APPROVED}`]: () => this.setPublisherStatusValues(DomainApprovalStatus.APPROVED),
        [`Publisher ${DomainApprovalStatus.REJECTED}`]: () => this.setPublisherStatusValues(DomainApprovalStatus.REJECTED),
        scanAdsTxt: () => this.scanManyAdsTxt(),
        scanOwnedBy: () => this.scanManyOwnedBy()
    };

    constructor() {
        super(notificationsStore, SITE_SEARCHABLE_FIELDS, "site", "domain");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.adsTxtRecords = "";
            this.filterButton = DomainApprovalStatus.ALL;
            this.selectedValues = [];
        });
    }

    @action public onButtonGroupChange = (value: string) => {
        this.filterButton = value;
        this.getItems(500);
    };

    @action public getItems = (withTtl: number = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this.selectedValues = [];
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        const filter: any = {};
        if (this.filterButton !== DomainApprovalStatus.ALL) {
            filter.marketplaceApprovalStatus = this.filterButton;
        }

        sitesAPI.getAll(1, 20000, filter, {}, {})
            .then((res) => this.onLoadItemsSuccess(res))
            .then(() => this.prepareSites())
            .then(() => this.connectToSiteListRoom())
            .catch((res) => this.onLoadItemsError(res));
    };

    @action
    public prepareSites() {
        this.items.forEach((item) => {
            if (item.possibleOwners) {
                const possibleOwnersArray = Object.keys(item.possibleOwners).map((domain) => ({
                    value: domain,
                    label: `${domain} ${item.possibleOwners[domain]}`
                }));
                (possibleOwnersArray && possibleOwnersArray.length) && (item.possibleOwnersArray = possibleOwnersArray);
            }
        });
    }

    public async connectToSiteListRoom() {
        socketService.connectToSiteListRoom();
        socketService.startListenToSiteListRoomStatusUpdate((data) => this.onStatusUpdateComing(data));
    }

    public async disconnectToSiteListRoom() {
        socketService.disconnectToSiteListRoom();
        socketService.stopListenToSiteListRoomStatusUpdate((data) => this.onStatusUpdateComing(data));
    }

    @action
    private onStatusUpdateComing = (data: any) => {
        const idx = this.items.findIndex((item) => item._id === data._id);
        this.items[idx].adsTxtStatus = data.adsTxtStatus;
        this.items[idx].adsTxtRecordsStatus = data.adsTxtRecordsStatus;
        this.items[idx].lastScanned = data.lastScanned;
        data.ownedBy && (this.items[idx].ownedBy = data.ownedBy);
        data.lastScanOwnedBy && (this.items[idx].lastScanOwnedBy = data.lastScanOwnedBy);
    };

    @action public updateSite = (item: ISite, name: string, value: string) => {
        item[name] = value;
        delete item.updated;
        sitesAPI.update(item._id, item)
            .then((res) => this.onUpdateStateSuccess())
            .catch((res) => this.onUpdateStateError(res));
    };

    @action public scanAdsTxt = (siteId: string) => {
        const site = this.items.find((item) => item._id === siteId);
        site.adsTxtStatus = AdsTxtStatus.RECORDS_UNDER_REVIEW;
        sitesAPI.scan({sites: [siteId]})
            .then((res) => this.onScanSuccess())
            .catch((res) => this.onScanError());
    };

    protected scanManyAdsTxt() {
        sitesAPI.scan({sites: this.selectedValues})
            .then((res) => this.onScanSuccess())
            .catch((res) => this.onScanError());
    }

    @action public scanOwnedBy = (siteId: string) => {
        sitesAPI.scanOwnedBy({sites: [siteId]})
            .then((res) => this.onScanOwnedBySuccess())
            .catch((res) => this.onScanError());
    };

    protected scanManyOwnedBy() {
        sitesAPI.scanOwnedBy({sites: this.selectedValues})
            .then((res) => this.onScanOwnedBySuccess())
            .catch((res) => this.onScanError());
    }

    @action protected deleteItem = () => {
        sitesAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    };

    public getAdsTxt = () => {
        sitesAPI.getAdsTxt()
            .then((res) => {
                if (!!res.data.trim()) {
                    this.setAdsTxtRecords(res);
                } else {
                    this.notificationsStore.pushErrorNotification({
                        title: "There is no ads.txt",
                        text: "Failed to get ads.txt ",
                        timeout: 5000
                    });
                }
            });
    };

    @action
    private setAdsTxtRecords(res) {
        transaction(() => {
            this.adsTxtRecords = res.data;
        });
    }

    public downloadAdsTxt = () => {
        exportToCsvService.exportAdsTxtCsv(this.adsTxtRecords);
    };

    protected setMarketplaceStatusValues(status: DomainApprovalStatus) {
        return Promise.all(this.selectedValues.map((id) => {
            const site = this.items.find((item) => item._id === id);
            this.updateSite(site, "marketplaceApprovalStatus", status);
        }));
    }

    protected setPublisherStatusValues(status: DomainApprovalStatus) {
        return Promise.all(this.selectedValues.map((id) => {
            const site = this.items.find((item) => item._id === id);
            this.updateSite(site, "publisherApprovalStatus", status);
        }));
    }

    protected deleteSelectedValues() {
        return Promise.all(this.selectedValues.map((id) => sitesAPI.delete(id)));
    }

    private onScanOwnedBySuccess() {
        this.notificationsStore.pushSuccessNotification({
            title: "OwnedBy scan can take a couple of minutes",
            text: "Please refresh the page after few minutes to see updated ads.txt status.",
            timeout: 5000
        });
    }

    private onScanSuccess() {
        this.notificationsStore.pushSuccessNotification({
            title: "Ads.txt scan can take a couple of minutes",
            text: "Please refresh the page after few minutes to see updated ads.txt status.",
            timeout: 5000
        });
    }

    private onScanError() {
        this.notificationsStore.pushErrorNotification({
            title: "Oops! Something went wrong",
            text: "Please notify our support or your account manager regarding this issue.",
            timeout: 4000
        });
    }

    private getCsvFieldsKeysNames() {
        const fields = {};
        for (const field of SitesCsvFields) {
            fields[field.key] = 1;
        }
        return fields;
    }

    @action
    private onGetSitesForCsvSuccess(sites) {
        this.isWaitingForSitesToCsv = false;
        exportToCsvService.exportItems(sites, SitesCsvFields, "sites");
    }

    @action
    private onGetSitesForCsvError() {
        this.isWaitingForSitesToCsv = false;
        this.onActionError("Download sites CSV failed");
    }

    @action public downloadCsv = () => {
        this.isWaitingForSitesToCsv = true;
        this.notificationsStore.pushNotification({title: "Preparing sites for download", timeout: 3000});

        sitesAPI.getAll(1, 30000, {}, this.getCsvFieldsKeysNames())
            .then((res) => this.onGetSitesForCsvSuccess(res.data.results))
            .catch((e) => this.onGetSitesForCsvError());
    };

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`sites`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`sites`);
        return columnsWidth || {0: 60, 1: 300, 2: 300, 3: 300, 4: 300, 5: 150, 6: 150, 7: 150, 8: 150, 9: 150, 10: 150};
    };

}
