import {BaseListStore} from "@vidazoo/ui-framework";
import {action, observable, transaction} from "mobx";
import IMedia from "../../interfaces/IMedia";
import {notificationsStore} from "../index";
import {mediaCatalogAPI} from "../../api";
import {CatalogOrder} from "../../common/enums";

export default class MediaCatalogStore extends BaseListStore<IMedia, any, string> {

    @observable public itemsByCategories: { [index: string]: IMedia[] };
    @observable public categories: string[];

    @observable public itemsByProducers: { [index: string]: IMedia[] };
    @observable public producers: string[];

    @observable public orderBy: string;

    @observable public language: string;
    @observable public filters: any;
    @observable public entity: string;

    constructor() {
        super(notificationsStore, [], "media", "name");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.categories = [];
            this.itemsByCategories = {};
            this.producers = [];
            this.itemsByProducers = {};
            this.language = "en";
            this.filters = {};
            this.orderBy = CatalogOrder.Categories;
            this.entity = "";
        });
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        mediaCatalogAPI.getCatalog(this.language, this.filters)
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    };

    @action
    public onLoadItemsSuccess(res) {
        this.isLoading = false;
        this.itemsByCategories = res.data.itemsByCategories;
        this.categories = res.data.categories;

        this.itemsByProducers = res.data.itemsByProducers;
        this.producers = res.data.producers;
    }

    @action public changeOrder = (name: string) => {
        this.orderBy = name;
    };

    @action public changeLanguage = (language: string) => {
        this.language = language;
        this.getItems();
    };

    @action public setFilter = (entity: string, entityName: string) => {
        this.filters = {[`${entityName}.name`]: entity};
        this.entity = entity;
        this.getItems();
        this.changeOrder(entityName === CatalogOrder.Categories ? CatalogOrder.Producers : CatalogOrder.Categories);
    };

    @action public clearFilter = () => {
        if (this.entity) {
            this.filters = {};
            this.entity = "";
            this.getItems();
        }
    };
}
