import { BaseAPI } from "@vidazoo/ui-framework";

class UpdateDimensionsAPI extends BaseAPI {
    public addGroups(data: any) {
        return this.request({
            method: "patch",
            url: `/api/update_dimensions/`,
            data
        });
    }
}

export default new UpdateDimensionsAPI((window as any).__BASE_API_URL__, true);
