import {MemoryClipboard} from "@vidazoo/ui-framework";

export default class DemoWidgetStore {

    public copySnippetToClipboard() {
        MemoryClipboard.copy(this.snippet);
    }

    public get snippet() {
        return `<script src="https://static.vidazoo.com/basev/vwpt.js" data-widget-id="5eba8ac60efb62000410244d"></script>`;
    }

}
