import { BaseAPI } from "@vidazoo/ui-framework";

class WidgetsAPI extends BaseAPI {

    public getAll() {
        return this.request({
            method: "get",
            url: "/api/widgets/",
        });
    }
}

export default new WidgetsAPI((window as any).__BASE_API_URL__, true);
