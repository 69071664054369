import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import IAccount from "interfaces/IAccount";
import { accountsAPI } from "api";
import { notificationsStore, navigationStore } from "stores";
import BaseItemStore, { IBaseItemStore } from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { appUrlsService } from "services";
import { sessionStore } from "stores";
import BaseAccountStore from "./base/BaseAccountStore";

export default class CreateAccountStore extends BaseAccountStore {

    @action public reset() {
        super.reset();
    }

    @action public submit() {

        accountsAPI.create(this.item)
            .then(() => navigationStore.push(appUrlsService.accounts()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }
}
