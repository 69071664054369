import {nodeListToArrayOf} from "@vidazoo/ui/lib/components/utils";
import {d} from '@pmmmwh/react-refresh-webpack-plugin/types/options';

export const isAllInViews = document.domain.indexOf("allinviews.com") > -1;

export function getVsName(fields) {
    return `${fields[0]}Vs${fields[1]}`;
}

export function getRevByName(groups) {
    return "revBy" + groups[0];
}

export function refreshAllCodeMirrorEditors() {
    nodeListToArrayOf<HTMLDivElement>(document.querySelectorAll(".CodeMirror")).map((element: any) => {
        if (element.CodeMirror) {
            element.CodeMirror.refresh();
        }
    });
}

export const formatDuration = (seconds: number = 0) => {
    return seconds < 3600 ? new Date(seconds * 1000).toISOString().substr(14, 5) : new Date(seconds * 1000).toISOString().substr(11, 8);
};

export function toIsoStringWithTz(date) {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = (num) => {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? "0" : "") + norm;
        };

    return date.getFullYear() +
        "-" + pad(date.getMonth() + 1) +
        "-" + pad(date.getDate()) +
        "T" + pad(date.getHours()) +
        ":" + pad(date.getMinutes()) +
        ":" + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ":" + pad(tzo % 60);
}

export function getDuplicated<T>(arr: T[]): T[] {
    const duplicated = [];
    const sorted = arr.slice().sort();
    for (let i = 0; i < sorted.length - 1; i++) {
        if (sorted[i] == sorted[i + 1]) {
            duplicated.push(sorted[i]);
        }
    }
    return duplicated;
}
