import { observable, action, computed, transaction } from "mobx";
import {globalPresetsReportsAPI} from "api";
import { navigationStore } from "stores";
import { appUrlsService } from "services";
import BaseGlobalPresetReportStore, {IBaseGlobalPresetReportStore} from "./BaseGlobalPresetReportStore";

export interface ICreateGlobalPresetReportStore extends IBaseGlobalPresetReportStore {

}

export default class CreateGlobalPresetReportStore extends BaseGlobalPresetReportStore implements ICreateGlobalPresetReportStore {

    constructor() {
        super();
    }

    @action public submit() {
        const item = this.setAccounts();

        globalPresetsReportsAPI.create(item)
            .then((res) => navigationStore.push(appUrlsService.globalPresetsReports()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }
}
