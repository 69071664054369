import {BaseAPI} from "@vidazoo/ui-framework";

class CountryListsAPI extends BaseAPI {

    public getAll() {
        return this.request({
            method: "get",
            url: "/api/countryLists/",
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/countryLists/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/countryLists/${id}/`,
            data
        });
    }


}

export default new CountryListsAPI((window as any).__BASE_API_URL__, true);
