import {observable, action, computed, transaction, toJS} from "mobx";
import * as _ from "lodash";
import BaseItemStore, {IBaseItemStore} from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {currentUserStore, notificationsStore} from "..";
import {IReportingEntry, guid, IReportingConstraint, IReportingFilter, fastClone} from "@vidazoo/ui-framework";
import reportingFiltersManager from "../reporting/filters/reportingFiltersManager";
import {IGlobalPresetReport} from "../../interfaces/presetReport/IGlobalPresetReport";
import {ActivityType} from "../../common/enums";
import activitiesAPI from "../../api/activitiesAPI";
import {reportingService} from "../../services";
import {IScheduleReport} from "../../interfaces/IScheduleReport";

export interface IBaseGlobalPresetReportStore extends IBaseItemStore<IGlobalPresetReport> {
    groups: IReportingEntry[];
    fields: IReportingEntry[];
    filters: IReportingEntry[];
    addConstraint: (values?: Partial<IReportingConstraint>) => IReportingConstraint;
    setConstraintParam: (constraint: IReportingConstraint, key: string, value: any) => void;
    addFilter: () => IReportingFilter;
    pushFilterValue: (filter: IReportingFilter, value: string, label: string) => void;
    removeFilterValue: (filter: IReportingFilter, value: string) => void;
    setFilterParam: (filter: IReportingFilter, key: string, value: any) => void;
}

export default abstract class BaseGlobalPresetReportStore extends BaseItemStore<IGlobalPresetReport> implements IBaseGlobalPresetReportStore {

    @observable public groups: IReportingEntry[];
    @observable public fields: IReportingEntry[];
    @observable public filters: IReportingEntry[];
    @observable public activities: { [type: string]: any };
    @observable public isLoadingActivities: boolean;

    constructor() {
        super(notificationsStore, "global preset report");
    }

    @action public getAllActivities = () => {
        this.isLoadingActivities = true;
        activitiesAPI.getAll()
            .then((res) => this.setAllActivities(res))
            .catch((err) => this.onSubmitFailed(err));
    };

    @action private setAllActivities = (res) => {
        this.activities = _.keyBy(res.data, "type");
        this.setActivityParams();
        this.isLoadingActivities = false;
    };

    @action public setActivityParams = () => {
        transaction(() => {
            const {dimensions, metrics, verticalType} = this.activities[this.item.activityType];
            this.fields = this.prepareParams(metrics, reportingService.fields[verticalType], "value", ["type", "format", "formula"]);
            this.groups = this.prepareParams(dimensions, reportingService.groups[verticalType], "value", ["filterType", "operators"]);
            this.filters = _.filter<IReportingEntry>(this.groups, (group) => group.filterType !== undefined);
        });
    };

    @action public setActivity = (value: string) => {
        transaction(() => {
            this.item.groups = [];
            this.item.fields = [];
            this.item.filters = [];
            this.item.constraints = [];
            this.item.activityType = value;
        });
        this.setActivityParams();
    };

    @action
    public reset(params?: Partial<IGlobalPresetReport>) {
        transaction(() => {
            super.reset();

            this.fields = observable([]);
            this.groups = observable([]);
            this.filters = observable([]);
            this.isLoadingActivities = true;

            this.item = {
                ...this.item,
                name: "",
                description: "",
                timezone: "",
                fields: observable([]),
                groups: observable([]),
                filters: observable([]),
                constraints: observable([]),
                activityType: ActivityType.IN_STREAM,
                ...params,
            };
        });
    }

    @action public addConstraint = (values?: Partial<IReportingConstraint>) => {
        this.item.constraints = this.item.constraints.concat({
            id: guid(),
            name: "",
            op: "",
            value: "",
            ...values
        });

        return this.item.constraints[this.item.constraints.length - 1];
    };

    @action public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
        constraint[key] = value;
    };

    @action public addFilter = (): IReportingFilter => {
        const filter = observable.object({
            id: guid(),
            key: "",
            values: [],
            filterList: [],
            isLoading: false,
            filterValueKey: "",
            filterLabelKey: "",
            allowNew: false,
            exclude: false,
            operator: "isAnyOf",
        });

        this.item.filters.push(filter);

        return filter;
    };

    @action public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
        let item: any = value;

        if (filter.filterLabelKey && filter.filterValueKey) {
            item = {
                [filter.filterLabelKey]: label,
                [filter.filterValueKey]: value
            };
        }

        filter.values = filter.values.concat(item);
    };

    @action public removeFilterValue = (filter: IReportingFilter, value: string) => {
        filter.values = filter.filterValueKey
            ? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
            : _.filter(filter.values, (item) => item !== value);
    };

    @action public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
        if (key === "key") {
            this.setFilterKey(filter, value);
            return;
        }

        filter[key] = value;
    };

    @action public setFilterKey = (filter: IReportingFilter, key: string): Promise<any> => {

        const filterHandler = reportingFiltersManager.getFilter(key, key);

        filter.key = key;
        filter.filterLabelKey = filterHandler.labelKey;
        filter.filterValueKey = filterHandler.valueKey;
        filter.allowNew = filterHandler.allowNew;
        filter.isLoading = filterHandler.isLoading;
        filter.values = [];

        return filterHandler.initialize({}).then(action(() => {
            filter.filterList = filterHandler.items;
            filter.isLoading = filterHandler.isLoading;
        }));
    };

    protected submitify(item?: IGlobalPresetReport) {
        const cloneItem: any = fastClone(item || this.item);

        cloneItem.groups = cloneItem.groups.map((x) => ({value: x.value, label: x.label}));
        cloneItem.fields = cloneItem.fields.map((x) => ({value: x.value, label: x.label}));
        cloneItem.constraints = cloneItem.constraints.map((x) => ({name: x.name, op: x.op, value: x.value}));

        return cloneItem;
    }

    @action
    protected onLoadFail() {
        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: "Failed to load logs, try again",
            timeout: 5000
        });
    }

    private prepareParams(params: IReportingEntry[], other: IReportingEntry[], matchBy: string, extendProps: string[]) {
        return params.map((item: IReportingEntry) => {
            const found = _.find(other, (otherItem) => item[matchBy] === otherItem[matchBy]);
            if (found) {
                for (const prop of extendProps) {
                    item[prop] = found[prop];
                }
            }
            return item;
        });
    }
}
