import {action, observable, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {accountsAPI} from "api";
import IAccount from "interfaces/IAccount";
import {notificationsStore} from "stores";
import {exportToCsvService, reportingService, storageService} from "../services";

export const AccountsCsvFields = [
    {name: "ID", key: "_id"},
    {name: "Company Name", key: "username"},
    {name: "Company Domain", key: "companyDomain"},
    {name: "ADS.TXT ID", key: "adsTxtPublisherId"},
    {name: "isActive", key: "isActive"},
    {name: "Date Added", key: "created"},
    {name: "Date Updated", key: "updated"},
    {name: "Onboarding Status", key: "onboardingStatus"},
    {name: "Seller Type", key: "sellerType"},
    {name: "Seller Json ID", key: "sellerJson"},
    {name: "Is In Sellers", key: "isInSellers"},
    {name: "Contact Full Name", key: "contactFullName"},
    {name: "Contact Email", key: "contactEmail"},
    {name: "Contact Phone", key: "contactPhone"},
    {name: "Contact Address", key: "contactAddress"},
    {name: "Google Ad Manager Administrator Email", key: "gamAdministratorEmail"},
    {name: "Google Ad Manager Network Code", key: "gamNetworkCode"},
];

const ACCOUNTS_SEARCHABLE_FIELDS = ["username"];

const ACCOUNTS_LIST_FIELDS = {
    username: 1,
    created: 1,
    isActive: 1,
    date: 1,
    updated: 1,
    onboardingStatus: 1,
    source: 1,
    companyDomain: 1,
    isInSellers: 1,
    adsTxtPublisherId: 1,
    allowContentLibrary: 1,
    sellerType: 1,
    sellerJson: 1,
    contactFullName: 1,
    contactEmail: 1,
    contactPhone: 1,
    contactAddress: 1,
    gamAdministratorEmail: 1,
    gamNetworkCode: 1
};

export default class AccountsStore extends BaseListStore<IAccount, any, string> {
    @observable public filterButton: string;
    @observable public filterButtonSellers: string;

    constructor() {
        super(notificationsStore, ACCOUNTS_SEARCHABLE_FIELDS, "account", "username");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.filterButton = "all";
            this.filterButtonSellers = "all";
        });
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        const filter: any = {};

        (this.filterButton === "active") && (filter.isActive = true);
        (this.filterButton === "inactive") && (filter.isActive = false);
        (this.filterButtonSellers === "sellersJsonEnabled") && (filter.isInSellers = true);
        (this.filterButtonSellers === "sellersJsonDisabled") && (filter.isInSellers = false);

        accountsAPI.getAll(null, null, filter, ACCOUNTS_LIST_FIELDS, sort).then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    };

    @action public toggleActiveState = (item: IAccount) => {
        item.isActive = !item.isActive;

        accountsAPI.active(item._id, item.isActive)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    };

    @action public toggleSellerJson = (item: IAccount) => {
        item.isInSellers = !item.isInSellers;

        accountsAPI.sellerJson(item._id, item.isInSellers)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    };

    @action public toggleContentLibrary = (item: IAccount) => {
        item.allowContentLibrary = !item.allowContentLibrary;

        accountsAPI.contentLibrary(item._id, item.allowContentLibrary)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    };

    @action protected deleteItem = () => {
        accountsAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    };

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`accounts`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`accounts`);
    };

    public onViewAsSuccess(email: string) {
        notificationsStore.pushSuccessNotification({
            title: "Operation Complete",
            text: `Successfully view as <b>${email}</b>`,
            timeout: 5000
        });
    }

    public onViewAsError() {
        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: `Failed to <b>View as</b> relevant account`,
            timeout: 5000
        });
    }

    @action public onButtonGroupChange = (value: string) => {
        this.filterButton = value;
        this.getItems();
    };

    @action public onButtonGroupSellersChange = (value: string) => {
        this.filterButtonSellers = value;
        this.getItems();
    };

    @action public downloadCsv = () => {
        exportToCsvService.exportItems(this.items, AccountsCsvFields, "accounts");
    };
}
