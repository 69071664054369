import {action, observable, toJS} from "mobx";
import {activitiesService, appUrlsService, reportingService, storageService, trackingService} from "services";
import BaseUserStore from "stores/base/BaseUserStore";
import {ActivityContext, navigationStore} from "stores";
import IUser from "interfaces/IUser";
import IActivity from "interfaces/IActivity";
import {DayField, getCurrentHour, IDictionary, IReportingEntry} from "@vidazoo/ui-framework";
import {AccountOnboardingStatus, AccountSource, UserRole} from "common/enums";
import * as _ from "lodash";
import IAccount from "interfaces/IAccount";
import {__DEV__, PathByOnboardingStatus} from "common/constants";
import * as moment from "moment";
import {SupervisorAPI} from "../api";

export interface ICurrentUserStore extends IUser {
    initialize: (user: IUser, currentAccount: IAccount) => void;
    getActivity: (name: string) => IActivity;
    isInRoles: (scope: string[]) => boolean;
    isAnyAdmin: () => boolean;
    isAdmin: () => boolean;
    isRootAdmin: () => boolean;
    isMilkshakeAdmin: () => boolean;
    isMilkshakeUser: () => boolean;
}

export default class CurrentUserStore extends BaseUserStore implements ICurrentUserStore {
    private activityContext: IDictionary<ActivityContext>;
    public onboardingStatus: AccountOnboardingStatus;
    public accountSource: AccountSource;
    @observable public isVidazooOrganization: boolean;
    @observable public currentAccountId: string;
    @observable public currentAccount: IAccount;
    @observable public defaultActivityName: string;

    constructor() {
        super();

        this.activityContext = {};
        this.currentAccount = {} as any;
    }

    public async initialize(user: IUser, currentAccount: IAccount) {

        super.initialize(user, currentAccount);

        this.currentAccount = currentAccount;
        this.currentAccountId = currentAccount ? currentAccount._id : null;

        this.isVidazooOrganization = user.organization ? (user.organization.name === "vidazoo") : false;

        this.fulfilActivities();

        this.createActivityContext();

        storageService.initialize(this._id);

        trackingService.initialize(user, currentAccount.source);

        this.updateCurrentAccountOnboardingStatus(currentAccount.onboardingStatus);

        this.updateCurrentAccountSource(currentAccount.source);

        this.checkOnBoardingStatus();

    }

    private checkOnBoardingStatus() {
        if ([AccountOnboardingStatus.INTRO, AccountOnboardingStatus.COMPANY_LEGAL].includes(this.onboardingStatus)) {
            navigationStore.push(appUrlsService.onboardingIntro());
        }
    }

    private createActivityContext() {
        if (this.activities.length > 0) {
            for (let i = 0, len = this.activities.length; i < len; i++) {
                const activity = this.activities[i];
                this.activityContext[activity.name] = new ActivityContext(activity);
            }
        }
    }

    public updateCurrentAccountActivities = (activities: IActivity[]) => {
        this.activities = activities;
    };

    public updateCurrentAccountOnboardingStatus = (status: AccountOnboardingStatus) => {
        this.onboardingStatus = status;
        if (window.location.search.includes("skip")) {
            this.onboardingStatus = AccountOnboardingStatus.DONE;
            navigationStore.push(appUrlsService.app());
        }
    };

    public forcePath() {
        if (this.isAnyAdmin()) {
            return;
        }

        const forcedPath = PathByOnboardingStatus[this.onboardingStatus];
        if (forcedPath && !__DEV__) {
            navigationStore.push(forcedPath);
        }
    }

    public updateCurrentAccountSource = (source) => {
        this.accountSource = source;
    };

    private fulfilActivities() {
        if (this.activities.length > 0) {
            for (let i = 0, len = this.activities.length; i < len; i++) {
                const activity = this.activities[i];

                this.fulfilActivity(activity);

                this.extendList(activity.fields, reportingService.fields[activity.verticalType], "value", ["type", "format"]);
                this.extendList(activity.groups, reportingService.groups[activity.verticalType], "value", ["filterType"]);

                this.activityContext[activity.name] = new ActivityContext(activity);
            }
        }
    }

    private extendList(base: any, other: any, matchBy: string, extendProps: string[]) {
        base.forEach(action((baseItem: IReportingEntry) => {
            const found = _.find(other, (otherItem) => baseItem[matchBy] === otherItem[matchBy]);
            if (found) {
                for (const prop of extendProps) {
                    baseItem[prop] = found[prop];
                }
            }
        }));
    }

    /**
     * If the `activity` is missing required fields,
     * this method will fulfil them with default ones.
     *
     * @private
     * @param {IActivity} activity
     * @returns
     * @memberof CurrentUserStore
     */
    private fulfilActivity(activity: IActivity) {
        const model = activitiesService.getActivity(activity.type);

        if (!model) {
            return;
        }

        const userActivityLabels = activity.fields.map((field) => field.label);
        const requiredActivityMetrics = _.filter(model.metrics, (metric) => metric.required);

        for (let i = 0, len = requiredActivityMetrics.length; i < len; i++) {
            const metric = requiredActivityMetrics[i];

            if (userActivityLabels.indexOf(metric.label) < 0) {
                activity.fields.push(metric);
            }
        }
    }

    public getActivity(name: string): IActivity {
        return this.activities.find((activity) => activity.name === name);
    }

    public getActivityContext(name: string): ActivityContext {
        return _.find(this.activityContext, (context) => context.name === name);
    }

    public getActivityContxtById(id: string): ActivityContext {
        return _.find(this.activityContext, (context) => context.id === id);
    }

    public isInRoles = (scope: UserRole[]): boolean => {
        return _.some(scope, (role) => _.includes(this.roles, role));
    };

    public isAnyAdmin = (): boolean => {
        return this.isInRoles([UserRole.ADMIN, UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN]);
    };

    public isAdmin(): boolean {
        return this.isInRoles([UserRole.ADMIN]);
    }

    public isRootAdmin = (): boolean => {
        return this.isInRoles([UserRole.ROOT_ADMIN]);
    }

    public isMilkshakeAdmin = (): boolean => {
        return _.some(["MILKSHAKE_ADMIN"], (role) => _.includes(this.roles, role));
    };

    public isMilkshakeUser = (): boolean => {
        return this.accountSource === AccountSource.MILKSHAKE;
    };

    public isThirdPartyPublisher = (): boolean => {
        return this.currentAccount.thirdPartyPublishers;
    };

    public getActivitiesContext(): ActivityContext[] {
        const array = []
        this.activities.forEach((activity) => {
            array.push(this.getActivityContext(activity.name))
        })
        return array
    }

    @action
    public async getAllDashboardsData() {

        const activities = this.getActivitiesContext();
        const dashboardsData = [];

        for (const activity of activities) {
            const name = activity.name;
            const params = {
                dayField: DayField.DATE,
                time: "today",
                timezone: storageService.getGlobalTimezone() || "Etc/GMT+0",
                publisherIds: activity.publisherIds,
                verticalType: activity.verticalType,
                from: moment().utc().minutes(0).second(0).subtract(getCurrentHour(), "hour"),
                to: moment().utc().minutes(0).second(0).add(-getCurrentHour() + 24, "hour")
            };

            const dashboardReport = await reportingService.getDashboardReport(toJS(params), activity);
            dashboardsData.push({
                name,
                isLoading: true,
                loadedOnce: false,
                dashboard: activity.dashboards[0],
                fieldByLabel: activity.fieldByLabel,
                dashboardReport,
            });
        }

        return dashboardsData;
    };

    public async getDefaultActivityName() {

        const defaultActivityName = storageService.getDefaultActivityName(this.currentAccountId);

        if (defaultActivityName) {
            this.setDefaultActivityName(defaultActivityName);
        }

        if (!defaultActivityName && this.activities.length > 0) {
            const dashboardsData = await this.getAllDashboardsData();

            if (dashboardsData.length > 0) {
                for (const row of dashboardsData) {
                    const {dashboard, dashboardReport, fieldByLabel, name} = row;

                    const field = dashboard.fields.join("");
                    const value = fieldByLabel[dashboard.fields[0]].value;

                    if (dashboardReport[field][0].fields[value] > 0) {
                        this.setDefaultActivityName(name);
                        break;
                    }
                }
                if (!this.defaultActivityName) {
                    this.setDefaultActivityName(dashboardsData[0].name);
                }
            }
        }
    };

    @action
    private setDefaultActivityName(name: string) {
        this.defaultActivityName = name;
        storageService.setDefaultActivity(name, this.currentAccountId);
    };

    @action public deleteAuthAccount = async () => {
        this.userSelfDestruct && await SupervisorAPI.deleteAuthAccount();
    }
}
