import { BaseAPI } from "@vidazoo/ui-framework";

class SellersJsonAPI extends BaseAPI {

    public getAll() {
        return this.request({
            method: "get",
            url: "/api/sellers_json/",
        });
    }
}

export default new SellersJsonAPI((window as any).__BASE_API_URL__, true);
