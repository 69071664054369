import {BaseListStore} from '@vidazoo/ui-framework';
import {action, observable, transaction} from 'mobx';
import {notificationsStore} from './index';
import {exportToCsvService, storageService} from '../services';
import {IDemandPartner} from "../interfaces/IDemandPartner";
import demandPartnersAPI from "../api/demandPartnersAPI";
import {debounce} from "lodash";

const DEMAND_PARTNERS_SEARCHABLE_FIELDS = ["name"];

const DEMAND_PARTNERS_CSV_FIELDS = [
    {name: "ID", key: "_id"},
    {name: "Name", key: "name"},
    {name: "Activity Name", key: "activityName"},
];

export default class DemandPartnersStore extends BaseListStore<IDemandPartner, any, string> {

    private lastFetch: number;


    constructor() {
        super(notificationsStore, DEMAND_PARTNERS_SEARCHABLE_FIELDS, "demandPartners", "name");
    }


    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.selectedValues = [];
        });
    };


    @action public getItems = (withTtl: number = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        demandPartnersAPI.getAll()
            .then((res) => {
                this.onLoadItemsSuccess(res)
            })
            .catch((res) => this.onLoadItemsError(res));
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`demandPartners`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`demandPartners`);
        return columnsWidth || {0: 200, 1: 220, 2: 200, 3: 200};
    };

    public downloadCsv = debounce(() => {
        exportToCsvService.exportItems(this.items, DEMAND_PARTNERS_CSV_FIELDS, "demandPartners.csv");
    }, 500);


}


