import { action, transaction } from "mobx";
import { BaseListStore } from "@vidazoo/ui-framework";
import { scheduleReportsAPI } from "api";
import { IScheduleReport } from "interfaces/IScheduleReport";
import {currentUserStore, notificationsStore, sessionStore} from "..";
import IUser from "interfaces/IUser";
import {appUrlsService, storageService} from "../../services";

const SCHEDULE_REPORTS_SEARCHABLE_FIELDS = ["name", "isActive", "schedule", "dateRange", "lastSchedule", "nextSchedule", "user.username"];

export default class ScheduleReportsAdminAllStore extends BaseListStore<IScheduleReport, IUser, string> {
    constructor() {
        super(notificationsStore, SCHEDULE_REPORTS_SEARCHABLE_FIELDS, "schedule report", "created");
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        scheduleReportsAPI.getAdminAllReports()
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`scheduleReportsAdminAll`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`scheduleReportsAdminAll`);
    }

    @action public scheduleReportViewAs = async (userId: string, scheduleReportId: string) => {
        const currentAccount = sessionStore.indexAccounts[userId];
        if (!currentAccount && currentUserStore.isRootAdmin()) {
            const users = await window.__sdk__.auth.users.getByAccountId(userId);
            if (users.length) {
                this.onViewAsSuccess(users[0].email);
                window.__sdk__.auth.viewAs(users[0].email, appUrlsService.editScheduleReport(scheduleReportId));
            } else {
                this.onViewAsError();
            }
        }
    }

    public onViewAsSuccess(email: string) {
        notificationsStore.pushSuccessNotification({
            title: "Operation Complete",
            text: `Successfully view as <b>${email}</b>`,
            timeout: 5000
        });
    }

    public onViewAsError() {
        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: `Failed to <b>View as</b> relevant account`,
            timeout: 5000
        });
    }
}
