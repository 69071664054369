import {action, observable} from "mobx";
import {navigationStore, notificationsStore} from "..";
import appUrlsService from "services/appUrlsService";
import {BaseItemStore, MemoryClipboard} from "@vidazoo/ui-framework";
import {sitesAPI} from "../../api";
import ISite from "../../interfaces/ISite";
import {AdsTxtStatus, DomainApprovalStatus} from "../../common/enums";
import IAdsTxtRecordsStatus from "../../interfaces/ISite";

export default class SiteStore extends BaseItemStore<ISite> {

    constructor() {
        super(notificationsStore, "site");
    }

    @action
    public reset() {
        this.item = {
            ...this.item,
            domain: "",
            created: 0,
            updated: 0,
            marketplaceApprovalStatus: DomainApprovalStatus.ALL,
            adsTxtStatus: AdsTxtStatus.RECORDS_UNDER_REVIEW,
            lastAdsTxtScan: 0,
            publisherApprovalStatus: DomainApprovalStatus.ALL,
            isActive: true,
            isDeleted: false,
            date: 0,
            user: {
                username: "",
                _id: ""
            },
            adsTxtRecordsStatus: observable.array([]),
        };
    }

    @action
    public getItem(id: string) {
        this.isLoading = true;
        this.loadError = false;

        sitesAPI.getOne(id)
            .then((res) => this.setItem(res.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    public delete() {
        sitesAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.siteList()))
            .catch((err) => this.onSubmitFailed(err));
    }

    @action
    public submit() {
        sitesAPI.update(this.item._id, this.item)
            .then((res) => this.onSubmitSuccess())
            .catch((res) => this.onLoadFailed(res));
    }

    public copyToClipboard = (item: string) => {
        MemoryClipboard.copy(item);
        notificationsStore.pushNotification({
            title: "copy to Clipboard",
            timeout: 2000,
            success: true
        });
    }
}
