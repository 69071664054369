import { observable, action, transaction } from "mobx";
import { accountsAPI } from "api";
import { VerticalType } from "common/enums";
import { LoadState, IDictionary } from "@vidazoo/ui-framework";
import IPublisher from "interfaces/IPublisher";
import _ from "lodash";

export default class VerticalAccountsStore {

    @observable public loadStateByVertical: IDictionary<LoadState>;
    @observable public accountsByVertical: IDictionary<{ username: string, _id: string }[]>;
    @observable public accountsByVerticalById: IDictionary<IDictionary<{username: string, _id: string}>>;

    constructor() {
        this.reset();
    }

    @action private reset() {
        transaction(() => {
            this.loadStateByVertical = observable<any>({
                [VerticalType.PLAYER]: LoadState.None,
                [VerticalType.CONNECTION]: LoadState.None,
                [VerticalType.DISPLAY]: LoadState.None,
                [VerticalType.OPEN_RTB]: LoadState.None
            });

            this.accountsByVertical = observable<any>({
                [VerticalType.PLAYER]: [],
                [VerticalType.CONNECTION]: [],
                [VerticalType.DISPLAY]: [],
                [VerticalType.OPEN_RTB]: [],
            });

            this.accountsByVerticalById = observable<any>({
                [VerticalType.PLAYER]: {},
                [VerticalType.CONNECTION]: {},
                [VerticalType.DISPLAY]: {},
                [VerticalType.OPEN_RTB]: {},
            });
        });
    }

    @action public loadAccountsByVertical(vertical: string) {
        if (this.loadStateByVertical[vertical] > LoadState.Error) {
            return;
        }

        this.loadStateByVertical[vertical] = LoadState.Loading;

        accountsAPI.getAccountsByVertical(vertical)
            .then((res) => this.onVerticalAccountsLoadSuccess(vertical, res.data))
            .catch(() => this.onVerticalAccountsLoadFail(vertical));
    }

    @action private onVerticalAccountsLoadSuccess(vertical: string, accounts: { username: string, _id: string }[]) {
        transaction(() => {
            this.loadStateByVertical[vertical] = LoadState.Loaded;
            this.accountsByVertical[vertical] = accounts;
            this.accountsByVerticalById[vertical] = _.keyBy(accounts, "_id");
        });
    }

    @action private onVerticalAccountsLoadFail(vertical: string) {
        this.loadStateByVertical[vertical] = LoadState.Error;
    }

    @action public getAccountsByVertical(vertical: string): { username: string, _id: string }[] {
        if (this.loadStateByVertical[vertical] < LoadState.Loaded) {
            this.loadAccountsByVertical(vertical);

            return [];
        }

        return this.accountsByVertical[vertical];
    }
}
