import { BaseAPI } from "@vidazoo/ui-framework";

class ViewAsAPI extends BaseAPI {
    public getUsersToViewAs() {
        return this.request({
            method: "get",
            url: `/api/view_as/`,
        });
    }
}

export default new ViewAsAPI((window as any).__BASE_API_URL__, true);
