import { BaseAPI } from "@vidazoo/ui-framework";
import { sessionStore } from "stores";

class PublishersAPI extends BaseAPI {

    public getAllDisplayPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/display"
        });
    }

    public getUserDisplayPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/display/me"
        });
    }

    public getAllPlatformPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/platform"
        });
    }

    public getUserPlatformPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/platform/me"
        });
    }

    public getAllConnectionsPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/connections"
        });
    }

    public getUserConnectionsPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/connections/me"
        });
    }

    public getAllOpenRTBPublishers() {
        return this.request({
            method: "get",
            url: "/api/publishers/open_rtb"
        });
    }

    public getActivityPublishers(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}`
        });
    }

    public getActivityConnections(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}/connections`
        });
    }

    public getActivityPlayers(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}/players`
        });
    }

    public getActivityDemandPartners(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}/demand-partners`
        });
    }

    public getActivityTags(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}/tags`
        });
    }

    public getActivityVideos(activityId: string) {
        return this.request({
            method: "get",
            url: `/api/publishers/activity/${activityId}/videos`
        });
    }
}

export default new PublishersAPI((window as any).__BASE_API_URL__, true);
