import { AccountOnboardingStatus } from "common/enums";
import { appUrlsService } from "services";

const QUERY_PARAMS = new URLSearchParams(location.search);

export const __DEV__ = process.env.NODE_ENV === "development" || QUERY_PARAMS.get("dev");

export const PathByOnboardingStatus = {
    [AccountOnboardingStatus.INTRO]: appUrlsService.onboardingIntro(),
    [AccountOnboardingStatus.DEMO_WIDGET]: appUrlsService.onboardingDemoWidget()
};
