import {BaseAPI} from "@vidazoo/ui-framework";

class DomainsListsAPI extends BaseAPI {

    public getAll() {
        return this.request({
            method: "get",
            url: "/api/domainsLists/",
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/domainsLists/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/domainsLists/${id}/`,
            data
        });
    }


}

export default new DomainsListsAPI((window as any).__BASE_API_URL__, true);
