import BaseItemStore from '@vidazoo/ui-framework/lib/stores/BaseItemStore';
import {IConnection} from '../interfaces/IConnection';
import {notificationsStore} from './index';
import {action, transaction} from 'mobx';
import connectionsAPI from '../api/connectionsAPI';


export class EditConnectionStore extends BaseItemStore<IConnection> {

    constructor() {
        super(notificationsStore, "connection");
    }


    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
            };
        });
    };

    @action
    public getItem(id: string) {
        this.isLoading = true;

        connectionsAPI.getOne(id)
            .then((item) => this.setItem(item.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    public setItem(item) {
        transaction(() => {
            this.item = item;
            this.loadError = false;
            this.isLoading = false;
        });
    }

    @action
    public submit() {
        connectionsAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }


}
