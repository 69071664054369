import {action, observable, runInAction, toJS, transaction} from "mobx";
import * as moment from "moment";
import {ActivityContext, currentUserStore, navigationStore} from "stores";
import IDashboardActivity from "interfaces/dashboard/IDahboardActivity";
import {
    DayField,
    getCurrentHour,
    getCurrentMonth,
    getCurrentWeek,
    getLastMonth,
    getPastDays
} from "@vidazoo/ui-framework";
import IDashboardActivityParams from "interfaces/dashboard/IDashboardActivityParams";
import {reportingService, storageService} from "services";
import IDashboard from "../interfaces/IDashboard";
import * as _ from "lodash";

const CUSTOM_DATE_KEYS = ["from", "to"];

export default class DashboardStore {

    @observable public activity: ActivityContext;
    @observable public dashboardData: any;
    @observable public error: Error;
    public activities: { [name: string]: IDashboardActivity };

    constructor() {
        this.activities = {};
        this.activity = null;
        this.error = null;
    }

    @action public setActivity = (name: string) => {

        this.activity = currentUserStore.getActivityContext(name);

        if (!this.activity || !this.activity.name) {
            navigationStore.push("/");
            return;
        }

        this.activities[name] = observable<IDashboardActivity>({
            isLoading: false,
            loadedOnce: false,
            name,
            results: observable.array([], {deep: false}),
            params: {
                dayField: DayField.DATE,
                time: "today",
                timezone: storageService.getGlobalTimezone() || "Etc/GMT+0",
                publisherIds: this.activity.publisherIds,
                verticalType: this.activity.verticalType
            }
        });

        this.applyTimePreset(this.activities[name].params.time);

        const dashboardData = storageService.getDashboardData();
        dashboardData && (this.dashboardData = dashboardData);
        !this.dashboardData && (this.dashboardData = observable<any>({}));
        if (this.activity && this.activity.dashboards) {
            this.activity.dashboards.forEach((dashboard) => {
                if (!this.dashboardData[dashboard._id + this.preset]) {
                    this.dashboardData[dashboard._id + this.preset] = observable<any>({isLoading: true});
                }
            });
        }
    };

    @action public getDashboardData = (resetLoad: boolean = false) => {
        if (!this.activity || !this.activity.name || !this.activity.dashboards) {
            return;
        }

        transaction(() => {
            this.error = null;
            this.isLoading = true;

            if (resetLoad) {
                this.loadedOnce = false;
            }
        });

        const activity = this.activity;
        const dashboardActivity = this.activities[this.activity.name];

        storageService.setGlobalTimezone(dashboardActivity.params.timezone);

        activity.dashboards.forEach((dashboard) => this.getAndBuildDashboardReport(toJS(this.params), activity, dashboard, resetLoad, this.preset));
    };

    @action
    public getAndBuildDashboardReport = async (params: IDashboardActivityParams, context: ActivityContext, dashboard: IDashboard, resetLoad, preset) => {
        const lastHour = moment().utc().minutes(0).second(0).subtract(1, "hour").unix() * 1000;
        if (!resetLoad && preset !== "custom" && this.dashboardData[dashboard._id + preset] && this.dashboardData[dashboard._id + preset].lastRefresh && lastHour < this.dashboardData[dashboard._id + this.preset].lastRefresh) {
            return;
        }
        this.dashboardData[dashboard._id + preset] ?
            this.dashboardData[dashboard._id + preset].isLoading = true :
            this.dashboardData[dashboard._id + preset] = {isLoading: true};
        const report = await reportingService._getDashboardReport(params, context, dashboard);
        reportingService._buildDashboardResult(dashboard, report, context, params);
        runInAction(() => {
            this.dashboardData[dashboard._id + preset].data = dashboard.data;
            this.dashboardData[dashboard._id + preset].lastRefresh = Date.now();
            this.dashboardData[dashboard._id + preset].isLoading = false;
            storageService.setDashboardData(this.dashboardData);
        });
    };

    @action
    public setResults(results: any[], activityStore: IDashboardActivity) {
        transaction(() => {
            activityStore.loadedOnce = true;
            activityStore.isLoading = false;
            activityStore.results = results;
        });
    }

    @action
    private setError(e: Error, activityStore: IDashboardActivity) {
        transaction(() => {
            activityStore.isLoading = false;
            activityStore.results = [];
            this.error = e;
        });
    }

    @action public setActivityParam = (key: string, value: any) => {
        this.params[key] = value;
        if (CUSTOM_DATE_KEYS.indexOf(key) > -1) {
            const diff = this.params.to.diff(this.params.from, "hours");
            this.params.dayField = (diff > 72) ? DayField.DAY : DayField.DATE;
            this.applyTimePreset("custom");
            this.getDashboardData();
        }
    };

    @action
    public setDate(from: number, to: number, withTimezone?: boolean) {
        if (withTimezone) {
            const offset = this.getTimezoneOffset() / 60;

            from += offset;
            to -= offset;
        }

        transaction(() => {
            this.params.from = moment().utc().minutes(0).second(0).subtract(from, "hour");
            this.params.to = moment().utc().minutes(0).second(0).add(to, "hour");
        });
    }

    private getTimezoneOffset(): number {
        return (moment.tz.zone(this.params.timezone) as any).utcOffset(moment().utc().minutes(0).second(0));
    }

    @action public changeTimezone = (timezone: string) => {
        this.params.timezone = timezone;
    };

    @action public applyTimePreset = (preset: string) => {

        this.params.time = preset;

        switch (preset) {
            case "now":
                this.setDate(1, 1, true);
                break;
            case "today":
                this.setDate(getCurrentHour(), -getCurrentHour() + 24);
                this.params.dayField = DayField.DATE;
                break;
            case "yesterday":
                this.setDate(getCurrentHour() + 24, -getCurrentHour());
                this.params.dayField = DayField.DATE;
                break;
            case "last7days":
                this.setDate(getPastDays(7), -getCurrentHour() + 24);
                this.params.dayField = DayField.DAY;
                break;
            case "weektodate":
                this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
                this.params.dayField = DayField.DAY;
                break;
            case "lastmonth":
                this.setDate(getLastMonth(), -getCurrentMonth());
                this.params.dayField = DayField.DAY;
                break;
            case "monthtodate":
                this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
                this.params.dayField = DayField.DAY;
                break;
        }
    };

    public get results(): any[] {
        return this.currentActivity.results;
    }

    public get isLoading(): boolean {
        return this.currentActivity && this.currentActivity.isLoading;
    }

    public get loadedOnce(): boolean {
        return this.currentActivity.loadedOnce;
    }

    public set isLoading(value: boolean) {
        this.currentActivity.isLoading = value;
    }

    public set loadedOnce(value: boolean) {
        this.currentActivity.loadedOnce = value;
    }

    public get params(): IDashboardActivityParams {
        return this.currentActivity.params || {};
    }

    public get preset(): string {
        return this.currentActivity ? this.currentActivity.params.time : "";
    }

    public get currentActivity(): IDashboardActivity {
        return this.activity
            ? this.activities[this.activity.name]
            : null;
    }

}
