import { action, transaction } from "mobx";
import { BaseListStore } from "@vidazoo/ui-framework";
import IUser from "interfaces/IUser";
import { notificationsStore } from "stores";
import {globalPresetsReportsAPI} from "../../api";
import {IGlobalPresetReport} from "../../interfaces/presetReport/IGlobalPresetReport";
import {storageService} from "../../services";

const GLOBAL_PRESETS_REPORTS_SEARCHABLE_FIELDS = ["name", "isActive", "activityType", "fields", "groups", "updated", "created"];
const GLOBAL_PRESETS_REPORTS_LIST_FIELDS = {
    name: 1,
    isActive: 1,
    activityType: 1,
    fields: 1,
    groups: 1,
    updated: 1,
    created: 1,
};

export default class GlobalPresetsReportsStore extends BaseListStore<IGlobalPresetReport, IUser, string> {
    constructor() {
        super(notificationsStore, GLOBAL_PRESETS_REPORTS_SEARCHABLE_FIELDS, "global presets reports", "name");
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        globalPresetsReportsAPI.getAll({page: 1, page_size: 10000, fields: GLOBAL_PRESETS_REPORTS_LIST_FIELDS, sort})
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    @action public toggleActiveState = (item: IGlobalPresetReport) => {
        item.isActive = !item.isActive;

        globalPresetsReportsAPI.active(item._id, item.isActive)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action public deleteItem = () => {
        globalPresetsReportsAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth("global_presets_reports", columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth("global_presets_reports");
        return columnsWidth || {0: 100, 1: 250, 2: 250, 3: 137, 4: 124, 5: 150, 6: 117, 7: 171, 8: 119};
    };

}
