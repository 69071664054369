import { BaseAPI } from "@vidazoo/ui-framework";
import { sessionStore } from "stores";
import { AccountOnboardingStatus } from "common/enums";

class AccountsAPI extends BaseAPI {
    public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/accounts/",
            params: { page, page_size, filter, fields, sort }
        });
    }
    public getAllWithReport(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/accounts/report",
            params: { page, page_size, filter, fields, sort }
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: "/api/accounts/",
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/accounts/${id}/`
        });
    }

    public active(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/active/`,
            data: { isActive }
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/`,
            data
        });
    }

    public sellerJson(id: string, isInSellers: boolean) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/seller_json/`,
            data: { isInSellers }
        });
    }

    public contentLibrary(id: string, allowContentLibrary: boolean) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/content_library/`,
            data: { allowContentLibrary }
        });
    }

    public me() {
        return this.request({
            method: "get",
            url: `/api/accounts/me`
        });
    }

    public updateOnboardingStatus(status: AccountOnboardingStatus) {
        return this.request({
            method: "patch",
            url: `/api/accounts/onboarding_status`,
            data: { status }
        });
    }

    public getVidazooUsers() {
        return this.request({
            method: "get",
            url: "/api/accounts/vidazoo_users",
        });
    }

    public createNetworkAndCollections(id: string) {
        return this.request({
            method: "patch",
            url: `/api/accounts/create_network_and_collections/${id}`,
        });
    }

    public getAccountsByVertical(vertical: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/by_vertical/`,
            params: {vertical}
        });
    }

    public getCollectionData(collectionId: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/get_collection/${collectionId}/`
        });
    }

    public getPossibleOwners(accountId: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/possible_owners/${accountId}/`
        });
    }

    public getCompanyLegalInfo(id: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/company_legal_info/${id}/`
        });
    }

    public updateCompanyLegalInfo(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/accounts/company_legal_info/${id}/`,
            data
        });
    }
}

export default new AccountsAPI((window as any).__BASE_API_URL__, true);
