import {action, observable} from "mobx";
import {accountsAPI, UpdatePointOfContactAPI} from "../../api";
import {navigationStore} from "../index";
import {appUrlsService} from "../../services";
import {BaseNotificationsStore} from "@vidazoo/ui-framework";
import {IUpdatePointOfContact} from "../../interfaces/IUpdatePointOfContact";

export default class UpdatePointOfContactStore {

    @observable public item: IUpdatePointOfContact;
    @observable public vidazooUsers: Array<{ _id: string, email: string }>;
    @observable public isLoadingVidazooUsers: boolean;
    @observable public accounts: any[];
    @observable public isLoadingAccounts: boolean;

    constructor(private notificationsStore: BaseNotificationsStore) {
        this.reset();
    }

    @action
    public reset() {
        this.item = {
            userId: "",
            accountIds: observable.array([], {deep: false})
        };

        this.vidazooUsers = observable.array([], {deep: false});
        this.isLoadingVidazooUsers = false;
        this.accounts = observable.array([], {deep: false});
        this.isLoadingAccounts = false;
    }

    @action
    public getVidazooUsers = async () => {
        this.isLoadingVidazooUsers = true;
        accountsAPI.getVidazooUsers()
            .then((res) => this.setVidazooUsers(res))
            .catch((err) => this.onLoadFailed("accounts"));
    }

    @action
    private setVidazooUsers(res) {
        this.vidazooUsers = res.data;
        this.isLoadingVidazooUsers = false;
    }

    @action
    public getAccounts() {
        this.isLoadingAccounts = true;

        accountsAPI.getAll(null, null, {isActive: true}, {username: 1, created: 1})
            .then((res) => this.setAccounts(res));
    }

    @action
    public setAccounts(res) {
        this.accounts = res.data.results;
        this.isLoadingAccounts = false;
    }

    @action public addAccountId = (accountId: string) => {
        this.item.accountIds = [...this.item.accountIds, accountId];
    }

    @action public removeAccountId = (accountId: string) => {
        this.item.accountIds = this.item.accountIds.filter((item) => (item) !== accountId);
    }

    @action public updateParam = (key: string, value: any) => {
        this.item[key] = value;
    };

    @action
    public submit = (): void => {
        UpdatePointOfContactAPI.updatePoc(this.item)
            .then((res) => this.onSubmitSuccess());
    };

    public onSubmitSuccess = () => {
        this.notificationsStore.pushSuccessNotification({
            title: "Update Point of contact Success",
            timeout: 5000
        });
        navigationStore.push(appUrlsService.accounts());
    };

    @action public onLoadFailed(type: string) {
        this.isLoadingVidazooUsers = false;

        this.notificationsStore.pushErrorNotification({
            title: "Operation Error",
            text: `Failed to load ${type}`,
            timeout: 5000
        });
    }
}
