import {action, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {notificationsStore} from "..";
import IUser from "interfaces/IUser";
import IUserNetwork from "../../interfaces/IUserNetwork";
import ITestPage from "../../interfaces/ITestPage";
import {testPagesAPI} from "../../api";

const TEST_PAGES_SEARCHABLE_FIELDS = ["url", "created", "updated"];

const TEST_PAGES_LIST_FIELDS = {
    url: 1,
    created: 1,
    updated: 1
};

export default class TestPagesStore extends BaseListStore<ITestPage, IUser, IUserNetwork> {
    public bulkActionHandlers = {
        ...this.bulkActionHandlers,
    };

    constructor() {
        super(notificationsStore, TEST_PAGES_SEARCHABLE_FIELDS, "testPages", "url");
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.selectedValues = [];
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        testPagesAPI.getAll(1, 10000, {}, TEST_PAGES_LIST_FIELDS, {})
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    };

    @action protected deleteItem = () => {
        testPagesAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    };

    protected deleteSelectedValues() {
        return Promise.all(this.selectedValues.map((x) => testPagesAPI.delete(x)));
    }

    public sendTestPage = (url: string) => {
        testPagesAPI.create({url})
            .then(() => this.onSendSuccess())
            .catch((e) => this.onSendError())
    };

    private onSendSuccess = () => {
        notificationsStore.pushSuccessNotification({
            title: "Your test page send",
            timeout: 3000
        });
        this.getItems();
    }

    private onSendError = () => {
        notificationsStore.pushErrorNotification({
            title: "Your test page not send",
            timeout: 3000
        });
    }
}
