import * as _ from "lodash";
import { observable, action, computed, transaction, toJS } from "mobx";
import { guid, fastClone } from "@vidazoo/ui-framework";
import {globalPresetsReportsAPI} from "api";
import {createGlobalPresetReportStore, navigationStore} from "stores";
import { appUrlsService } from "services";
import BaseGlobalPresetReportStore, {IBaseGlobalPresetReportStore} from "./BaseGlobalPresetReportStore";
import {IGlobalPresetReport} from "../../interfaces/presetReport/IGlobalPresetReport";

export interface IGlobalPresetReportStore extends IBaseGlobalPresetReportStore {
    getItem: (id: string) => void;
    duplicate: () => void;
}

export default class GlobalPresetReportStore extends BaseGlobalPresetReportStore implements IGlobalPresetReportStore {
    @action public getItem(id: string) {
        this.isLoading = true;

        globalPresetsReportsAPI.getOne(id)
            .then((res) => this.unpack(res.data))
            .then((item) => this.setItem(item))
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public duplicate() {
        const item = fastClone(this.item);

        _.unset(item, "_id");

        item.name = "";

        createGlobalPresetReportStore.reset();
        createGlobalPresetReportStore.setItem(item);

        navigationStore.push({ pathname: appUrlsService.createGlobalPresetReport(), query: { duplicate: true } });
    }

    @action public submit() {
        globalPresetsReportsAPI.update(this.item._id, this.submitify())
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public delete() {
        globalPresetsReportsAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.globalPresetsReports()))
            .catch((err) => this.onSubmitFailed(err));
    }

    private unpack(presetReport: IGlobalPresetReport) {
        if (presetReport.timezone === "Etc/GMT") {
            presetReport.timezone = "Etc/GMT+0";
        }

        if (presetReport.constraints.length) {
            _.forEach(presetReport.constraints, (constraint) => {
                constraint.id = guid();

                if (constraint.op === "==") {
                    constraint.op = "=";
                }
            });
        }

        if (presetReport.filters.length) {
            _.forEach(presetReport.filters, (filter) => {
                filter.id = guid();

                switch (filter.key) {
                    case "publisherId": filter.key = "publisherName"; break;
                    case "scenarioId": filter.key = "scenarioName"; break;
                    case "connectionId": filter.key = "connectionName"; break;
                    case "tagId": filter.key = "tagName"; break;
                    case "abTestId": filter.key = "abTestName"; break;
                }
            });
        }

        return presetReport;
    }
}
