import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { BaseListStore } from "@vidazoo/ui-framework";
import { milkshakeUsersAPI } from "api";
import IMilkshakeUsers from "interfaces/IMilkshakeUsers";
import { notificationsStore } from "stores";
import milkshakeUsers from "containers/milkshakeUsers";
import { SortDirection } from "@vidazoo/ui";
import {storageService} from "../services";

const MILKSHAKEUSERS_SEARCHABLE_FIELDS = ["email", "firstName", "lastName", "account"];

export default class MilkshakeUsersStore extends BaseListStore<IMilkshakeUsers, any, string>  {
    constructor() {
        super(notificationsStore, MILKSHAKEUSERS_SEARCHABLE_FIELDS, "milkshake user", "created");
        this.sortDirection = SortDirection.DESC;
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        milkshakeUsersAPI.getAllMilkshakeUsers()
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`accounts`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`accounts`);
    }
}
