import { MemoryClipboard } from "@vidazoo/ui-framework";
import { action, observable } from "mobx";
import { sitesAPI } from "api";
import { editAccountStore, notificationsStore } from "stores";
import {appUrlsService, exportToCsvService} from "services";
import { AccountOnboardingStatus } from "common/enums";

export default class DownloadAdsTxtStore {

    @observable public adsTxt: string;
    @observable public isLoading: boolean;

    constructor() {
        this.adsTxt = "";
        this.isLoading = false;
    }

    public copyAdsTxtToClipboard = () => {
        MemoryClipboard.copy(this.adsTxt);

        notificationsStore.pushSuccessNotification({
            title: "Copied to clipboard",
            timeout: 5000,
        });
    }

    public downloadAdsTxt = (isOnBoarding: boolean = false) => {
        exportToCsvService.exportAdsTxtCsv(this.adsTxt);
        const path = isOnBoarding ? appUrlsService.onboardingDemoWidget() : appUrlsService.demoWidget();
        editAccountStore.updateOnboardingStatus(AccountOnboardingStatus.DEMO_WIDGET, path);
    }

    @action public loadAdsTxt = () => {
        this.isLoading = true;
        sitesAPI.getAdsTxt()
            .then((res) => this.onGetAdsTxtSuccess(res.data))
            .catch(() => this.onGetAdsTxtError());
    }

    @action private onGetAdsTxtSuccess(res) {
        this.isLoading = false;
        this.adsTxt = res;
    }

    @action private onGetAdsTxtError() {
        this.isLoading = false;

        notificationsStore.pushErrorNotification({
            title: "Could not get ads.txt at this time",
            text: "Please try again later or contact support@vidazoo.com",
            timeout: 5000,
        });
    }

}
