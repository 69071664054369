import * as React from "react";
import * as PropTypes from "prop-types";
import IMilkshakeUsers from "interfaces/IMilkshakeUsers";
import {IDictionary} from "@vidazoo/ui-framework";
import {
    SortDirection,
    Density,
    DensitySwitch,
    PageTitle,
    Modal,
    ConfirmPopMessage,
    Button,
    Loader,
    Label,
    PageMessage,
    PageContainer,
    OptionsButton
} from "@vidazoo/ui";
import {RouteComponentProps} from "react-router";
import * as theme from "styles/_baseList.scss";
import {appUrlsService} from "services";
import * as classnames from "classnames";
import {List} from "./components";

export interface IMilkshakeUsersProps extends RouteComponentProps<any> {
    items?: IMilkshakeUsers[];
    isLoading?: boolean;
    searchQueries?: IDictionary<string>;
    sortBy?: string;
    sortDirection?: SortDirection;
    getItems?: () => void;
    onSort?: (sortBy: string) => void;
    onSearch?: (searchKey: string, value: string) => void;
    selectedValues?: string[];
    hasAccountPermission?: (id: string) => boolean;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
}

export interface IMilkshakeUsersState {
}

export default class MilkshakeUsers extends React.Component<IMilkshakeUsersProps, IMilkshakeUsersState> {

    constructor(props, context) {
        super(props, context);
    }

    public componentDidMount() {
        const {getItems} = this.props;

        getItems();
    }

    private renderLoader(): JSX.Element {
        return (
            <div className={theme.message}>
                <Loader size={2} gray className={theme.loader}/>
                <PageMessage
                    small
                    title="Loading Milkshake Users"
                    description="Please wait..."
                />
            </div>
        );
    }

    public render(): JSX.Element {
        const {isLoading, items, sortBy, sortDirection, onSort, searchQueries, onSearch, hasAccountPermission, setColumnsWidth, getColumnsWidth} = this.props;

        return (
            <PageContainer pageTitle="Milkshake Users" className={classnames(theme.list, theme.dataGrid)} top={1.2}>
                <div className={theme.actions}>
                    <PageTitle iconName="icon-users" label="Milkshake Users"/>
                </div>

                {!isLoading ? (
                    <List
                        items={items}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        searchQueries={searchQueries}
                        onSearch={onSearch}
                        onSort={onSort}
                        hasAccountPermission={hasAccountPermission}
                        setColumnsWidth={setColumnsWidth}
                        getColumnsWidth={getColumnsWidth}
                    />
                ) : null}

                {isLoading ? this.renderLoader() : null}

            </PageContainer>
        );
    }
}
