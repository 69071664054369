import BaseFilter from "./BaseFilter";
import ReportingFilterType from "../ReportingFilterType";

export default class DomainFilter extends BaseFilter {

    public static type: ReportingFilterType = ReportingFilterType.Domain;

    constructor() {
        super();
    }
}
