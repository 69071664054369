import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {ICreateConnection} from "../interfaces/ICreateConnection";
import {navigationStore, notificationsStore} from "./index";
import {action, transaction} from "mobx";
import {accountsAPI} from "../api";
import {appUrlsService} from "../services";
import connectionsAPI from "../api/connectionsAPI";


export class CreateConnectionStore extends BaseItemStore<ICreateConnection> {

    constructor() {
        super(notificationsStore, "connection");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
                name: "",
                responseType: null,
                thirdPartyRevenueShare: 0,
                iosThirdPartyRevenueShareEnabled: false,
                iosThirdPartyRevenueShare: 0,
                floor: 0,
                isActive: true,
            };
        });
    }

    @action
    submit(): void {
        connectionsAPI.create(this.item)
            .then(() => navigationStore.push(appUrlsService.connections()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

}
