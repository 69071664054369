import { observable, action, transaction } from "mobx";
import { publishersAPI } from "api";
import { VerticalType } from "common/enums";
import { LoadState, IDictionary } from "@vidazoo/ui-framework";
import IPublisher from "interfaces/IPublisher";

export default class PublishersStore {

    @observable public loadStateByVertical: IDictionary<LoadState>;
    @observable public publishersByVertical: IDictionary<IPublisher[]>;

    constructor() {
        this.reset();
    }

    @action private reset() {
        transaction(() => {
            this.loadStateByVertical = observable<any>({
                [VerticalType.PLAYER]: LoadState.None,
                [VerticalType.CONNECTION]: LoadState.None,
                [VerticalType.DISPLAY]: LoadState.None,
                [VerticalType.OPEN_RTB]: LoadState.None
            });

            this.publishersByVertical = observable<any>({
                [VerticalType.PLAYER]: [],
                [VerticalType.CONNECTION]: [],
                [VerticalType.DISPLAY]: [],
                [VerticalType.OPEN_RTB]: [],
            });
        });
    }

    @action public loadPublishersByVertical(vertical: string) {
        if (this.loadStateByVertical[vertical] > LoadState.Error) {
            return;
        }

        this.loadStateByVertical[vertical] = LoadState.Loading;

        let promise;

        switch (vertical) {
            case VerticalType.PLAYER:
                promise = publishersAPI.getAllPlatformPublishers();
                break;
            case VerticalType.CONNECTION:
                promise = publishersAPI.getAllConnectionsPublishers();
                break;
            case VerticalType.DISPLAY:
                promise = publishersAPI.getAllDisplayPublishers();
                break;
            case VerticalType.OPEN_RTB:
                promise = publishersAPI.getAllOpenRTBPublishers();
                break;
        }

        promise
            .then((res) => this.onVerticalPublishersLoadSuccess(vertical, res.data))
            .catch(() => this.onVerticalPublishersLoadFail(vertical));
    }

    @action private onVerticalPublishersLoadSuccess(vertical: string, publishers: IPublisher[]) {
        transaction(() => {
            this.loadStateByVertical[vertical] = LoadState.Loaded;
            this.publishersByVertical[vertical] = publishers;
        });
    }

    @action private onVerticalPublishersLoadFail(vertical: string) {
        this.loadStateByVertical[vertical] = LoadState.Error;
    }

    @action public getPublishersByVertical(vertical: string): IPublisher[] {
        if (this.loadStateByVertical[vertical] < LoadState.Loaded) {
            this.loadPublishersByVertical(vertical);

            return [];
        }

        return this.publishersByVertical[vertical];
    }
}
