import * as _ from "lodash";
import IActivity from "interfaces/IActivity";
import { fastClone, IDictionary, IReportingEntry } from "@vidazoo/ui-framework";
import { storageService, activitiesService } from "services";
import { publishersAPI } from "api";
import IPublisher from "interfaces/IPublisher";
import IDashboard from "../interfaces/IDashboard";
import {reportingService} from "services";

export default class ActivityContext {
    private activity: IActivity;
    private groupByValue: IDictionary<IReportingEntry>;
    private groupByLabel: IDictionary<IReportingEntry>;
    private fieldByValue: IDictionary<IReportingEntry>;
    public fieldByLabel: IDictionary<IReportingEntry>;
    private filterByValue: IDictionary<IReportingEntry>;
    private filterByLabel: IDictionary<IReportingEntry>;

    public publishers: IPublisher[];
    public publishersLoaded: boolean;
    public connections: any[];
    public connectionsLoaded: boolean;
    public players: any[];
    public playersLoaded: boolean;
    public demandPartners: any[];
    public demandPartnersLoaded: boolean;
    public tags: any[];
    public tagsLoaded: boolean;
    public filters: IReportingEntry[];
    public videos: any[];
    public videosLoaded: boolean;

    constructor(activity: IActivity) {

        this.activity = fastClone(activity);

        this.fulfilActivity();

        this.extendList(this.activity.fields, reportingService.fields[this.activity.verticalType], "value", ["type", "format", "formula"]);
        this.extendList(this.activity.groups, reportingService.groups[this.activity.verticalType], "value", ["filterType", "operators"]);

        this.filters = _.filter<IReportingEntry>(this.activity.groups, (group) => group.filterType !== undefined);

        this.indexEntries();

        this.publishersLoaded = false;
    }

    public get id(): string {
        return this.activity._id;
    }

    public get type(): string {
        return this.activity.type;
    }

    public get name(): string {
        return this.activity.name;
    }

    public get verticalType(): string {
        return this.activity.verticalType;
    }

    public get publisherIds(): string[] {
        return this.activity.publisherIds;
    }

    public get groups(): IReportingEntry[] {
        return this.activity.groups;
    }

    public get fields(): IReportingEntry[] {
        return this.activity.fields;
    }

    public get dashboards(): IDashboard[] {
        return this.activity.dashboards;
    }

    public getFieldByLabel(label: string): IReportingEntry {
        return this.fieldByLabel[label];
    }

    public getFieldByValue(value: string): IReportingEntry {
        return this.fieldByValue[value];
    }

    public getGroupByLabel(label: string): IReportingEntry {
        return this.groupByLabel[label];
    }

    public getGroupByValue(value: string): IReportingEntry {
        return this.groupByValue[value];
    }

    public getFilterByLabel(label: string): IReportingEntry {
        return this.filterByLabel[label];
    }

    public getFilterByValue(value: string): IReportingEntry {
        return this.filterByValue[value];
    }

    public tryGetFilter(labelOrValue: string): IReportingEntry {
        return this.getFilterByLabel(labelOrValue) || this.getFilterByValue(labelOrValue);
    }

    public getPreselectedFields(): IReportingEntry[] {

        const storageFields = storageService.getReportingVerticalActivityEntries(this.verticalType, this.type, "fields");

        if (storageFields && storageFields.length) {
            return _.filter<IReportingEntry>(this.fields, (field) => _.find<IReportingEntry>(storageFields, (sField) => sField.value === field.value) !== undefined);
        }

        const model = activitiesService.getActivity(this.type);

        if (!model) {
            return [];
        }

        return _(model.metrics as any)
            .pickBy<IReportingEntry>({ preselected: true })
            .map<any, IReportingEntry>((metric: IReportingEntry) => _.find<IReportingEntry>(this.fields, (field) => field.value === metric.value))
            .compact()
            .value();
    }

    public getPreselectedGroups(): IReportingEntry[] {
        const storageGroups = storageService.getReportingVerticalActivityEntries(this.verticalType, this.type, "groups");

        if (storageGroups && storageGroups.length) {
            return _.filter<IReportingEntry>(this.groups, (group) => _.find<IReportingEntry>(storageGroups, (sGroup) => sGroup.value === group.value) !== undefined);
        }

        const model = activitiesService.getActivity(this.type);

        if (!model) {
            return [];
        }

        return _(model.dimensions as any)
            .pickBy<IReportingEntry>({ preselected: true })
            .map<any, IReportingEntry>((dimension: IReportingEntry) => _.find<IReportingEntry>(this.groups, (group) => group.value === dimension.value))
            .compact()
            .value();
    }

    public setPersistentFields(fields: IReportingEntry[]) {
        storageService.setReportingVerticalActivityEntries(
            this.verticalType,
            this.type,
            "fields",
            fields
        );
    }

    public setPersistentGroups(groups: IReportingEntry[]) {
        storageService.setReportingVerticalActivityEntries(
            this.verticalType,
            this.type,
            "groups",
            groups
        );
    }

    public loadConnections(): Promise<any[]> {
        if (this.connectionsLoaded) {
            return Promise.resolve(this.connections);
        }

        return this.loadActivityConnections().then((response) => {
            this.connectionsLoaded = true;

            this.connections = response.data;

            return this.connections;
        });
    }

    private loadActivityPlayers(): Promise<any> {
        return publishersAPI.getActivityPlayers(this.id);
    }

    public loadPlayers(): Promise<any[]> {
        if (this.playersLoaded) {
            return Promise.resolve(this.players);
        }

        return this.loadActivityPlayers().then((response) => {
            this.playersLoaded = true;

            this.players = response.data;

            return this.players;
        });
    }

    private loadActivityDemandPartners(): Promise<any> {
        return publishersAPI.getActivityDemandPartners(this.id);
    }

    public loadDemandPartners(): Promise<any[]> {
        if (this.demandPartnersLoaded) {
            return Promise.resolve(this.demandPartners);
        }

        return this.loadActivityDemandPartners().then((response) => {
            this.demandPartnersLoaded = true;

            this.demandPartners = response.data;

            return this.demandPartners;
        });
    }

    private loadActivityTags(): Promise<any> {
        return publishersAPI.getActivityTags(this.id);
    }

    public loadTags(): Promise<any[]> {
        if (this.tagsLoaded) {
            return Promise.resolve(this.tags);
        }

        return this.loadActivityTags().then((response) => {
            this.tagsLoaded = true;

            this.tags = response.data;

            return this.tags;
        });
    }

    private loadActivityVideos(): Promise<any> {
        return publishersAPI.getActivityVideos(this.id);
    }

    public loadVideos(): Promise<any[]> {
        if (this.videosLoaded) {
            return Promise.resolve(this.videos);
        }

        return this.loadActivityVideos().then((response) => {
            this.videosLoaded = true;

            this.videos = response.data;

            return this.tags;
        });
    }

    private loadActivityConnections(): Promise<any> {
        return publishersAPI.getActivityConnections(this.id);
    }

    public loadPublishers(): Promise<IPublisher[]> {
        return this.loadActivityPublishers().then((response) => {
            this.publishersLoaded = true;

            this.publishers = response.data;

            return this.publishers;
        });
    }

    private loadActivityPublishers(): Promise<any> {
        return publishersAPI.getActivityPublishers(this.id);
    }

    private indexEntries() {
        this.fieldByValue = {};
        this.fieldByLabel = {};
        this.groupByValue = {};
        this.groupByLabel = {};
        this.filterByValue = {};
        this.filterByLabel = {};

        for (let i = 0, len = this.fields.length; i < len; i++) {
            const field = this.fields[i];
            this.fieldByLabel[field.label] = this.fieldByValue[field.value] = field;
        }

        for (let i = 0, len = this.groups.length; i < len; i++) {
            const group = this.groups[i];
            this.groupByLabel[group.label] = this.groupByValue[group.value] = group;
        }

        for (let i = 0, len = this.filters.length; i < len; i++) {
            const filter = this.filters[i];
            this.filterByLabel[filter.label] = this.filterByValue[filter.value] = filter;
        }
    }

    private fulfilActivity() {
        const model = activitiesService.getActivity(this.activity.type);

        if (!model) {
            return;
        }

        const userActivityLabels = this.activity.fields.map((field) => field.label);
        const requiredActivityMetrics = _.filter(model.metrics, (metric) => metric.required);

        for (let i = 0, len = requiredActivityMetrics.length; i < len; i++) {
            const metric = requiredActivityMetrics[i];

            if (userActivityLabels.indexOf(metric.label) < 0) {
                this.activity.fields.push(metric);
            }
        }
    }

    private extendList(base: IReportingEntry[], other: IReportingEntry[], matchBy: string, extendProps: string[]) {
        base.forEach((baseItem: IReportingEntry) => {
            const found = _.find(other, (otherItem) => baseItem[matchBy] === otherItem[matchBy]);
            if (found) {
                for (const prop of extendProps) {
                    baseItem[prop] = found[prop];
                }
            }
        });
    }
}
