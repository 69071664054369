import App from "./App";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";

export default inject((stores: any, props: any, context: any): any => ({
    userActivities: stores.currentUserStore.activities,
    userName: stores.currentUserStore.fullName,
    userEmail: stores.currentUserStore.email,
    userAvatarUrl: stores.currentUserStore.avatar,
    onSignOut: stores.sessionStore.logout,
    notifications: stores.notificationsStore.notifications,
    removeNotification: stores.notificationsStore.removeNotification,
    openMyProfile: stores.profileStore.open,
    hiddenAppHeader: stores.uiStore.hiddenAppHeader,
    onboardingStatus: stores.currentUserStore.onboardingStatus,
    isMilkshakeAdmin: stores.currentUserStore.isMilkshakeAdmin,
    isMilkshakeUser: stores.currentUserStore.isMilkshakeUser,
    defaultActivityName: stores.currentUserStore.defaultActivityName,
    isVidazooOrganization: stores.currentUserStore.isVidazooOrganization,
    currentAccount: stores.currentUserStore.currentAccount,
    userSelfDestruct: stores.currentUserStore.userSelfDestruct,
    deleteAuthAccount: stores.currentUserStore.deleteAuthAccount,
    userId: stores.currentUserStore._id,
    userAccounts: stores.sessionStore.userAccounts
}))(withRouter<any>(observer(App)));
