import {BaseListStore} from '@vidazoo/ui-framework';
import {action, observable, transaction} from 'mobx';
import {notificationsStore} from './index';
import {exportToCsvService, storageService} from '../services';
import {IVideo} from "../interfaces/IVideo";
import {debounce} from "lodash";
import {videosAPI} from "../api";
import moment from "moment";

const VIDEOS_SEARCHABLE_FIELDS = ["title"];

const VIDEOS_CSV_FIELDS = [
    {name: "ID", key: "_id"},
    {name: "Title", key: "title"},
    {name: "Activity Name", key: "activityName"},
];

export default class VideosStore extends BaseListStore<IVideo, any, string> {

    private lastFetch: number;
    @observable public pubDate: number;


    constructor() {
        super(notificationsStore, VIDEOS_SEARCHABLE_FIELDS, "videos", "name");
    }


    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.selectedValues = [];
            this.pubDate =  moment.utc().startOf("week").unix() * 1000;
        });
    };


    @action public getItems = (withTtl: number = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        videosAPI.getAll(this.pubDate / 1000)
            .then((res) => {
                if (res.data.count && !res.data.results.length) {
                    notificationsStore.pushErrorNotification({
                        title: "Operation Failed",
                        text: `Too many results to display. Refine your search.`,
                        timeout: 5000
                    });
                }
                this.onLoadItemsSuccess(res)
            })
            .catch((res) => this.onLoadItemsError(res));
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`videos`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`videos`);
        return columnsWidth || {0: 200, 1: 220, 2: 200, 3: 200};
    };

    public downloadCsv = debounce(() => {
        exportToCsvService.exportItems(this.items, VIDEOS_CSV_FIELDS, "videos.csv");
    }, 500);

    @action public setPubDateFilterProp = (value: moment.Moment) => {
        this.pubDate = value.unix() * 1000;
    };
}


