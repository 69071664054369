import BaseItemStore from '@vidazoo/ui-framework/lib/stores/BaseItemStore';
import {notificationsStore} from '../index';
import {action, computed, observable, transaction} from 'mobx';
import {IDomain, IDomainList} from "../../interfaces/IDomainList";
import {domainsListsAPI} from "../../api";
import {guid} from "@vidazoo/ui-framework";
import {exportToCsvService} from "../../services";
import Papa from "papaparse";
import accountManagement from "../../containers/accountManagement";
import _ from "lodash";
import isValidDomain from "is-valid-domain";


export class EditDomainListStore extends BaseItemStore<IDomainList> {
    @observable public filter: string;
    @observable public page: number;
    @observable public pageSize: number;

    constructor() {
        super(notificationsStore, "domainList");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
            };

            this.filter = "";
            this.page = 1;
            this.pageSize = 50;

        });
    }

    @action
    public getItem(id: string) {
        this.isLoading = true;

        domainsListsAPI.getOne(id)
            .then((item) => this.setItem(item.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    public setItem(item) {
        if (!item.totalDomains) {
            item.totalDomains = item.domains.length;
        }
        this.item = item;
        this.isLoading = false;

        _.forEach(this.item.domains, (domain: IDomain) => {
            domain._id = guid();
        });
    }

    @action
    public submit() {
        this.removeDuplicatedDomains();

        this.item.totalDomains = this.item.domains.length;

        domainsListsAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public deleteDomain = (domain: IDomain) => {
        this.item.totalDomains = this.item.totalDomains - 1;
        this.item.domains = this.item.domains.filter((item) => item._id !== domain._id);
    };

    @action public clearList = () => {
        this.item.domains = observable([]);
    };

    @action public addDomain = () => {
        this.item.totalDomains = this.item.totalDomains + 1;

        const domain = {
            url: "",
            _id: guid()
        };

        this.item.domains.unshift(domain);
    };

    @action public updateDomain = (domain: IDomain, value: string) => {
        domain.url = value.trim();
    };

    @computed
    public get filterDomains(): IDomain[] {
        return this.item.domains.filter((domain) => !!~domain.url.toLowerCase().indexOf(this.filter.toLowerCase()));
    }

    @action public changeFilter = (value: string) => {
        this.filter = value;
    };

    @action public downloadCSV = () => {
        exportToCsvService.exportDomainList(this.item.domains);
    };

    @action public uploadFile = (e) => {
        const files = e.target.files;

        if (!files || !files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsText(files[0], "UTF-8");

        reader.onloadend = this.onLoadFile.bind(this);
    };

    @action private onLoadFile = (event: any) => {
        const csv: { data: any[] } = Papa.parse(event.target.result);

        transaction(() => {
            csv.data.forEach((line) => this.addCsvDomain(line[0]));
            this.uniqDomains();
        });
    };

    @action private addCsvDomain = (line) => {
        line && (this.item.domains = [...this.item.domains, {url: line.trim(), _id: guid()}]);
    };

    @action
    private uniqDomains() {
        this.item.domains = _.uniqBy(this.item.domains, "url");
    }

    @action public loadMore = () => {
        if (this.filterDomains.length > this.page * this.pageSize) {
            this.setPage(this.page + 1);
        }
    };

    @action
    public setPage(page) {
        this.page = page;
    }

    @action
    protected removeDuplicatedDomains() {
        const map = {};
        const domainsToSubmit = [];
        this.item.domains.forEach((d) => {
            if (map[d.url]) {
                return;
            } else {
                map[d.url] = 1;
                domainsToSubmit.push(d);
            }
        });
        this.item.domains = domainsToSubmit;
        this.item.totalDomains = domainsToSubmit.length;
    }

    public checkAlphanumericValidation = (): string[] => {

        const regex = /^[a-zA-Z0-9]+$/;

        const suspiciousValues = [];

        this.item.domains.map((value) => {
            if (!regex.test(value.url)) {
                suspiciousValues.push(value.url);
            }
        });

        return suspiciousValues;

    };

    public checkDomainValidation = (): string[] => {

        const suspiciousValues = [];

        this.item.domains.map((value) => {
            if (!isValidDomain(value.url)) {
                suspiciousValues.push(value.url);
            }
        });

        return suspiciousValues;

    };
    public onDomainValidation = (): boolean => {

        const isDomain = this.item.type === "domain";

        if (isDomain) {
            const suspiciousValues = this.checkDomainValidation();
            return (isDomain && (suspiciousValues.length > 0));
        }
    };
}

