import * as _ from "lodash";
import {observable, transaction, action, computed, IObservableArray} from "mobx";
import {scheduleReportsAPI} from "api";
import { notificationsStore } from "..";
import {guid, IDictionary} from "@vidazoo/ui-framework";
import {SortDirection} from "@vidazoo/ui";
import {multiWordFilter} from "@vidazoo/ui/lib/components/utils";
import {storageService} from "../../services";

export default class ScheduleReportsAdminLogsStore {
    @observable public items: any;
    @observable public isLoading: boolean;
    @observable public error: any;
    @observable public sortBy: string;
    @observable public sortDirection: SortDirection | any;
    @observable public searchQueries: IDictionary<string>;
    @observable public selectedValues: string[];

    constructor(protected searchList: any[] = [], sort: string = "date") {
        this.reset(sort);
    }

    @action public reset(sort: string = "date") {
        transaction(() => {
            this.items = observable([]);
            this.error = null;
            this.isLoading = null;
            this.sortBy = sort;
            this.sortDirection = SortDirection.DESC;
            this.resetSearchQueries();
            this.selectedValues = observable([]);
        });
    }

    @action private setAdminLogs = (res: any) => {
        transaction(() => {
            this.items = res;
            this.isLoading = false;
        });
    }

    @action public getAdminLogs = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        scheduleReportsAPI.getAdminLogs()
            .then((res) => this.setAdminLogs(res.data))
            .catch((err) => this.setError(err));
    }

    @action private setError(err: any) {
        transaction(() => {
            this.isLoading = false;
            this.error = err;
            this.onItemsFail();
        });
    }

    @action protected onItemsSuccess() {
        notificationsStore.pushSuccessNotification({
            title: "Operation Success",
            text: "Test ran successfully",
            timeout: 5000
        });
    }

    @action protected onItemsFail() {
        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: "Failed to load items, try again",
            timeout: 5000
        });
    }

    @action public setSearchQuery = (searchKey: string, value: string) => {
        transaction(() => {
            this.searchQueries[searchKey] = value;
        });
    }

    @action protected resetSearchQueries() {
        transaction(() => {
            const searchQueries = {};
            _.forEach(this.searchList, (field) => {
                searchQueries[field] = "";
            });

            this.searchQueries = searchQueries;
        });
    }

    @action public sort = (sortBy: string, preserveDirection?: boolean) => {
        transaction(() => {
            if (sortBy === this.sortBy && !preserveDirection) {
                this.sortDirection = this.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
            }

            this.sortBy = sortBy;

            this.items.replace(_.orderBy(this.items, [this.sortBy], [this.sortDirection.toString()]));
        });
    }

    @computed public get filteredItems() {

        if (this.items) {
            const hasQueries = _(this.searchQueries).values().compact().value().length > 0;

            if (!hasQueries) {
                return this.items;
            }

            return _.filter(this.items, (item) => {
                const matchQueries = _.map(this.searchQueries, (query: string, field: string) => {
                    const value = (_.get(item, field, "") || "").toString();
                    return multiWordFilter(value, query);
                });

                return _.every(matchQueries);
            });
        }
    }

    @action public downloadCsv = (id, path) => {
        scheduleReportsAPI.getOneLog(id, path)
            .then((res) => res.data.startsWith("http") ? window.open(res.data) : this.onDownloadFail())
            .catch(() => this.onDownloadFail());
    }

    @action protected onDownloadFail() {
        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: "Failed to generate link, try again",
            timeout: 5000
        });
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`scheduleReportsAdminLogs`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`scheduleReportsAdminLogs`);
    }
}
