import IUser from "interfaces/IUser";
import {AccountSource} from "../common/enums";

const JacoRecorder = (window as any).JacoRecorder;
const MixPanel = (window as any).mixpanel;

class TrackingService {

    private initialized: boolean;

    constructor() {
        this.initialized = false;
    }

    public initialize(user: IUser, source: AccountSource) {
        if (this.initialized) {
            return;
        }

        this.initialized = true;

        const isVidaooUser = user.organization.name === "vidazoo";
        const isMilkshakeAccount = source === AccountSource.MILKSHAKE;

        if (JacoRecorder) {
            JacoRecorder.identify(user.username);
        }

        if (MixPanel) {
            MixPanel.identify(user.username);
            MixPanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $created: user.date,
                $email: user.email
            });
        }

        // Intercom: https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-single-page-app
        // this._loadIntercom();
        // const Intercom = (window as any).Intercom;
        // Intercom("boot", {
        //     app_id: window["__INTERCOM_ID__"],
        //     email: user.email,
        //     created_at: user.date,
        //     name: `${user.firstName} ${user.lastName}`,
        //     user_id: user._id,
        // });

        // if (isMilkshakeAccount) {
        //     this._loadHubspot();
        // }

        // this._loadFullStory();
        // const FullStory = window[window["_fs_namespace"]] as any;
        // FullStory.identify(user._id, {
        //     email: user.email,
        // });
    }

    private _loadHubspot() {
        /* tslint:disable */
        (function () {
            var w: any = window;

            var d = document;
            var user = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = "//js.hs-scripts.com/2694753.js";
                s.id = "hs-script-loader";
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                user();
            } else if (w.attachEvent) {
                w.attachEvent('onload', user);
            } else {
                w.addEventListener('load', user, false);
            }
        })();
    }

    private _loadIntercom() {
        /* tslint:disable */
        (function () {
            var w: any = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i: any = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var user = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + window["__INTERCOM_ID__"];
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    user();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', user);
                } else {
                    w.addEventListener('load', user, false);
                }
            }
        })();
        /* tslint:enable */
    }

    private _loadFullStory() {
        /* tslint:disable */
        window['_fs_debug'] = false;
        window['_fs_host'] = 'fullstory.com';
        window['_fs_org'] = window["__FULLSTORY_ID__"];
        window['_fs_namespace'] = 'FS';
        (function (m, n, e, t, l, o, g, y) {
            if (e in m) {
                if (m.console && m.console.log) {
                    m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                }
                return;
            }
            g = m[e] = function (a, b, s) {
                g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
            } as any;
            g.q = [];
            o = n.createElement(t);
            o.async = 1;
            o.crossOrigin = 'anonymous';
            o.src = 'https://' + window['_fs_host'] + '/s/fs.js';
            y = n.getElementsByTagName(t)[0];
            y.parentNode.insertBefore(o, y);
            g.identify = function (i, v, s) {
                g(l, {uid: i}, s);
                if (v) {
                    g(l, v, s);
                }
            };
            g.setUserVars = function (v, s) {
                g(l, v, s);
            };
            g.event = function (i, v, s) {
                g('event', {n: i, p: v}, s);
            };
            g.shutdown = function () {
                g("rec", !1);
            };
            g.restart = function () {
                g("rec", !0);
            };
            g.log = function (a, b) {
                g("log", [a, b]);
            };
            g.consent = function (a) {
                g("consent", !arguments.length || a);
            };
            g.identifyAccount = function (i, v) {
                o = 'account';
                v = v || {};
                v.acctId = i;
                g(o, v);
            };
            g.clearUserCookie = function () {
            };
        })(window, document, window['_fs_namespace'], 'script', 'user');
        /* tslint:enable */
    }
}

export default new TrackingService();
