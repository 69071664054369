import {BaseListStore} from '@vidazoo/ui-framework';
import {action, observable, transaction} from 'mobx';
import {notificationsStore} from './index';
import {IConnection} from '../interfaces/IConnection';
import {exportToCsvService, storageService} from '../services';
import connectionsAPI from "../api/connectionsAPI";

const ConnectionCsvFields = [
    {name: "ID", key: "_id"},
    {name: "Name", key: "name"},
    {name: "Third Party Revenue Share", key: "thirdPartyRevenueShare"},
    {name: "Active", key: "isActive"},
    {name: "Created Date", key: "created"}

];
const CONNECTIONS_SEARCHABLE_FIELDS = ["name"];

export default class ConnectionsStore extends BaseListStore<IConnection, any, string> {

    private lastFetch: number;

    constructor() {
        super(notificationsStore, CONNECTIONS_SEARCHABLE_FIELDS, "connection", "name");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.selectedValues = [];
        });
    }

    @action public getItems = (withTtl = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        connectionsAPI.getAll()
            .then((res) => {
                this.onLoadItemsSuccess(res);
            })
            .catch((res) => this.onLoadItemsError(res));
    };

    @action
    public onDownloadCSV = () => {
        exportToCsvService.exportItems(this.items, ConnectionCsvFields,'connections');
    };

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`connections`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`connections`);
        return columnsWidth || {0: 500, 1: 200, 2: 200, 3: 250};
    };
}

