import * as moment from "moment";
import {socketService} from "services";
import * as _ from "lodash";
import {
    percentageOf,
    momentToTimeZoneUnix,
    formatNumber,
    IDictionary,
    IReportingEntry,
    IReportingFilter,
    IReportingConstraint
} from "@vidazoo/ui-framework";
import countries from "@vidazoo/ui-framework/lib/common/countries";
import IDashboardActivityParams from "interfaces/dashboard/IDashboardActivityParams";
import IDashboardDeviceRevenue from "interfaces/dashboard/IDashboardDeviceRevenue";
import IDashboardCountryRevenue from "interfaces/dashboard/IDashboardCountryRevenue";
import IDashboardDomainRevenue from "interfaces/dashboard/IDashboardDomainRevenue";
import IReportingActivityParams from "interfaces/reporting/IReportingActivityParams";
import IReportingActivityResults from "interfaces/reporting/IReportingActivityResults";
import ActivityContext from "stores/ActivityContext";
import {SortDirection} from "@vidazoo/ui";
import {sessionStore} from "stores";
import {DashboardTypes, RevByOptions, TimeFormats, VerticalType} from "../common/enums";
import {getRevByName, getVsName} from "../common/utils";
import IFieldsIndexed from "../interfaces/reporting/IFieldsIndexed";
import {IReportingMetadata, IReportingMetadataByVertical} from "../interfaces/reporting/IReportingMetadata";
import * as timeFormat from "hh-mm-ss";
import reportingFiltersManager from "../stores/reporting/filters/reportingFiltersManager";
import IDashboard from "../interfaces/IDashboard";
import IGroupsIndexed from "../interfaces/reporting/IGroupsIndexed";

class ReportingService {
    public groups: IReportingMetadata;
    public fields: IReportingMetadata;
    public fieldsIndexedByLabel: IFieldsIndexed;
    public fieldsIndexedByValue: IFieldsIndexed;
    public groupsIndexedByValue: IGroupsIndexed;

    protected readonly NUMBER_FORMAT_REGEXP = /(.?)\.(0+)(.?)/;

    public initialize(metaData: IReportingMetadataByVertical) {
        this.groups = metaData.groups;

        this.fields = {
            [VerticalType.PLAYER]: metaData.fields[VerticalType.PLAYER].map(this.enhanceField.bind(this)),
            [VerticalType.CONNECTION]: metaData.fields[VerticalType.CONNECTION].map(this.enhanceField.bind(this)),
            [VerticalType.DISPLAY]: metaData.fields[VerticalType.DISPLAY].map(this.enhanceField.bind(this)),
            [VerticalType.OPEN_RTB]: metaData.fields[VerticalType.OPEN_RTB].map(this.enhanceField.bind(this))
        } as IReportingMetadata;

        this.fieldsIndexedByLabel = {
            player: _.keyBy(metaData.fields[VerticalType.PLAYER], "label"),
            connection: _.keyBy(metaData.fields[VerticalType.CONNECTION], "label"),
            display: _.keyBy(metaData.fields[VerticalType.DISPLAY], "label"),
            open_rtb: _.keyBy(metaData.fields[VerticalType.OPEN_RTB], "label"),
        } as IFieldsIndexed;

        this.fieldsIndexedByValue = {
            player: _.keyBy(metaData.fields[VerticalType.PLAYER], "value"),
            connection: _.keyBy(metaData.fields[VerticalType.CONNECTION], "value"),
            display: _.keyBy(metaData.fields[VerticalType.DISPLAY], "value"),
            open_rtb: _.keyBy(metaData.fields[VerticalType.OPEN_RTB], "value"),
        } as IFieldsIndexed;

        this.groupsIndexedByValue = {
            player: _.keyBy(metaData.groups[VerticalType.PLAYER], "value"),
            connection: _.keyBy(metaData.groups[VerticalType.CONNECTION], "value"),
            display: _.keyBy(metaData.groups[VerticalType.DISPLAY], "value"),
            open_rtb: _.keyBy(metaData.groups[VerticalType.OPEN_RTB], "value"),
        } as IFieldsIndexed;
    }

    public getFieldByValue(vertical: string, value: string): IReportingEntry {
        return this.fieldsIndexedByValue[vertical][value];
    }

    public getFieldByLabel(vertical: string, label: string): IReportingEntry {
        return this.fieldsIndexedByLabel[vertical][label];
    }

    public getReport(params: IReportingActivityParams, verticalType: string, activityId: string) {
        return socketService.getReport(this.getReportParams(params, verticalType, activityId));
    }

    public buildResults(groups: IReportingEntry[], fields: IReportingEntry[], data: any, context: ActivityContext): IReportingActivityResults {
        let results = {
            fields,
            groups,
            originResults: data,
            result: [] as any,
            groupsByName: {},
            fieldsByName: {}
        };

        if (data.length) {

            const result: any[] = _.map<any>(data, (item, idx) => {
                const dto = {
                    id: idx,
                    fields: [],
                    groups: [],
                    indexed: {}
                };

                const requestedFieldsDtos = [];
                const nonRequestedFieldsDtos = [];

                _.forEach(item.fields, (fieldNumericValue, fieldValue, fIndex) => {

                    const requestedFieldIndex = _.findIndex(fields, {value: fieldValue});
                    const isRequested = requestedFieldIndex > -1;
                    const fieldFromContext = isRequested ? context.getFieldByValue(fieldValue) : this.getFieldByValue(context.verticalType, fieldValue);

                    const fieldDto: any = {
                        id: fIndex,
                        name: fieldFromContext.label,
                        value: fieldNumericValue,
                        type: fieldFromContext.type,
                        isRequested
                    };

                    fieldDto.displayValue = fieldFromContext.format ? fieldFromContext.format(fieldDto.value) : formatNumber(fieldDto.value, 2);

                    // this is critical in order to preserve the requested fields order
                    if (isRequested) {
                        requestedFieldsDtos[requestedFieldIndex] = fieldDto;
                    } else {
                        nonRequestedFieldsDtos.push(fieldDto);
                    }
                });

                dto.fields = _.uniqBy([...requestedFieldsDtos, ...nonRequestedFieldsDtos], "name");

                groups.forEach((group, gIndex) => {
                    let value = item.groups[group.value];
                    const originalId = group.value === "advertiserName" ? item.groups["advertiserId"] : item.groups[`${group.value}Original`];
                    (value === "marketPlace") && (value = "Market Place");

                    dto.groups.push({
                        id: gIndex,
                        name: group.label,
                        valueDisplay: value,
                        hasFilterType: !!context.getFilterByValue(group.value),
                        value,
                        originalId
                    });

                });

                dto.indexed = _.keyBy(dto.groups.concat(dto.fields), "name");

                return dto;
            });

            results = {
                result,
                fields: result.length ? result[0].fields : [],
                fieldsByName: _.keyBy(result[0].fields, "name"),
                groups: result.length ? result[0].groups : [],
                groupsByName: _.keyBy(result[0].groups, "name"),
                originResults: data
            };
        }
        return results;
    }

    private getReportParams(params: IReportingActivityParams, verticalType: string, activityId: string) {
        return {
            ids: params.publisherIds,
            from: momentToTimeZoneUnix(params.from, params.timezone),
            to: momentToTimeZoneUnix(params.to, params.timezone),
            fields: params.fields.map((field) => field.value),
            groups: params.groups.map((group) => group.value),
            verticalType,
            activityId,
            filters: this.prepareReportFilters(params.filters),
            constraints: this.prepareReportConstraints(params.constraints),
            timeZone: params.timezone
        };
    }

    public prepareReportFilters(filters: IReportingFilter[]) {
        const results = [];

        if (!filters || !filters.length) {
            return results;
        }

        for (let i = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            const values = _.compact(filter.values);

            if (values.length) {
                results.push({
                    key: filter.key,
                    values: _.map(filter.values, (value: any) => _.isObject(value) ? value[filter.filterValueKey] : value),
                    operator: filter.operator
                });
            }
        }

        return results;
    }

    public prepareReportFiltersWithLabels(filters: IReportingFilter[], verticalType: string) {
        const results = [];

        if (!filters || !filters.length) {
            return results;
        }

        for (let i = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            const values = _.compact(filter.values);

            if (values.length) {
                results.push({
                    key: filter.key ? this.groupsIndexedByValue[verticalType][filter.key].label : "",
                    values: _.map(filter.values, (value: any) => _.isObject(value) ? value[filter.filterValueKey] : value),
                    operator: filter.operator
                });
            }
        }

        return results;
    }

    public prepareReportConstraintsWithLabels(constraints: IReportingConstraint[], verticalType: string) {
        const results = [];

        if (!constraints || !constraints.length) {
            return results;
        }

        for (let i = 0, len = constraints.length; i < len; i++) {
            const {name, op, value} = constraints[i];

            const key = name ? this.fieldsIndexedByValue[verticalType][name].label : "";

            results.push({key, op, value});
        }

        return results;
    }

    public prepareReportConstraints(constraints: IReportingConstraint[]) {
        const results = [];

        if (!constraints || !constraints.length) {
            return results;
        }

        for (let i = 0, len = constraints.length; i < len; i++) {
            const {name, op, value} = constraints[i];

            results.push({name, op, value});
        }

        return results;
    }

    public getAllDashboardReports(params: IDashboardActivityParams, context: ActivityContext) {
        const {from, to, publisherIds, dayField, timezone: dashboardTimezone, verticalType} = params;
        const baseParams = this.getDashboardParams(from, to, dashboardTimezone, publisherIds, verticalType, context.id);

        const queries = context.dashboards.reduce((obj, dashboard) => {
            const {type, groups, fields} = dashboard;

            switch (type) {
                case DashboardTypes.PERFORMANCE:
                    obj[getVsName(fields)] = {
                        fields: fields.map((field) => context.getFieldByLabel(field).value),
                        groups: [dayField],
                        ...baseParams
                    };
                    obj[fields[0] + fields[1]] = {
                        fields: fields.map((field) => context.getFieldByLabel(field).value),
                        groups: groups || [],
                        ...baseParams
                    };
                    break;
                case DashboardTypes.EXPANDABLE:
                case DashboardTypes.CIRCLES:
                    obj[getRevByName(groups)] = {
                        fields: [context.getFieldByLabel(fields[0]).value],
                        groups: groups.map((group) => context.getGroupByLabel(group).value),
                        ...baseParams
                    };
                    break;
            }

            return obj;
        }, {});

        return socketService.getReportMulti(queries);
    }

    public buildDashboardResults(data, context: ActivityContext, params: IDashboardActivityParams): Promise<any> {
        context.dashboards.forEach((dashboard) => this._buildDashboardResult(dashboard, data, context, params));
        return Promise.resolve(context.dashboards);
    }

    public _buildDashboardResult(dashboard: IDashboard, data, context: ActivityContext, params: IDashboardActivityParams) {
        const {type, fields, groups} = dashboard;
        dashboard.data = {};
        switch (type) {
            case DashboardTypes.PERFORMANCE:
                dashboard.data[getVsName(fields)] = this.buildVsReportByFields(data, context, params.dayField, fields);
                dashboard.data[fields[0]] = this.getSingleValue(data, fields[0] + fields[1], context.getFieldByLabel(fields[0]));
                dashboard.data[fields[1]] = this.getSingleValue(data, fields[0] + fields[1], context.getFieldByLabel(fields[1]));
                break;
            case DashboardTypes.EXPANDABLE:
            case DashboardTypes.CIRCLES:
                dashboard.data[getRevByName(groups)] = this.buildRevBy(data[getRevByName(groups)], context, dashboard);
                break;
        }
    }

    public getDashboardReport(params: IDashboardActivityParams, context: ActivityContext) {
        return this._getDashboardReport(params, context, context.dashboards[0]);
    }

    public async _getDashboardReport(params: IDashboardActivityParams, context: ActivityContext, dashboard: IDashboard) {
        const {from, to, publisherIds, dayField, timezone: dashboardTimezone, verticalType} = params;
        const baseParams = this.getDashboardParams(from, to, dashboardTimezone, publisherIds, verticalType, context.id);

        const {type, groups, fields} = dashboard;
        const query = {};

        switch (type) {
            case DashboardTypes.PERFORMANCE:
                query[getVsName(fields)] = {
                    fields: fields.map((field) => context.getFieldByLabel(field).value),
                    groups: [dayField],
                    ...baseParams
                };
                query[fields[0] + fields[1]] = {
                    fields: fields.map((field) => context.getFieldByLabel(field).value),
                    groups: groups || [],
                    ...baseParams
                };
                break;
            case DashboardTypes.EXPANDABLE:
            case DashboardTypes.CIRCLES:
                query[getRevByName(groups)] = {
                    fields: [context.getFieldByLabel(fields[0]).value],
                    groups: groups.map((group) => context.getGroupByLabel(group).value),
                    ...baseParams
                };
                break;
        }

        return socketService.getReportMulti(query);
    }

    private getSingleValue(data, entry, field: IReportingEntry) {
        try {
            const value = data[entry][0].fields[field.value];
            return typeof field.format === "function" ? field.format(value) : value.toLocaleString();
        } catch (e) {
            return 0;
        }
    }

    private buildVsReportByFields(data, context: ActivityContext, dayField, fields) {
        data = _(data[getVsName(fields)]).orderBy((item) => this.getDate(item, dayField), SortDirection.ASC.toString() as any).value();

        const results = {
            [fields[0]]: [],
            [fields[1]]: []
        };

        if (data.length) {

            const fieldOne = context.getFieldByLabel(fields[0]);
            const fieldTwo = context.getFieldByLabel(fields[1]);

            // const fieldOneFormat = typeof fieldOne.format === "function" ? (v) => parseFloat(fieldTwo.format(v)) : (v) => v;
            // const fieldTwoFormat = typeof fieldTwo.format === "function" ? (v) => parseFloat(fieldTwo.format(v)) : (v) => v;

            data.forEach((item) => {
                const date = this.getDate(item, dayField);
                results[fields[0]].push([date, item.fields[fieldOne.value]]);
                results[fields[1]].push([date, item.fields[fieldTwo.value]]);
            });
        }

        return results;
    }

    private buildRevBy(data, context: ActivityContext, dashboard) {
        switch (context.getGroupByLabel(dashboard.groups[0]).value) {
            case RevByOptions.COUNTRY:
                return this.buildRevByCountry(data, context, dashboard.fields);
            case RevByOptions.DEVICE:
                return this.buildRevByDevice(data, context, dashboard.fields);
            case RevByOptions.DOMAIN:
                return this.buildRevByDomain(data, context, dashboard.fields);
        }
    }

    private buildRevByDevice(data, context: ActivityContext, fields): IDictionary<IDashboardDeviceRevenue> {

        const results = {
            desktop: {name: "desktop", value: 0, percentage: 0},
            mobileWeb: {name: "mobileWeb", value: 0, percentage: 0},
            inApp: {name: "inApp", value: 0, percentage: 0}
        };

        const revenueFieldValue = context.getFieldByLabel(fields[0]).value;

        if (data.length) {

            const top = _(data)
                .sortBy((x) => x.fields[revenueFieldValue])
                .reverse()
                .filter((x) => x.fields[revenueFieldValue])
                .take(5)
                .value();

            const total = _.sumBy<any>(top, (x) => _.round(x.fields[revenueFieldValue], 2));

            _(top).forEach<any>((x) => {
                const value = _.round(x.fields[revenueFieldValue], 2);

                switch (x.groups.device) {
                    case "desktop":
                        results.desktop.value += value;
                        results.desktop.percentage += percentageOf(value, total);
                        break;
                    case "mobile":
                    case "tablet":
                        results.mobileWeb.value += value;
                        results.mobileWeb.percentage += percentageOf(value, total);
                        break;
                    case "inapp_mobile":
                    case "inapp_tablet":
                        results.inApp.value += value;
                        results.inApp.percentage += percentageOf(value, total);
                        break;
                }
            });
        }

        return results;
    }

    private buildRevByCountry(data, context: ActivityContext, fields): IDashboardCountryRevenue[] {
        if (data.length) {
            const revenueFieldValue = context.getFieldByLabel(fields[0]).value;

            const total = _.sumBy<any>(data, (x) => _.round(x.fields[revenueFieldValue], 2));

            return _(data)
                .sortBy((x) => x.fields[revenueFieldValue])
                .reverse()
                .filter((x) => x.fields[revenueFieldValue])
                .map<any, any>((x) => {
                    const country = _.find<any>(countries, {name: x.groups.country});
                    const value = _.round(parseFloat(x.fields[revenueFieldValue]), 2);
                    return {
                        name: x.groups.country,
                        code: country ? country.code : x.groups.country,
                        value,
                        percentage: percentageOf(value, total)
                    };
                })
                .value();
        }

        return [];
    }

    private buildRevByDomain(data, context: ActivityContext, fields): IDashboardDomainRevenue[] {
        if (data.length) {

            const revenueFieldValue = context.getFieldByLabel(fields[0]).value;

            const total = _.sumBy<any>(data, (x) => _.round(x.fields[revenueFieldValue], 2));

            return _(data)
                .sortBy((x) => x.fields[revenueFieldValue])
                .reverse()
                .filter((x) => x.fields[revenueFieldValue])
                .map<any, any>((x) => {
                    const value = _.round(parseFloat(x.fields[revenueFieldValue]), 2);
                    return {
                        name: x.groups.domain,
                        value,
                        percentage: percentageOf(value, total)
                    };
                })
                .value();
        }

        return [];
    }

    private getDate(reportRow, dayField) {
        const dateValue = reportRow.groups[dayField];
        return dayField === "day" ? moment.utc(dateValue, "DD/MM/YYYY").unix() * 1000 : dateValue;
    }

    private getDashboardParams(currFrom, currTo, dashboardTimezone, publisherIds, verticalType, activityId) {
        return {
            selectedAccount: sessionStore.selectedAccounts[0],
            from: momentToTimeZoneUnix(currFrom, dashboardTimezone),
            to: momentToTimeZoneUnix(currTo, dashboardTimezone),
            timezone: dashboardTimezone,
            ids: publisherIds,
            verticalType,
            activityId
        };
    }

    protected enhanceField(field: IReportingEntry): IReportingEntry {
        if (field.formula) {
            field.formula = this.compileFieldFormula(field.formula);
        }

        if (field.format) {
            field.format = this.compileFieldFormat(field.format);
        }

        return field;
    }

    protected compileFieldFormula(formula): (dto: any) => number {
        let template;
        if (formula && formula.includes("${")) {
            template = _.template(formula);

        } else {
            template = _.template(formula, {interpolate: /([a-zA-Z][\w]+)/g});
        }

        return (dto) => {
            try {
                const fn = new Function("return " + template(dto));
                const result = fn();
                return (Infinity === result || isNaN(result)) ? 0 : result;
            } catch (e) {
                return 0;
            }
        };
    }

    protected compileFieldFormat(format): (value: number) => string {
        if (TimeFormats[format]) {
            return (value) => timeFormat.fromS(Math.floor(value), format);
        }
        const matches = format.match(this.NUMBER_FORMAT_REGEXP);
        if (matches) {
            const [pre, decimals, post] = matches.slice(1);
            return (value) => pre + formatNumber(value, decimals.length) + post;
        }
    }
}

export default new ReportingService();
