import { action, computed, observable, toJS, transaction } from "mobx";
import * as _ from "lodash";
import { gravatar, guid } from "@vidazoo/ui-framework";
import { RemotePasswordValidator } from "stores";
import IUser from "interfaces/IUser";
import IActivity from "interfaces/IActivity";
import { activitiesService } from "services";
import { AccountOnboardingStatus, AccountSource, UserRole } from "common/enums";
import IAccount from "interfaces/IAccount";

export default class BaseUserStore implements IUser {
    public _id: string;
    public date: number;
    public isDeleted: boolean;
    public avatar: string;
    @observable public username: string;
    @observable public activities: IActivity[];
    @observable public roles: UserRole[];
    @observable public firstName: string;
    @observable public lastName: string;
    @observable public email: string;
    @observable public password: string;
    @observable public isActive: boolean;
    @observable public userSelfDestruct: boolean;

    @observable public selectedActivity: IActivity;

    @observable public passwordValidator: RemotePasswordValidator;

    @action public initialize(user: IUser, currentAccount: IAccount) {
        transaction(() => {
            this._id = user._id;
            this.date = user.date;
            this.username = user.username;
            this.activities = currentAccount.activities || [];
            this.roles = this.isMockingUser() ? [UserRole.USER] : user.roles;
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.email = user.email;
            this.password = "";
            this.isActive = user.isActive;
            this.isDeleted = user.isDeleted;
            this.selectedActivity = this.activities.length > 0 ? this.activities[0] : null;
            this.avatar = gravatar.getUrl(this.email, 100);
            this.userSelfDestruct = user.selfDestruct;
        });
    }

    private isMockingUser() {
        return localStorage.getItem("AS_USER") !== null;
    }

    @action public reset = () => {
        this.initialize({
            _id: null,
            date: null,
            username: "",
            activities: [],
            roles: [UserRole.USER],
            firstName: "",
            lastName: "",
            email: "",
            isActive: true,
            isDeleted: false
        }, null);
    }

    @action public updateParam = (key: string, value: any) => {
        if (this[key] !== undefined) {
            this[key] = value;

            if (key === "password") {
                this.passwordValidator.checkPassword(value);
            }
        }
    }

    @action public updateParams = (partial: Partial<IUser>) => {
        _.forEach(partial, (value: string, key: string) => {
            this.updateParam(key, value);
        });
    }

    @action public addActivity = (type: string, select?: boolean) => {
        const model = activitiesService.getActivity(type);

        if (!model) {
            return;
        }

        transaction(() => {
            this.activities = this.activities.concat({
                _id: guid(),
                type: model.type,
                name: model.defaultName,
                verticalType: model.verticalType,
                groups: model.dimensions,
                fields: model.metrics,
                publisherIds: [],
                dashboards: [],
                accountIds: [],
                allowUploadContent: false,
                cmsAccount: ""
            });

            if (select) {
                this.selectedActivity = this.activities[this.activities.length - 1];
            }
        });
    }

    @action public removeActivity = (id: string) => {
        transaction(() => {
            this.activities = this.activities.filter((activity) => activity._id !== id);

            if (this.activities.length === 0) {
                this.selectedActivity = null;
            } else {
                this.selectedActivity = this.activities[0];
            }
        });
    }

    @action public setSelectedActivity(activity: IActivity) {
        this.selectedActivity = activity;
    }

    @action public setSelectedActivityById = (id: string) => {
        const activity = this.activities.find((x) => x._id === id);

        if (activity) {
            this.setSelectedActivity(activity);
        }
    }

    @action public setActivityParam = (activity: IActivity, key: string, value: any) => {
        activity[key] = value;
    }

    @action public addToActivityCollection = (activity: IActivity, collection: string, value: any) => {
        activity[collection] = activity[collection].concat(value);
    }

    @action public removeFromActivityCollection = (activity: IActivity, collection: string, value: any) => {
        activity[collection] = activity[collection].filter((x) => x !== value);
    }

    @action public addKeyValuePairToActivityCollection = (activity: IActivity, collection: string) => {
        this.addToActivityCollection(activity, collection, {
            label: "",
            value: ""
        });
    }

    @action public setItemParam = (item: any, key: string, value: any) => {
        item[key] = value;
    }

    @computed get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public toJSON() {
        return {
            id: this._id,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            roles: this.roles,
            activities: this.activities
        };
    }

    public toSlimJSON() {
        return _.omit(this.toJSON(), ["_id"]);
    }

    public submitify() {
        const dto = this.toSlimJSON();

        (dto as any).isActive = this.isActive;
        (dto as any).isDeleted = this.isDeleted;

        return toJS(dto);
    }
}
