import * as _ from "lodash";
import * as moment from "moment";
import {IReportingEntry} from "@vidazoo/ui-framework";
import ActivityContext from "stores/ActivityContext";
import * as papa from "papaparse";
import {dateFields} from "../common/enums";
import {IReportingLog} from "../interfaces/ILog";
import {currentUserStore} from "../stores";

export interface ICsvField {
    name: string;
    key: string;
}

class ExportToCsvService {

    private getReportingCsvHeaders(groups: IReportingEntry[], fields: IReportingEntry[]) {
        return _.map<IReportingEntry>(groups, (group) => ({
            id: group.value,
            displayName: group.label
        })).concat(_.map<IReportingEntry>(fields, (field) => ({
            id: field.value,
            displayName: field.label,
            type: field.type
        })));
    }

    private getReportingCsvData(results, columns, context: ActivityContext) {
        return _.map<any>(results.originResults, (data) => {
            const dto = {};

            _.forEach(columns, (column) => {
                _.has(data.groups, column.id) ? dto[column.displayName] = data.groups[column.id] : null;
                _.has(data.fields, column.id) ? dto[column.displayName] = data.fields[column.id] : null;
            });

            return dto;
        });
    }

    private getExportedFileName(name) {
        const date = moment().format("DD.MM.YY_HHmm");
        if(!currentUserStore.isVidazooOrganization){
            return `${name}_${date}.csv`;
        }
        return `vidazoo_${name}_${date}.csv`;
    }

    public exportFilteredReport(results: Array<{ fields: Array<{ name: string, value: any }>, groups: Array<{ name: string, value: number }> }>, paramsHeaders: { name: string, isGroup: boolean }[]) {

        const head = paramsHeaders.map((h) => h.name);
        const body = results.map((row) => {
            return paramsHeaders.map((header) => {
                if (header.isGroup) {
                    return row.groups.find((g) => g.name === header.name).value;
                } else {
                    const field = row.fields.find((f) => f.name === header.name);
                    if (_.isArray(field.value)) {
                        const val = field.value;
                        return String([val[0], val[3].toFixed(2)]);
                    }
                    return String(field.value);
                }
            });
        });

        const csv = papa.unparse({
            fields: head,
            data: body,
        });
        const filename = this.getExportedFileName("report");

        this.download(csv, filename);
    }

    public exportReportCsv(groups, fields, results, context) {
        const filename = this.getExportedFileName("report");
        const columns = this.getReportingCsvHeaders(groups, fields);
        const data = this.getReportingCsvData(results, columns, context);

        const csv = papa.unparse(data, {header: true});

        this.download(csv, filename);
    }

    public exportAdsTxtCsv(adsTxt) {
        const filename = "vidazoo-ads-txt.txt";
        this.download(adsTxt, filename);
    }

    private download(content: any, fileName: string) {
        const blob = new Blob([content]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    public exportItems(items: any[], csvFields: ICsvField[], fileName: string) {
        const head = csvFields.map((h) => h.name);
        const body = [];

        for (const item of items) {
            if (fileName === "sites") {
                const user = item["user"];
                item["user"] = user["username"];
            }
            body.push(csvFields.map((f) => {
                return _.includes(dateFields, f.key) ? moment.utc(item[f.key]).format() : item[f.key];
            }));
        }

        const csv = papa.unparse({
            fields: head,
            data: body,
        });

        this.download(csv, this.getExportedFileName(fileName));
    }

    public exportLogReport(report: Array<{ fields: Array<{ name: string, value: any }>, groups: Array<{ name: string, value: number }> }>) {
        const head = [...Object.keys(report[0].groups), ...Object.keys(report[0].fields)];
        const body = report.map((row) => {
            const groups = Object.keys(row.groups).map((k) => row.groups[k]);
            const fields = Object.keys(row.fields).map((k) => {
                if (_.isArray(row.fields[k])) {
                    const val = row.fields[k];
                    return String([val[0], val[3].toFixed(2)]);
                }
                return String(row.fields[k]);
            });
            return [].concat(groups, fields);
        });

        const csv = papa.unparse({
            fields: head,
            data: body,
        });

        this.download(csv, this.getExportedFileName("log_report"));
    }

    public exportLog(item) {
        const maxLength = 32000;

        if (item.report.length > maxLength) {
            item.report = item.report.substring(0, maxLength);
        }

        const csv = papa.unparse([item], {header: true});

        this.download(csv, this.getExportedFileName("log_response"));
    }

    public exportDomainList(domains) {
        domains = domains.map((domain) => [domain.url]);

        const csv = papa.unparse(domains, {header: false});

        this.download(csv, this.getExportedFileName("domain_lists"));
    }

    public exportCountryList(countries) {
        countries = countries.map((country) => [country.name]);

        const csv = papa.unparse(countries, {header: false});

        this.download(csv, this.getExportedFileName("country_lists"));
    }
}

export default new ExportToCsvService();
