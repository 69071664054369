import { BaseAPI } from "@vidazoo/ui-framework";

class GlobalPresetsReportsAPI extends BaseAPI {
    public getAll(params: {page?: number , page_size?: number, filter?: any, fields?: any, sort?: any}) {
        return this.request({
            method: "get",
            url: "/api/global_presets_reports/",
            params
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/global_presets_reports/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: "/api/global_presets_reports/",
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/global_presets_reports/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/global_presets_reports/${id}/`,
            data
        });
    }

    public active(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/global_presets_reports/${id}/active/`,
            data: { isActive }
        });
    }
}

export default new GlobalPresetsReportsAPI(window.__BASE_API_URL__);
