import { observable, computed, action, transaction } from "mobx";

export default class RemotePasswordValidator {
    @observable public isCheckingPassword: boolean;
    @observable public passwordStrength: any;
    private minValidScore: number;

    constructor(minValidScore: number = 2) {
        this.reset();

        this.minValidScore = minValidScore;
    }

    @action public reset() {
        transaction(() => {
            this.isCheckingPassword = false;
            this.passwordStrength = {};
        });
    }

    @action public setCheckState(state: boolean) {
        this.isCheckingPassword = state;
    }

    @action public checkPassword = (password: string) => {
        transaction(() => {

            this.passwordStrength = {};

            if (!password) {
                return;
            }

            this.isCheckingPassword = true;

            (window as any).__sdk__.utils.passwordStrength(password)
                .then((res) => this.onCheckPasswordComplete(res))
                .catch(() => this.onCheckPasswordError());
        });
    }

    @action private onCheckPasswordComplete(res) {
        transaction(() => {
            this.isCheckingPassword = false;
            this.passwordStrength = res.data;
        });
    }

    @action private onCheckPasswordError() {
        transaction(() => {
            this.isCheckingPassword = false;
            this.passwordStrength = {};
        });
    }

    @computed get passwordScore(): number {
        return this.passwordStrength && !isNaN(this.passwordStrength.score)
            ? this.passwordStrength.score
            : -1;
    }

    @computed get isValid(): boolean {
        return this.passwordScore >= this.minValidScore;
    }
}
