import { BaseAPI } from "@vidazoo/ui-framework";

class AccountManagementAPI extends BaseAPI {
    public getMilkshakeUsersByAccountId(id: string) {
        return this.request({
            method: "get",
            url: `/api/account_management/accounts_by_id/${id}/`,
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/account_management/account/${id}/`,
        });
    }

    public getInvitationsByAccountId(id: string) {
        return this.request({
            method: "get",
            url: `/api/account_management/invitations/${id}/`,
        });
    }

    public sendAccountInvitation(data) {
        return this.request({
            method: "post",
            url: `/api/account_management/send_invitation/`,
            data
        });
    }

    public removeAccountInvitation(data) {
        return this.request({
            method: "post",
            url: `/api/account_management/remove_account_invitation/`,
            data
        });
    }

    public removeAccountUser(data) {
        return this.request({
            method: "post",
            url: `/api/account_management/remove_account_user/`,
            data
        });
    }
}

export default new AccountManagementAPI((window as any).__BASE_API_URL__, true);
