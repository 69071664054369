import * as React from "react";
import {Notification, Label, Button} from "@vidazoo/ui";
import NavigationStore from "stores/NavigationStore";
import {AccountOnboardingStatus} from "common/enums";
import {PathByOnboardingStatus} from "common/constants";
import * as theme from "./theme.scss";
import CurrentUserStore from "stores/CurrentUserStore";

export interface IOnboardingPendingMessageProps {
    onboardingStatus: AccountOnboardingStatus;
    navigationStore?: NavigationStore;
    currentUserStore?: CurrentUserStore;
}

export interface IOnboardingPendingMessageState {
    isOpen: boolean;
}

export default class OnboardingPendingMessage extends React.Component<IOnboardingPendingMessageProps, IOnboardingPendingMessageState> {

    public constructor(props) {
        super(props);

        const isOnboardingPath = window.location.pathname.startsWith("/onboarding");
        const isMilkshakeUser = props.currentUserStore.isMilkshakeUser();

        this.state = {
            isOpen: isMilkshakeUser && (props.onboardingStatus !== AccountOnboardingStatus.DONE) && !isOnboardingPath,
        };
    }

    private _onCancel = () => {
        this._closeModal();
    };

    private _onConfirm = () => {
        this.props.navigationStore.push(this.onboardingPath);
        this._closeModal();
    };

    private _closeModal = () => {
        this.setState({isOpen: false});
    };

    private get onboardingPath() {
        const {onboardingStatus} = this.props;
        return PathByOnboardingStatus[onboardingStatus];
    }

    private get renderContent() {
        const {onboardingStatus} = this.props;
        let text = "We highly recommend you finish the short Publisher Onboarding Process";
        if (onboardingStatus === AccountOnboardingStatus.INTRO) {
            text = "We highly recommend you go through the short Publisher Onboarding Process";
        }
        return (
            <div className={theme.content}>
                <div>
                    <Label>Welcome to Vidazoo Publisher's Platform!</Label>
                    <span>{text}</span>
                </div>
                <Button medium yellow onClick={this._onConfirm}>Let's do it</Button>
            </div>
        );
    }

    public render(): JSX.Element {
        return this.state.isOpen ? (
            <div className={theme.pendingmsg}>
                <Notification
                    warning
                    onClose={this._onCancel}
                    content={this.renderContent}
                    closeBtn
                />
            </div>
        ) : null;
    }
}
