import {observable, action, transaction, runInAction} from "mobx";
import {accountManagementAPI} from "../../src/api";
import {currentUserStore, notificationsStore} from "./index";

export default class AccountManagementStore {

    @observable public isLoadingMilkshakeUsers: boolean;
    @observable public isLoadingInvitations: boolean;
    @observable public accountId: string;
    @observable public users: any[];
    @observable public invitations: any[];
    @observable public roles: any[];
    @observable public organizations: string[];
    @observable public indexOrganizations: any;
    @observable public isLoading: boolean;
    @observable public loadError: boolean;
    @observable public editUser: any;
    @observable public pendingDeleteUser: any;
    @observable public pendingDeleteInvite: any;
    @observable public item: any;
    @observable public isLoadingItem: boolean;

    @action
    public reset() {
        transaction(() => {
            this.isLoading = false;
            this.isLoadingItem = false;
            this.isLoadingMilkshakeUsers = false;
            this.isLoadingInvitations = false;
            this.loadError = false;
            this.accountId = null;
            this.users = [];
            this.invitations = [];
            this.roles = [];
            this.organizations = [];
            this.indexOrganizations = {};
            this.editUser = null;
            this.pendingDeleteUser = null;
            this.pendingDeleteInvite = null;
            this.item = {
                ...this.item,
                username: "",
                adsTxtPublisherId: "",
                isActive: true,
                isInSellers: false,
                companyDomain: "",
                customName: ""
            };
        })
    }

    @action
    public initialize(id: string) {
        transaction(async () => {
            this.reset();
            this.accountId = id;
            this.getItem(id);
            this.getMilkshakeUsersByAccountId(id);
            this.getInvitationsByAccountId(id);
        })
    }

    @action
    public getMilkshakeUsersByAccountId(id: string) {
        this.isLoadingMilkshakeUsers = true;
        accountManagementAPI.getMilkshakeUsersByAccountId(id)
            .then((res) => this.setMilkshakeUsers(res))
    }

    @action
    private setMilkshakeUsers(res) {
        transaction(() => {
            this.users = res.data.results;
            this.isLoadingMilkshakeUsers = false;
        });
    }

    @action
    public getItem(id: string) {
        this.isLoadingItem = true;
        accountManagementAPI.getOne(id)
            .then((item) => this.setItem(item.data))
    }

    @action
    public setItem(item) {
        transaction(() => {
            this.item = item;
            this.loadError = false;
            this.isLoadingItem = false;
        });
    }

    @action
    public getInvitationsByAccountId(id: string) {
        this.isLoadingInvitations = true;
        accountManagementAPI.getInvitationsByAccountId(id)
            .then((res) => this.setInvitations(res))
    }

    @action
    private setInvitations(res) {
        transaction(() => {
            this.invitations = res.data;
            this.isLoadingInvitations = false;
        });
    }

    @action
    public async sendInvitation(email: string, userId: string) {

        const data = {
            email
        };

        try {
            const invitation = await accountManagementAPI.sendAccountInvitation(data);

            // @ts-ignore
            if (!invitation.addScopeToUser) {
                runInAction(() => {
                    this.invitations = [...this.invitations, invitation.data];
                });
                notificationsStore.pushSuccessNotification({
                    title: "Operation Complete",
                    text: `Invitation sent successfully to <b>${email}</b>`,
                    timeout: 5000
                });
            } else {
                notificationsStore.pushSuccessNotification({
                    title: "Operation Complete",
                    text: `This account successfully added to the user`,
                    timeout: 5000
                });
            }

        } catch (e) {
            notificationsStore.pushErrorNotification({
                title: "Operation Failed",
                text: `Failed to send invitation to <b>${email}</b>`,
                timeout: 5000
            });
        }
    }

    @action public setPendingDeleteUser = (user) => {
        this.pendingDeleteUser = user;
    }

    @action public setPendingDeleteInvite = (invite) => {
        this.pendingDeleteInvite = invite;
    }

    @action public deletePendingUser = () => {
        if (!this.pendingDeleteUser) {
            return;
        }

        transaction(async () => {

            const userId = this.pendingDeleteUser._id;
            const currentUser = currentUserStore.toJSON();
            const data = {
                userId
            }

            try {
                await accountManagementAPI.removeAccountUser(data);

                runInAction(() => {
                    this.users = this.users.filter((user) => user !== this.pendingDeleteUser);
                });

                notificationsStore.pushSuccessNotification({
                    title: "Operation Complete",
                    text: "User deleted successfully",
                    timeout: 5000
                });

            } catch (e) {

                notificationsStore.pushErrorNotification({
                    title: "Operation Failed",
                    text: "Failed to delete user",
                    timeout: 5000
                });
            }

            runInAction(() => {
                this.pendingDeleteUser = null;
            });
        });
    }

    @action public deletePendingInvite = () => {
        if (!this.pendingDeleteInvite) {
            return;
        }

        transaction(async () => {

            const data = {
                inviteId: this.pendingDeleteInvite._id,
            }

            try {
                await accountManagementAPI.removeAccountInvitation(data);

                runInAction(() => {
                    this.invitations = this.invitations.filter((invite) => invite !== this.pendingDeleteInvite);
                });

                notificationsStore.pushSuccessNotification({
                    title: "Operation Complete",
                    text: "Invitation deleted successfully",
                    timeout: 5000
                });

            } catch (e) {

                notificationsStore.pushErrorNotification({
                    title: "Operation Failed",
                    text: "Failed to delete invitation",
                    timeout: 5000
                });
            }

            runInAction(() => {
                this.pendingDeleteInvite = null;
            });
        });
    }

}
