import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import "bootstrap";
import * as React from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import { default as UIProvider, IUIProviderConfig } from "@vidazoo/ui/lib/components/uiProvider";
import { Root } from "containers";
import { Provider as StateProvider } from "mobx-react";
import * as stores from "stores";
import * as _ from "lodash";
import { hot } from "react-hot-loader/root";
import { __DEV__ } from "common/constants";
import { ThemeManager } from "@vidazoo/ui-framework";

const uiProviderConfig: IUIProviderConfig = {
    appContainerSelector: "#root",
    linkComponent: Link,
    baseAppTitle: "Platform"
};

if (process.env.NODE_ENV === "production") {
    disableReactDevTools();
}

render(
    <UIProvider config={uiProviderConfig}>
        <StateProvider {...stores}>
            <ThemeManager>
                <Root />
            </ThemeManager>
        </StateProvider>
    </UIProvider>
    ,
    document.querySelector(uiProviderConfig.appContainerSelector)
);

if (__DEV__) {
    hot(Root);
}
