import * as React from "react";
import * as classnames from "classnames";
import * as theme from "./theme.scss";
import {RouteComponentProps} from "react-router";
import {AccountsList, UserScope, VidazooLogo, ViewAsList} from "components";
import {AppBar} from "@vidazoo/ui/lib/components/appBar";
import {UserMenu} from "@vidazoo/ui/lib/components/userMenu";
import {MainMenu} from "@vidazoo/ui/lib/components/mainMenu";
import {MenuItem} from "@vidazoo/ui/lib/components/menu";
import IActivity from "interfaces/IActivity";
import {appUrlsService} from "services";
import {ThemeSwitch, UserRole, NotificationPanel,} from "@vidazoo/ui-framework";
import {Modal, VidazooHeaderLogo} from "@vidazoo/ui";
import IAccount from "../../interfaces/IAccount";
import {userNotificationsStore} from "../../stores";
import {ActivityType} from "../../common/enums";

export interface IAppHeaderProps extends RouteComponentProps<any> {
    userName?: string;
    userEmail?: string;
    userAvatarUrl?: string;
    userActivities?: IActivity[];
    onSignOut?: () => void;
    openMyProfile?: () => void;
    hidden?: boolean;
    isMilkshakeAdmin?: () => boolean;
    isMilkshakeUser?: () => boolean;
    defaultActivityName: string;
    currentAccount: IAccount;
    isVidazooOrganization?: boolean;
    userSelfDestruct?: boolean;
    deleteAuthAccount?: () => void;
    userId?: string;
    userAccounts: IAccount[];
}

export interface IAppHeaderState {
    mounted: boolean;
    viewAsIsOpen: boolean;
    mouseOnAppBar: boolean;
    contactUsModalOpen: boolean;
}

export default class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

    public static defaultProps: Partial<IAppHeaderProps> = {};

    constructor(props, context) {
        super(props, context);

        this.state = {mounted: false, viewAsIsOpen: false, mouseOnAppBar: false, contactUsModalOpen: false};
    }

    public componentDidMount() {
        window.requestAnimationFrame(() => {
            this.setState({mounted: true});
        });
    }

    public onLogoClick = () => {
        const {history, defaultActivityName} = this.props;
        history.push(appUrlsService.defaultDashboard(defaultActivityName));
    };

    private onLogout = () => {
        const {onSignOut} = this.props;

        onSignOut();
    };

    private goToHelp = () => {
        const isMilkshakeUser = this.props.isMilkshakeUser();
        if (isMilkshakeUser) {
            window.open((window as any).__HELP_URL_MILKSHAKE__);
        } else {
            window.open((window as any).__HELP_URL__);
        }
    };

    private goToReportingApiHelp = () => {
        window.open((window as any).__REPORTING_HELP__);
    };

    private goToFaq = () => {
        window.open((window as any).__FAQ_URL_MILKSHAKE__);
    };

    private getTermsLink = () => {
        const isMilkshakeUser = this.props.isMilkshakeUser();
        if (isMilkshakeUser) {
            return (window as any).__TERMS_URL_MILKSHAKE__;
        } else {
            return (window as any).__TERMS_URL__;
        }
    };

    private onViewAsModal = () => {
        this.setState({viewAsIsOpen: !this.state.viewAsIsOpen});
    };

    private setMouseOnAppBar = () => {
        this.setState({mouseOnAppBar: true});
    };

    private setMouseNotOnAppBar = () => {
        this.setState({mouseOnAppBar: false});
    };

    private onDeleteAuthAccount = async () => {
        const {userSelfDestruct, deleteAuthAccount, onSignOut} = this.props;
        if (userSelfDestruct) {
            await deleteAuthAccount();
            onSignOut();
        }
    };

    private toggleContactUsModal = () => {
        this.setState({contactUsModalOpen: !this.state.contactUsModalOpen});
    };

    public render(): JSX.Element {

        const {
            isMilkshakeAdmin,
            hidden,
            userName,
            userEmail,
            userAvatarUrl,
            userActivities,
            openMyProfile,
            currentAccount,
            isVidazooOrganization,
            userSelfDestruct,
            userId,
            userAccounts
        } = this.props;
        const isMilkshakeUser = this.props.isMilkshakeUser();
        const {mounted, viewAsIsOpen, mouseOnAppBar, contactUsModalOpen} = this.state;

        const classes = classnames(theme.header, {
            [theme.mounted]: mounted,
            [theme.hidden]: hidden
        });
        const isInStreamUser = userActivities.some((activity) => [ActivityType.MILKSHAKE_PREBID, ActivityType.IN_STREAM, ActivityType.MILKSHAKE_PRO].includes(activity.type));
        const isExposedLists = userActivities.some((activity) => activity.exposeLists);


        const USER_MENU_LINKS = [
            {label: "Terms & Conditions", native: true, linkProps: {href: this.getTermsLink(), target: "_blank"}},
            {
                label: "Privacy Policy",
                native: true,
                linkProps: {href: (window as any).__PRIVACY_URL__, target: "_blank"}
            }
        ];

        return (
            <AppBar onMouseLeave={this.setMouseNotOnAppBar} onMouseOver={this.setMouseOnAppBar} enableSticky
                    className={classes}>
                <div className={theme.inner}>
                    <div className="flex align-center">
                        <div className={theme.logo} onClick={this.onLogoClick}>
                            <VidazooLogo width={181} height={66} />
                        </div>
                        <MainMenu label="Dashboard" iconName="icon-dashboard">
                            {userActivities.map((activity) => <MenuItem
                                link={{to: appUrlsService.dashboard(activity.name)}} label={activity.name}
                                key={activity.name}/>)}
                        </MainMenu>
                        <MainMenu label="Reporting" iconName="icon-reporting">
                            {userActivities.map((activity) => <MenuItem
                                link={{to: appUrlsService.reporting(activity.name)}} label={activity.name}
                                key={activity.name}/>)}
                            <MenuItem link={{to: appUrlsService.scheduleReports()}} label="Schedule Reports"
                                      key="Schedule Reports"/>
                        </MainMenu>
                        {
                            (isInStreamUser || isMilkshakeAdmin() || isExposedLists || currentAccount.thirdPartyPublishers) ? (
                                <MainMenu label="Inventory" iconName="icon-widgets">
                                    {(isInStreamUser || isMilkshakeAdmin()) &&
                                        <>
                                            <MenuItem
                                                link={{to: appUrlsService.siteList()}} label="Site List"
                                                iconName="icon-network"/>
                                            <MenuItem link={{to: appUrlsService.widgets()}} label="Widgets"
                                                      iconName="icon-widgets"/>
                                        </>
                                    }
                                    {currentAccount.thirdPartyPublishers &&
                                        <>
                                            <MenuItem link={{to: appUrlsService.connections()}} label="Connections"
                                                      iconName="icon-demand-partners"/>
                                            <MenuItem link={{to: appUrlsService.domainsLists()}} iconName="icon-domain"
                                                      label="Domains Lists"/>
                                            <MenuItem link={{to: appUrlsService.countryLists()}} iconName="icon-country"
                                                      label="Country Lists"/>
                                        </>
                                    }
                                    {currentAccount.exposeVideos &&
                                        <MenuItem link={{to: appUrlsService.videos()}} label="Videos"
                                                  iconName="icon-videos"/>}
                                </MainMenu>
                            ) : null
                        }

                        {
                            currentAccount.exposeDemandPartners ? (
                                <MainMenu label="Demand" iconName="icon-demand-partners">
                                    <MenuItem link={{to: appUrlsService.demandPartners()}} label="Demand Partners"
                                              iconName="icon-demand-partners"/>
                                </MainMenu>
                            ) : null
                        }

                        <UserScope scope={[UserRole.MILKSHAKE_ADMIN]}>
                            <MainMenu link={{to: appUrlsService.testPages()}} label="Test Pages"
                                      iconName="icon-placements"/>
                        </UserScope>

                        <UserScope scope={[UserRole.ROOT_ADMIN, UserRole.MILKSHAKE_ADMIN]}>
                            <MainMenu label="Supervisor" iconName="icon-supervisor">
                                <UserScope scope={[UserRole.MILKSHAKE_ADMIN]}>
                                    <MenuItem link={{to: appUrlsService.accounts()}} label="Manage Accounts"/>
                                    <MenuItem link={{to: appUrlsService.milkshakeUsers()}} label="Milkshake Users"
                                              key={1}/>
                                    <MenuItem link={{to: appUrlsService.adminScheduleReportsAll()}}
                                              label="All Schedule Reports" key={2}/>
                                    <MenuItem link={{to: appUrlsService.adminLogsScheduleReports()}}
                                              label="Schedule Reports Logs" key={3}/>
                                    <MenuItem link={{to: appUrlsService.publishersReportingLogs()}}
                                              label="Reporting API Logs" key={4}/>
                                    <MenuItem link={{to: appUrlsService.updateMetrics()}} label="Update Metrics"
                                              key={5}/>
                                    <MenuItem link={{to: appUrlsService.updateDimensions()}} label="Update Dimensions"
                                              key={6}/>
                                    <MenuItem link={{to: appUrlsService.globalPresetsReports()}}
                                              label="Global Presets Reports"
                                              key={7}/>
                                    <MenuItem link={{to: appUrlsService.updatePointOfContact()}}
                                              label="Update Point of Contact"
                                              key={8}/>
                                </UserScope>
                            </MainMenu>
                        </UserScope>

                        <MainMenu label="Support" iconName="icon-help-support">
                            <MenuItem label="Help & Guides" onClick={this.goToHelp} key={0}/>
                            <MenuItem label="Reporting API" onClick={this.goToReportingApiHelp} key={1}/>
                            <MenuItem label="FAQ" onClick={this.goToFaq} key={2}/>
                            <MenuItem label="Contact Us" onClick={this.toggleContactUsModal} key={3}/>
                        </MainMenu>

                        {isVidazooOrganization ? (
                            <MainMenu label="Content Library" iconName="icon-videos"
                                      link={{to: appUrlsService.contentLibrary()}}/>
                        ) : null}
                        {/*{((currentAccount && currentAccount.allowContentLibrary) || isVidazooOrganization) ? (*/}
                        {/*    <MainMenu label="CMS" iconName="icon-dashboard">*/}
                        {/*        <MenuItem label="Content Library" link={{to: appUrlsService.contentLibrary()}}/>*/}
                        {/*        {userActivities.filter((activity) => !!activity.cmsAccount).map((activity) => <MenuItem*/}
                        {/*            link={{to: appUrlsService.cms(activity.name)}} label={activity.name}*/}
                        {/*            key={activity.name}/>)}*/}
                        {/*    </MainMenu>*/}
                        {/*) : null}*/}
                    </div>
                    <div className="flex align-center">
                        <ThemeSwitch className={theme["theme-switch"]}/>
                        {isVidazooOrganization ?
                            <NotificationPanel userId={userId} userNotificationsStore={userNotificationsStore}/> : null}
                        {(userAccounts && userAccounts.length > 1) && <AccountsList mouseOnAppBar={mouseOnAppBar}/>}
                        <UserMenu links={USER_MENU_LINKS} smallAvatar darkAvatar userName={userName}
                                  userEmail={userEmail} avatarUrl={userAvatarUrl}>
                            <MenuItem iconName="icon-my-account" label="My Profile" onClick={openMyProfile}/>
                            <MenuItem iconName="icon-help-support" label="Help & Support" onClick={this.goToHelp}/>
                            <MenuItem iconName="icon-reporting" label="Reporting API"
                                      onClick={this.goToReportingApiHelp}/>
                            <UserScope scope={[UserRole.SUPER_ADMIN, UserRole.ROOT_ADMIN]}>
                                <MenuItem iconName="icon-users" link={{to: appUrlsService.accounts()}}
                                          label="Manage Accounts"/>
                            </UserScope>
                            {isMilkshakeUser ?
                                <MenuItem iconName="icon-settings" link={{to: appUrlsService.accountManagement()}}
                                          label="Account Management"/>
                                : null}
                            <UserScope scope={[UserRole.VIEW_AS, UserRole.VIEW_AS_STRICT]}>
                                <MenuItem iconName="icon-supervisor" onClick={this.onViewAsModal} label="View As"/>
                            </UserScope>
                            {userSelfDestruct ?
                                <MenuItem iconName="icon-delete" label="Delete Account"
                                          onClick={this.onDeleteAuthAccount}/>
                                : null}
                            <MenuItem iconName="icon-sign-out" label="Sign Out" onClick={this.onLogout}/>
                        </UserMenu>
                    </div>
                </div>
                <Modal onClose={this.onViewAsModal} panel isOpen={viewAsIsOpen} title="View as"
                       scrollableContent={false} windowStyle={{minWidth: 450}}>
                    <ViewAsList/>
                </Modal>

                <Modal onClose={this.toggleContactUsModal} panel isOpen={contactUsModalOpen} title="Contact Our Support"
                       scrollableContent={false} windowStyle={{minWidth: 450}}>
                    <div className={theme["contact-us-container"]}>
                        <div className={theme["contact-us-content"]}>
                            <div className={theme["contact-us-content_text"]}>
                                <p>For any question or issue, please contact our support team at:</p>
                                <p><a href="mailto:pubops@vidazoo.com">pubops@vidazoo.com</a></p>
                            </div>
                        </div>
                    </div>
                </Modal>

            </AppBar>
        );
    }
}
