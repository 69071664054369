import ISite from "../../interfaces/ISite";
import {action, observable, transaction} from "mobx";
import {sitesAPI} from "../../api";
import {appUrlsService} from "../../services";
import {navigationStore} from "../index";
import {AccountOnboardingStatus, AdsTxtStatus, DomainApprovalStatus} from "../../common/enums";
import {currentUserStore, editAccountStore} from "stores";
import * as _ from "lodash";
import {BaseNotificationsStore, guid} from "@vidazoo/ui-framework";

export default class SubmitSiteStore {
    @observable public siteList: string;
    @observable public sites: { domain: string, type: string, id: string }[];
    @observable public selectedPublisher: string;
    @observable public item: ISite[];
    @observable public sitesType: string;

    constructor(private notificationsStore: BaseNotificationsStore) {
    }

    @action
    public reset() {
        transaction(() => {
            this.siteList = "";
            this.sites = [];
            this.selectedPublisher = "";
            this.item = [];
            this.sitesType = "O&O";
        });
    }

    @action
    public changeSiteList(value) {
        this.siteList = value;
        this.sites = _.compact(this.siteList.split("\n").map((domain) => ({domain, type: this.sitesType, id: guid()})));
    }

    @action
    public changeSite(index: number, key: string, value: string) {
        this.sites[index][key] = value;
    }

    @action
    public clearAllSites = () => {
        this.siteList = "";
        this.sites = [];
    };

    @action
    public addDomain = () => {
        this.sites.push({domain: "", type: this.sitesType, id: guid()});
    };

    @action
    public removeSite = (index: number) => {
        this.sites.splice(index, 1);
    };

    @action
    public changeSelectedPublisher(value) {
        this.selectedPublisher = value;
    }

    @action
    public submit(isOnBoarding: boolean = false): void {

        if (!this.sites.length) {
            this.notificationsStore.pushErrorNotification({
                title: "Hold on!",
                text: "Please make sure to add website list",
                timeout: 5000
            });
            return;
        }

        if (this.sites.length > 20 && !currentUserStore.isVidazooOrganization) {
            this.notificationsStore.pushErrorNotification({
                title: "Hold on!",
                text: "You can't create more than 20 sites at once",
                timeout: 5000
            });
            return;
        }

        sitesAPI.create(this.sites, this.selectedPublisher)
            .then((res) => this.onSubmitSuccess(isOnBoarding));
    }

    private onSubmitSuccess(isOnBoarding: boolean) {
        if (isOnBoarding) {
            editAccountStore.updateOnboardingStatus(AccountOnboardingStatus.DEMO_WIDGET, appUrlsService.onboardingDemoWidget());
        } else {
            navigationStore.push(appUrlsService.siteList());
        }
    }

    public onSkip() {
        navigationStore.push(appUrlsService.onboardingDemoWidget());
    }

    @action public changeSitesType = (value) => {
        this.sitesType = value;
    };

    @action public uploadCsv = (e) => {
        const files = e.target.files;

        if (!files || !files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsText(files[0], "UTF-8");

        reader.onloadend = (event: any) => {
            const csv = event.target.result.replace(/\r\n|\r/g, "\n");
            transaction(() => {
                this.addCsvDomain(csv);
            });
        };
    };

    @action private addCsvDomain = (csv) => {
        this.siteList = this.siteList + csv;
    };
}
