import * as moment from "moment";
import * as _ from "lodash";
import {observable, action, toJS, computed, transaction} from "mobx";
import IReportingActivity from "interfaces/reporting/IReportingActivity";
import IReportingActivityParams from "interfaces/reporting/IReportingActivityParams";
import IReportingActivityResults from "interfaces/reporting/IReportingActivityResults";
import IReportingActivityUI from "interfaces/reporting/IReportingActivityUI";
import {currentUserStore, ActivityContext, notificationsStore, sessionStore} from "stores";
import reportingFiltersManager from "./filters/reportingFiltersManager";
import {
    getCurrentHour,
    getCurrentWeek,
    getLastMonth,
    getCurrentMonth,
    getPastDays,
    guid,
    formatNumber,
    ValidTimeZones,
    ReportingTimePreset,
    Operators,
    FieldType, extractReportingFieldValue, IReportingPreset
} from "@vidazoo/ui-framework";
import {reportingService, storageService, exportToCsvService} from "services";
import {
    IDictionary,
    IReportingFilter,
    IReportingConstraint,
    MemoryClipboard,
    URLQueryParams
} from "@vidazoo/ui-framework";
import {filterReportingResults} from "@vidazoo/ui-framework/lib/utils";
import {Density, SortDirection} from "@vidazoo/ui";
import SessionStore from "../SessionStore";
import {globalPresetsReportsAPI, presetsReportsAPI, columnsWidthAPI} from "../../api";
import {IGlobalPresetReport} from "../../interfaces/presetReport/IGlobalPresetReport";
import {IPresetReport} from "../../interfaces/presetReport/IPresetReport";
import {filter} from "lodash";

const CUSTOM_DATE_KEYS = ["from", "to"];
const DATE_DIMENSIONS_FORMATS = {
    Month: "MM/YYYY",
    Day: "DD/MM/YYYY",
    Hour: "DD/MM/YYYY HH:mm",
};

export default class ReportingStore {
    @observable public activity: ActivityContext;
    @observable public error: Error;
    public activities: { [name: string]: IReportingActivity };
    @observable public globalPresetsReports: IGlobalPresetReport[];
    @observable public presetsReports: IPresetReport[];
    @observable public selectedPresetsReports: { _id: string, name: string };
    @observable public isGenerateReportDisabled: boolean;
    @observable public isLoadingPresetReports: boolean;
    @observable public promptDeletePreset: IReportingPreset;
    @observable public reportingSumOnTop: boolean;
    @observable public columnsWidthByEntity: { [index: number]: number };

    private setColumnsWidthDebounce: () => void;

    constructor() {
        this.activities = {};
        this.activity = null;
        this.error = null;
        this.globalPresetsReports = [];
        this.selectedPresetsReports = {name: "Custom", _id: "custom"};
    }

    @action
    public reset = () => {
        this.selectedPresetsReports = {name: "Custom", _id: "custom"};
        this.isLoadingPresetReports = false;
        this.presetsReports = observable([]);
        this.promptDeletePreset = null;
        this.columnsWidthByEntity = {};

        this.setColumnsWidthDebounce = _.debounce(() => {
            columnsWidthAPI.setColumnsWidth("reporting", this.columnsWidthByEntity);
        }, 1000);

    };

    @action public setActivity = (name: string, query?: string, reloadData?: boolean) => {

        this.activity = currentUserStore.getActivityContext(name);

        this.getPresetsReports();

        if (!this.activity || !this.activity.name || this.activities[name]) {
            return;
        }

        this.activities[name] = observable.object<IReportingActivity>({
            name,
            isLoading: false,
            isSearched: false,
            loadedOnce: false,
            results: this.initialActivityResults,
            params: this.initialActivityParams,
            reportingParams: {
                fields: this.activity.fields,
                groups: this.activity.groups,
                filters: this.activity.filters
            },
            searchQueries: {},
            verticalType: this.activity.verticalType,
            ui: this.initialActivityUI,
            id: this.activity.id
        });

        transaction(async () => {
            await this.setInitialActivityParams(this.activities[name], this.activity, query);

            this.applyTimePreset(this.activities[name].params.time);

            if (reloadData) {
                this.getReport();
            }
        });
    };

    @action
    private async setInitialActivityParams(activity: IReportingActivity, context: ActivityContext, query: string) {
        let setParamsFromQuery = false;

        if (query) {
            const queryParams = URLQueryParams.parse(query);

            setParamsFromQuery = _.some(await Promise.all([
                this.setReportEntriesFromQueryParams(activity, context, queryParams),
                this.setReportFiltersFromQueryString(activity, context, queryParams),
                this.setReportConstraintsFromQueryString(activity, context, queryParams),
                this.setReportTimeZoneFromQueryString(activity, context, queryParams),
                this.setReportTimeFrameFromQueryString(activity, context, queryParams),
            ]));
        }

        if (!setParamsFromQuery) {
            activity.params.fields = context.getPreselectedFields();
            activity.params.groups = context.getPreselectedGroups();
        }
    }

    @action
    private setReportTimeZoneFromQueryString(activity: IReportingActivity, context: ActivityContext, queryParams: any): boolean {
        let isSet = false;

        if (queryParams.tz && ValidTimeZones.indexOf(queryParams.tz) > -1) {
            isSet = true;
            activity.params.timezone = queryParams.tz;
        }

        return isSet;
    }

    @action
    private setReportTimeFrameFromQueryString(activity: IReportingActivity, context: ActivityContext, queryParams: any): boolean {
        let isSet = false;
        if (queryParams.t) {
            const validPresetValues = ReportingTimePreset.map((preset) => preset.value);

            if (validPresetValues.indexOf(queryParams.t) > -1) {
                isSet = true;

                activity.params.time = queryParams.t;

                if (queryParams.t === "custom" && queryParams.from && queryParams.to) {
                    const from = moment(queryParams.from, "X").utc(false);
                    const to = moment(queryParams.to, "X").utc(false);

                    if (from.isValid() && to.isValid() && from < to) {
                        this.setActivityParam("from", from);
                        this.setActivityParam("to", to);
                    } else {
                        activity.params.time = "now";
                    }
                }
            }
        }
        return isSet;
    }

    @action
    private setGlobalPresetsReports(res) {
        this.globalPresetsReports = res.data.results;
        const custom: any = {name: "Custom", _id: "custom"};
        this.globalPresetsReports.unshift(custom);
    }

    @action
    public getPresetsReports = () => {
        if (this.activity && this.activity.type) {
            this.isLoadingPresetReports = true;

            globalPresetsReportsAPI.getAll({filter: {activityType: this.activity.type, isActive: true}})
                .then((res) => this.setGlobalPresetsReports(res));

            presetsReportsAPI.getAll({filter: {activityType: this.activity.type, isActive: true}})
                .then((res) => this.setPresetsReports(res));
        }
    };

    @action
    private setPresetsReports(res) {
        this.isLoadingPresetReports = false;
        this.presetsReports = res.data.results;
    }

    @action public createPresetReport = (params: any) => {
        presetsReportsAPI.create({name: params.name, ...this.getPresetReportParams()})
            .then((res) => this.onCreatePresetSuccess(res))
            .catch(() => this.onCreatePresetError());
    };

    @action
    private onCreatePresetSuccess(res) {
        (this.presetsReports as any) = this.presetsReports.concat(res.data);

        notificationsStore.pushSuccessNotification({
            title: "Operation Complete",
            text: "Preset report created successfully",
            timeout: 5000
        });
    }

    @action
    private onCreatePresetError() {
        notificationsStore.pushErrorNotification({
            title: "Operation Error",
            text: "Failed to create preset report",
            timeout: 5000
        });
    }

    @action public savePresetReport = (preset: IReportingPreset) => {
        presetsReportsAPI.update(preset._id, this.getPresetReportParams())
            .then((res) => this.onUpdatePresetSuccess(res))
            .catch(() => this.onUpdatePresetError());
    };

    private getPresetReportParams() {
        return {
            timespan: this.params.time,
            timezone: this.params.timezone,
            groups: this.params.groups,
            fields: this.params.fields,
            constraints: this.params.constraints,
            filters: this.params.filters,
            activityType: this.activity.type
        };
    }

    @action
    private onUpdatePresetSuccess(res) {
        notificationsStore.pushSuccessNotification({
            title: "Operation Complete",
            text: "Preset report updated successfully",
            timeout: 5000
        });
    }

    @action
    private onUpdatePresetError() {
        notificationsStore.pushErrorNotification({
            title: "Operation Error",
            text: "Failed to update preset report",
            timeout: 5000
        });
    }

    @action
    private changePresetReport = (presetReport: { _id: string, name: string }) => {
        this.selectedPresetsReports = presetReport;
        if (presetReport._id === "custom") {
            this.resetPresetReportParams();
        } else {
            this.setPresetReportParams();
            this.getReport();
        }
    };
    @action public applyReportPreset = (preset: IReportingPreset) => {
        this.setActivityParam("fields", preset.fields);
        this.setActivityParam("groups", preset.groups);
        this.setActivityParam("filters", preset.filters);
        this.setActivityParam("constraints", preset.constraints);
    };

    @action
    private setPresetReportParams = () => {
        let presetReport = this.globalPresetsReports.find((row) => row._id === this.selectedPresetsReports._id);
        if (!presetReport) {
            presetReport = this.presetsReports.find((row) => row._id === this.selectedPresetsReports._id);
        }
        this.setActivityParam("fields", presetReport.fields);
        this.setActivityParam("groups", presetReport.groups);
        this.setActivityParam("filters", this.prepareFilters(presetReport.filters));
        this.setActivityParam("constraints", presetReport.constraints);
    };

    @action
    private prepareFilters(filters: IReportingFilter[]): IReportingFilter[] {
        console.log(toJS(this.currentActivity.reportingParams.filters));
        return filters.map((filter) => {
            if (!filter.filterValueKey) {
                const filterHandler = reportingFiltersManager.getFilter(filter.key, this.activity.id);
                if (filterHandler) {
                    filter.filterValueKey = filterHandler.valueKey;
                    filter.filterLabelKey = filterHandler.labelKey;
                    filter.allowNew = filterHandler.allowNew;
                    filter.isLoading = filterHandler.isLoading;
                }

                filterHandler.initialize(this.activity).then(action(() => {
                    filter.filterList = observable.array(filterHandler.items);
                    filter.isLoading = filterHandler.isLoading;
                }));
            }
            return filter;
        });
    }

    @action
    private resetPresetReportParams = () => {
        this.setActivityParam("fields", []);
        this.setActivityParam("groups", []);
        this.setActivityParam("filters", []);
        this.setActivityParam("constraints", []);
    };

    @action
    private setReportConstraintsFromQueryString(activity: IReportingActivity, context: ActivityContext, queryParams: any): boolean {
        let isSet = false;

        if (queryParams.c && queryParams.c.length > 0) {
            _.forEach(queryParams.c, (constraint) => {

                if (constraint.name && constraint.op && Operators.indexOf(constraint.op) > -1 && !isNaN(constraint.value)) {

                    const entry = context.getFieldByLabel(constraint.name);

                    if (entry) {
                        isSet = true;

                        this.addConstraint({
                            name: entry.value,
                            op: constraint.op,
                            value: constraint.value
                        });
                    }
                }
            });
        }

        return isSet;
    }

    @action
    private async setReportFiltersFromQueryString(activity: IReportingActivity, context: ActivityContext, queryParams: any): Promise<boolean> {
        let isSet = false;

        if (queryParams.f && queryParams.f.length > 0) {

            isSet = _.some(await Promise.all(queryParams.f.map(async (filter) => {

                let isSetFilter = false;
                if (filter.key && filter.values && filter.values.length > 0) {
                    const entry = context.getFilterByLabel(filter.key);

                    if (entry) {
                        const newFilter = this.addFilter();

                        await this.setFilterKey(newFilter, entry.value);

                        const stringList = newFilter.allowNew;

                        _.forEach(filter.values, (label) => {
                            const filterValue = !stringList
                                ? this.getFilterListValueByLabel(newFilter, label)
                                : typeof (label) === "string"
                                    ? label
                                    : null;

                            if (filterValue) {
                                isSetFilter = true;

                                this.pushFilterValue(newFilter, filterValue, label);
                            }
                        });
                    }
                }

                return isSetFilter;
            })));
        }

        return isSet;
    }

    @action
    private setReportEntriesFromQueryParams(activity: IReportingActivity, context: ActivityContext, queryParams: any): boolean {
        let isSet = false;

        if (queryParams.fs && queryParams.fs.length > 0) {
            _.forEach<string>(queryParams.fs, (label) => {
                const field = context.getFieldByLabel(label);

                if (field) {
                    isSet = true;
                    this.pushActivityReportParam("fields", field.value, field.label);
                }
            });
        }

        if (queryParams.gs && queryParams.gs.length > 0) {
            _.forEach<string>(queryParams.gs, (label) => {
                const group = context.getGroupByLabel(label);

                if (group) {
                    isSet = true;

                    this.pushActivityReportParam("groups", group.value, group.label);
                }
            });
        }

        return isSet;
    }

    private getFilterListValueByLabel(filter: IReportingFilter, label: string): string {
        if (filter.filterList && filter.filterList.length > 0) {
            const entry = _.find(filter.filterList, (item) => item[filter.filterLabelKey] === label);

            if (entry) {
                return entry[filter.filterValueKey];
            }
        }

        return "";
    }

    @action public getReport = (resetLoad: boolean = false) => {
        if (!this.canGetReport) {
            return;
        }

        const activity = this.activity;
        const reportingActivity = this.activities[this.activity.name];

        transaction(() => {
            this.error = null;
            this.isLoading = true;
            this.ui.page = 1;
            this.isGenerateReportDisabled = true;

            if (resetLoad) {
                this.loadedOnce = false;
            }
        });

        this.setGenerateReportStatus();

        storageService.setGlobalTimezone(reportingActivity.params.timezone);
        activity.setPersistentGroups(reportingActivity.params.groups);
        activity.setPersistentFields(reportingActivity.params.fields);

        reportingService.getReport(toJS(this.params), this.activity.verticalType, this.activity.id)
            .then((data) => reportingService.buildResults(reportingActivity.params.groups, reportingActivity.params.fields, data, activity))
            .then((results) => this.setResults(results, reportingActivity))
            .catch((e) => this.setError(e, reportingActivity));
    };

    @action
    private setResults(results: IReportingActivityResults, reportingActivity: IReportingActivity) {
        transaction(() => {

            reportingActivity.isSearched = true;
            reportingActivity.isLoading = false;
            reportingActivity.results = results;

            const searchQueries: IDictionary<string> = {};

            _.forEach(results.groups, (item) => {
                if (item.name) {
                    searchQueries[item.name] = reportingActivity.searchQueries[item.name] || "";
                }
            });

            reportingActivity.searchQueries = searchQueries;

            if (reportingActivity.results.result.length > 0) {
                reportingActivity.ui.collapsed = true;
            } else {
                notificationsStore.pushNotification({
                    title: "Oops!",
                    text: "We couldn't find any results",
                    error: true,
                    timeout: 5000
                });
            }

            if (this.hasActiveSort()) {
                this.sort(reportingActivity.ui.sortBy, false);
            } else {

                const firstFieldLabel = this.params.fields[0] && this.params.fields[0].label;

                if (firstFieldLabel) {
                    reportingActivity.ui.sortDirection = SortDirection.DESC;
                    this.sort(firstFieldLabel);
                }
            }
        });
    }

    @action
    private setError(e: Error, reportingActivity: IReportingActivity) {
        transaction(() => {
            reportingActivity.isLoading = false;
            reportingActivity.results = this.initialActivityResults;
            this.error = e;
        });
    }

    @action public resetDimensions = () => {
        transaction(() => {
            this.setActivityParam("groups", []);
            this.setActivityParam("filters", []);
        });
    };

    @action public resetMetrics = () => {
        transaction(() => {
            this.setActivityParam("fields", []);
            this.setActivityParam("constraints", []);
        });
    };

    @action public applyTimePreset = (preset?: string) => {
        if (preset && preset !== this.params.time) {
            this.params.time = preset;
        }

        switch (this.params.time) {
            case "now":
                this.setDate(1, 1, true);
                break;
            case "today":
                this.setDate(getCurrentHour(), -getCurrentHour() + 24);
                break;
            case "yesterday":
                this.setDate(getCurrentHour() + 24, -getCurrentHour());
                break;
            case "last7days":
                this.setDate(getPastDays(7), -getCurrentHour() + 24);
                break;
            case "weektodate":
                this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
                break;
            case "lastmonth":
                this.setDate(getLastMonth(), -getCurrentMonth());
                break;
            case "monthtodate":
                this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
                break;
        }
    };

    @action
    public setDate(from: number, to: number, withTimezone?: boolean) {
        if (withTimezone) {
            const offset = this.getTimezoneOffset() / 60;

            from += offset;
            to -= offset;
        }

        transaction(() => {
            this.params.from = moment().utc().minutes(0).second(0).subtract(from, "hour");
            this.params.to = moment().utc().minutes(0).second(0).add(to, "hour");
        });
    }

    @action public setActivityParam = (key: string, value: any) => {
        if (CUSTOM_DATE_KEYS.indexOf(key) > -1) {
            this.applyTimePreset("custom");
        }

        this.params[key] = value;

        if (key === "timezone" && this.params.time === "now") {
            this.applyTimePreset();
        }
    };

    @action public pushActivityReportParam = (key: string, value: string, label: string) => {
        this.params[key] = this.params[key].concat({value, label});
    };

    @action public removeActivityReportParam = (key: string, value: string) => {
        this.params[key] = this.params[key].filter((param) => param.value !== value);
    };

    @action public resetActivityParams = () => {
        this.params = this.initialActivityParams;

        this.applyTimePreset(this.currentActivity.params.time);
    };

    @action public setActivityUI = (key: string, value: any) => {
        this.ui[key] = value;
    };

    @action public addConstraint = (values?: Partial<IReportingConstraint>): IReportingConstraint => {
        this.params.constraints = this.params.constraints.concat({
            id: guid(),
            name: "",
            op: "",
            value: "",
            ...values
        });

        return this.params.constraints[this.params.constraints.length - 1];
    };

    @action public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
        constraint[key] = value;
    };

    @action public removeConstraint = (constraint: IReportingConstraint) => {
        this.params.constraints = _.filter<IReportingConstraint>(this.params.constraints, (c) => c.id !== constraint.id);
    };

    @action public addFilter = (): IReportingFilter => {
        const filter = observable<IReportingFilter>({
            id: guid(),
            key: "",
            values: [],
            filterList: [],
            isLoading: false,
            filterValueKey: "",
            allowNew: false,
            operator: "isAnyOf",
        });

        this.params.filters.push(filter);

        return filter;
    };

    @action public setFilterKey = (filter: IReportingFilter, key: string) => {
        const filterHandler = reportingFiltersManager.getFilter(key, this.activity.id);

        filter.key = key;
        filter.filterLabelKey = filterHandler.labelKey;
        filter.filterValueKey = filterHandler.valueKey;
        filter.allowNew = filterHandler.allowNew;
        filter.isLoading = filterHandler.isLoading;
        filter.values = [];

        return filterHandler.initialize(this.activity).then(action(() => {
            filter.filterList = observable.array(filterHandler.items);
            filter.isLoading = filterHandler.isLoading;
        }));
    };

    @action public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
        if (key === "key") {
            this.setFilterKey(filter, value);
            return;
        }

        filter[key] = value;
    };

    @action public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {

        let item: any = value;

        if (filter.filterLabelKey && filter.filterValueKey) {
            item = {
                [filter.filterLabelKey]: label,
                [filter.filterValueKey]: value
            };
        }

        filter.values = filter.values.concat(item);
    };

    @action public removeFilterValue = (filter: IReportingFilter, value: string) => {
        filter.values = filter.filterValueKey
            ? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
            : _.filter(filter.values, (item) => item !== value);
    };

    @action public removeFilter = (filter: IReportingFilter) => {
        this.params.filters = _.filter<IReportingFilter>(this.params.filters, (f) => f.id !== filter.id);
    };

    @action public setSearchQuery = (key, query) => {
        transaction(() => {
            this.setPage(1);
            this.searchQueries[key] = query;
        });
    };

    @action
    public setPage(page) {
        this.ui.page = page;
    }

    @action
    public resetSort(sortDirection?: SortDirection, sortBy?: string) {
        transaction(() => {
            this.ui.sortDirection = sortDirection || SortDirection.ASC;
            this.ui.sortBy = sortBy || "";
        });
    }

    @action public loadMore = () => {
        if (this.filteredResults.length > this.ui.page * this.ui.pageSize) {
            this.setPage(this.ui.page + 1);
        }
    };

    @computed get paginatedResults() {
        if (!this.activity) {
            return [];
        }

        return _(this.filteredResults).take(this.ui.page * this.ui.pageSize).value();
    }

    @computed get filteredResults() {
        if (!this.activity) {
            return [];
        }

        return filterReportingResults(this.results.result, this.searchQueries);
    }

    @computed get paramsFilteredResults(): any {
        const paramsResults = this.paramsResults;
        return filterReportingResults(paramsResults.result, this.searchQueries);
    }

    @computed
    private get paramsHeaders() {
        if (!this.results || !this.results.groupsByName || !this.results.fieldsByName) {
            return [];
        }

        const headerByParams = [];
        this.params.groups.forEach((group) => {
            let groupFromRes = this.results.groupsByName[group.label];
            if (groupFromRes) {
                groupFromRes = JSON.parse(JSON.stringify(groupFromRes));
                groupFromRes.isGroup = true;
                headerByParams.push(groupFromRes);
            }
        });
        this.params.fields.forEach((field) => {
            if (this.results.fieldsByName[field.label]) {
                headerByParams.push(this.results.fieldsByName[field.label]);
            }
        });
        return headerByParams;
    }

    @computed
    private get paramsResults() {
        const results: any = this.initialActivityResults;
        if (this.results && this.results.groupsByName) {
            const resultsGroups = [];
            this.params.groups.forEach((group) => {
                if (this.results.groupsByName[group.label]) {
                    const groupFromRes = JSON.parse(JSON.stringify(group));
                    groupFromRes.isGroup = true;
                    groupFromRes.name = groupFromRes.label;
                    resultsGroups.push(groupFromRes);
                }
            });

            results.groups = resultsGroups;
            results.result = this.results.result.map((row) => {
                const fields = [], groups = [];
                this.params.fields.forEach((field) => {
                    if (this.results.fieldsByName[field.label]) {
                        const fieldRes = row.fields.find((rowField) => rowField.name === field.label);
                        fields.push(fieldRes);
                    }
                });
                this.params.groups.forEach((group) => {
                    if (this.results.groupsByName[group.label]) {
                        const groupRes = row.groups.find((rowGroup) => rowGroup.name === group.label);
                        groups.push(groupRes);
                    }
                });

                return {
                    ...row,
                    fields,
                    groups
                };
            });
        }
        return {
            ...this.results,
            ...results
        };
    }

    @computed get filteredTotalRequested(): any[] {
        const totalFields = {};
        const totalFieldsValues = {};
        const totalResults = this.filteredResults.length;

        this.filteredResults.forEach((item) => {
            item.fields.forEach((field) => {
                const fieldFromContext = this.activity.getFieldByLabel(field.name) || reportingService.getFieldByLabel(this.activity.verticalType, field.name);
                const fieldKey = fieldFromContext ? fieldFromContext.value : field.name;

                if (!totalFields[field.name]) {

                    totalFields[field.name] = {
                        name: fieldFromContext ? fieldFromContext.label : field.name,
                        type: fieldFromContext ? fieldFromContext.type : FieldType.SUMABLE,
                        value: 0,
                        isRequested: field.isRequested
                    };

                    totalFieldsValues[fieldKey] = 0;
                }

                const originalValue = extractReportingFieldValue(field, "value");

                totalFields[field.name].value += originalValue;
                // this is needed because calculated fields" formula expect a simple dictionary (like {adStart: 3, adLoad: 4}
                totalFieldsValues[fieldKey] += originalValue;
            });
        });

        _.forEach(totalFields, (field: any) => {
            const fieldFromContext = this.activity.getFieldByLabel(field.name) || reportingService.getFieldByLabel(this.activity.verticalType, field.name);

            if (!fieldFromContext) {
                return;
            }

            const format = fieldFromContext.format || ((v) => formatNumber(v, 2));
            const formula = fieldFromContext.formula;

            switch (field.type) {
                case FieldType.SUMABLE:
                    field.typeSup = "sum";
                    break;
                case FieldType.AVERAGABLE:
                    field.typeSup = "avg";
                    field.value = field.value / totalResults;
                    break;
                case FieldType.CALCULATED:
                    field.typeSup = "clc";
                    field.value = formula(totalFieldsValues);
                    break;
            }

            field.displayValue = format(field.value);
            field.typeSup && (field.displayValue = `${field.typeSup}: ${field.displayValue}`);
        });

        if (!this.results || !this.results.fieldsByName) {
            return [];
        }
        const filteredTotalFields = [];
        this.params.fields.forEach((field) => {
            if (this.results.fieldsByName[field.label] && totalFields[field.label] && totalFields[field.label].isRequested) {
                filteredTotalFields.push(totalFields[field.label]);
            }
        });

        return _.values(filteredTotalFields);
    }

    @computed get filteredTotal() {
        const totalFields: any = {};
        const totalFieldsValues = {};

        this.filteredResults.forEach((item) => {
            item.fields.forEach((field) => {
                const fieldFromContext = this.activity.getFieldByLabel(field.name) || reportingService.getFieldByLabel(this.activity.verticalType, field.name);

                if (!totalFields[field.name]) {
                    totalFields[field.name] = {
                        name: fieldFromContext.label,
                        value: 0,
                        type: fieldFromContext.type,
                        formula: fieldFromContext.formula,
                        format: fieldFromContext.format || ((v) => formatNumber(v, 2))
                    };

                    totalFieldsValues[fieldFromContext.value] = 0;
                }

                totalFields[field.name].value += field.value;
                // this is needed because calculated fields' formula expect a simple dictionary (like {adStart: 3, adLoad: 4}
                totalFieldsValues[fieldFromContext.value] += field.value;
            });
        });

        _.forEach(totalFields, (field) => {
            switch (field.type) {
                case FieldType.SUMABLE:
                    field.typeSup = "sum";
                    break;
                case FieldType.AVERAGABLE:
                    field.typeSup = "avg";
                    field.value = field.value / this.filteredResults.length;
                    break;
                case FieldType.CALCULATED:
                    field.typeSup = "clc";
                    field.value = field.formula(totalFieldsValues);
                    break;
            }

            field.displayValue = field.format(field.value);
        });

        return _.values(totalFields);
    }

    @computed
    private get canGetReport(): boolean {
        return (this.params.fields.length > 0 || this.params.groups.length > 0) && (this.activity && !!this.activity.name);
    }

    @computed
    public get sortDir(): SortDirection {
        return this.ui.sortDirection;
    }

    @action public sort = (sortBy: string, withReverseDir: boolean = true) => {
        transaction(() => {
            this.setPage(1);

            if (sortBy === this.ui.sortBy && withReverseDir) {
                this.ui.sortDirection = this.ui.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
            }
            this.ui.sortBy = sortBy;

            const dateFormat = DATE_DIMENSIONS_FORMATS[this.ui.sortBy];

            const getValue = dateFormat
                ? (item: any) => moment(item.indexed[this.ui.sortBy].value, dateFormat)
                : (item: any) => item.indexed[this.ui.sortBy].value;

            this.results.result.replace(_.orderBy(this.results.result, (item) => getValue(item), this.ui.sortDirection.toString() as any));
        });
    };

    private hasActiveSort() {
        if (!this.ui.sortBy) {
            return false;
        }

        if (this.results.result && this.results.result.length > 0) {
            return typeof this.results.result[0].indexed[this.ui.sortBy] !== "undefined";
        }

        return false;
    }

    public generateReportLink = () => {
        const params = URLQueryParams.stringify({
            t: this.params.time,
            ids: this.params.publisherIds,
            tz: this.params.timezone,
            from: this.params.from.unix(),
            to: this.params.to.unix(),
            fs: this.params.fields.map((x) => x.label),
            gs: this.params.groups.map((x) => x.label),
            f: this.flattenFiltersForQuery(this.params.filters),
            c: this.flattenConstraintsForQuery(this.params.constraints)
        });

        MemoryClipboard.copy(`${window.location.origin}/reporting/${this.activity.name}?${params}`);

        notificationsStore.pushNotification({
            title: "Report Link",
            text: "Copied to your clipboard",
            success: true,
            timeout: 5000
        });
    };

    private flattenFiltersForQuery(filters: IReportingFilter[]): any {
        return _(filters).map((filter) => {
            if (filter.key && filter.values.length) {
                const key = this.activity.getGroupByValue(filter.key);

                if (key) {
                    return {
                        key: this.activity.getGroupByValue(filter.key).label,
                        values: _.map<any, any>(filter.values, (value) => value[filter.filterLabelKey] || value)
                    };
                }
            }
        }).compact().value();
    }

    private flattenConstraintsForQuery(constraints: IReportingConstraint[]): any {
        return _(constraints).map((constraint) => {

            if (constraint.name && constraint.op && constraint.value) {
                const key = this.activity.getFieldByValue(constraint.name);

                if (key) {
                    return {
                        name: this.activity.getFieldByValue(constraint.name).label,
                        op: constraint.op,
                        value: constraint.value
                    };
                }
            }
        }).compact().value();
    }

    public downloadCSV = () => {
        notificationsStore.pushSuccessNotification({
            title: "Generating CSV",
            text: "Please wait...",
            timeout: 5000
        });

        exportToCsvService.exportFilteredReport(this.paramsFilteredResults, this.paramsHeaders);

        // exportToCsvService.exportReportCsv(this.params.groups, this.params.fields, this.results, this.activity);
    };

    private getTimezoneOffset(): number {
        return (moment.tz.zone(this.params.timezone) as any).utcOffset(moment().utc().minutes(0).second(0));
    }

    public get currentActivity(): IReportingActivity {
        return this.activity
            ? this.activities[this.activity.name]
            : null;
    }

    public get results(): IReportingActivityResults {
        return this.currentActivity ? this.currentActivity.results : null;
    }

    public get searchQueries(): IDictionary<string> {
        return this.currentActivity ? this.currentActivity.searchQueries : null;
    }

    public get isLoading(): boolean {
        return this.currentActivity.isLoading;
    }

    public get loadedOnce(): boolean {
        return this.currentActivity.loadedOnce;
    }

    public set isLoading(value: boolean) {
        this.currentActivity.isLoading = value;
    }

    public set loadedOnce(value: boolean) {
        this.currentActivity.loadedOnce = value;
    }

    public set params(params: IReportingActivityParams) {
        this.currentActivity.params = params;
    }

    public get params(): IReportingActivityParams {
        return this.currentActivity.params || {};
    }

    public get ui(): IReportingActivityUI {
        return this.currentActivity.ui || {};
    }

    private get initialActivityResults(): IReportingActivityResults {
        return {
            result: observable([]),
            fields: [],
            groups: [],
            totalResults: 0,
            totalFields: {}
        };
    }

    private get initialActivityParams(): IReportingActivityParams {
        return {
            time: "today",
            publisherIds: this.activity.publisherIds,
            from: null,
            to: null,
            fields: [],
            groups: [],
            filters: [],
            constraints: [],
            timezone: storageService.getGlobalTimezone() || "Etc/GMT+0",
        };
    }

    private get initialActivityUI(): IReportingActivityUI {
        return {
            density: Density.Medium,
            collapsed: false,
            page: 1,
            pageSize: 50,
            sortDirection: SortDirection.ASC,
            sortBy: ""
        };
    }

    public quickFilter = (item) => {
        const {originalId, name} = item;
        const dimension = this.activity.getGroupByLabel(name);
        let filter = this.params.filters.find((currFilter) => currFilter.key === dimension.value);
        if (!filter) {
            filter = this.addFilter();
            this.setFilterParam(filter, "key", dimension.value);
            this.setFilterParam(filter, "operator", dimension.operators[0]);
        }
        const value = originalId || item.value;
        if (filter.values.some((val) => val === value || val[filter.filterValueKey] === value)) {
            return;
        }

        this.pushFilterValue(filter, value, item.value);
    };

    public quickConstraint = (item) => {
        this.addConstraint({
            name: this.activity.getFieldByLabel(item.name).value,
            op: ">=",
            value: extractReportingFieldValue(item, "value")
        });
    };

    @action private setGenerateReportStatus = () => {
        let secondsLeft = 10;
        const disabledTimer = setInterval(action(() => {
            if (!this.isLoading || secondsLeft <= 0) {
                clearInterval(disabledTimer);
                return this.isGenerateReportDisabled = false;
            }
            this.isGenerateReportDisabled = true;
            secondsLeft -= 1;
        }), 1000);
    };

    public getFiltersWithLabels = () => {
        const results = [];
        const {filters} = this.currentActivity.params;

        for (let i = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            const values = _.compact(filter.values);

            if (values.length) {
                results.push({
                    key: filter.key ? this.activity.getFilterByValue(filter.key).label : "",
                    values: _.map(filter.values, (value: any) => _.isObject(value) ? value[filter.filterValueKey] : value),
                    operator: filter.operator
                });
            }
        }

        return results;

    };

    public getConstraintsWithLabels = () => {
        const results = [];
        const {constraints} = this.currentActivity.params;

        constraints.forEach((constraint) => {
            const {name, op, value} = constraint;
            results.push({
                key: name ? this.activity.getFieldByValue(name).label : "",
                op,
                value
            });
        });

        return results;
    };

    @action protected deletePresetReport = (id: string) => {
        presetsReportsAPI.delete(id)
            .then(() => this.onDeletePresetReportSuccess(id))
            .catch(() => this.onDeletePresetReportError());
    };
    @action public setPromptDeletePresetReport = (item) => {
        this.promptDeletePreset = item;
    };

    @action
    protected onDeletePresetReportSuccess(id: string) {
        this.presetsReports = this.presetsReports.filter((item) => item._id !== id);
        this.promptDeletePreset = null;

        notificationsStore.pushSuccessNotification({
            title: "Operation Complete",
            text: `Delete successfully`,
            timeout: 5000
        });
    }

    @action
    protected onDeletePresetReportError() {
        this.promptDeletePreset = null;

        notificationsStore.pushErrorNotification({
            title: "Operation Error",
            text: `Failed to delete`,
            timeout: 5000
        });
    }

    @action
    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {

        const {groups, fields} = this.results.result[0];
        const headers = [...groups, ...fields.filter((field) => field.isRequested)];

        for (const index in columnsWidth) {

            if (headers[index]) {
                const {name} = headers[index];
                this.columnsWidthByEntity[name] = columnsWidth[index];
            }
        }

        this.setColumnsWidthDebounce();
    };

    @action
    public async initializeColumnsWidth() {
        const columnsWidthByEntity = await columnsWidthAPI.getColumnsWidth("reporting");
        this.setColumnsWidthByEntity(columnsWidthByEntity.data);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        if (this.results && this.results.result.length > 0) {
            const {groups, fields} = this.results.result[0];
            const headers = [...groups, ...fields.filter((field) => field.isRequested)];

            const columnsWidth = {};
            headers.forEach((row, idx) => {
                const rowKey = row.name.replace(/\./g, "");
                columnsWidth[idx] = this.columnsWidthByEntity[rowKey] || 100;
            });
            return columnsWidth;
        }
        return this.columnsWidthByEntity;
    };

    @action
    private setColumnsWidthByEntity(columnsWidthByEntity) {
        this.columnsWidthByEntity = columnsWidthByEntity;
    }

    @action
    public toggleReportingSumOnTop = () => {
        this.reportingSumOnTop = !this.reportingSumOnTop;
        storageService.setReportingSumOnTop(this.reportingSumOnTop);
    };

    @action
    public initAfterAllInitialize() {
        this.reportingSumOnTop = storageService.getReportingSumOnTop();
    }

    @action
    public setTotalRows = (header, totalResults) => {
        header.displayValue = `Total Rows: ${totalResults}`;
    };
}
