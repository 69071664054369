import {AccountsList} from "@vidazoo/ui-framework";
import { inject, observer } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    accounts: stores.sessionStore.userAccounts,
    selectedAccounts: stores.sessionStore.selectedAccounts,
    setSelectedAccounts: stores.sessionStore.setSelectedAccounts,
    indexAccounts: stores.sessionStore.indexAccounts,
    isMultiSelectAllow: false
}))(observer(AccountsList));
