import {BaseAPI} from "@vidazoo/ui-framework";

class ConnectionsAPI extends BaseAPI {

    public getAll() {
        return this.request({
            method: "get",
            url: "/api/connections/",
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/connections/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/connections/${id}/`,
            data
        });
    }

    public adminConnections(id: string) {
        return this.request({
            method: "get",
            url: `/api/connections/admin/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: `/api/connections/`,
            data
        });
    }

    public createMulti(data: any) {
        return this.request({
            method: "post",
            url: `/api/connections/create_multi`,
            data
        });
    }
}

export default new ConnectionsAPI((window as any).__BASE_API_URL__, true);
