import {VerticalType} from "common/enums";
import {IReportingEntry, BaseStorageService} from "@vidazoo/ui-framework";
import * as _ from "lodash";

class StorageService extends BaseStorageService {

    public getReportingVerticalActivityEntries = (vertical: string, activityType: string, entry: "groups" | "fields"): IReportingEntry[] => {
        return this.get(`reporting.${vertical}.${activityType}.${entry}`, null);
    };

    public setReportingVerticalActivityEntries = (vertical: string, activityType: string, entry: "groups" | "fields", values: IReportingEntry[]) => {
        this.set(`reporting.${vertical}.${activityType}.${entry}`, values);
    };

    public getGlobalTimezone = (): string => {
        return this.get(`global.timezone`, "");
    };

    public setGlobalTimezone = (timezone: string) => {
        this.set(`global.timezone`, timezone);
    };

    public getDefaultActivityName = (accountId: string): string => {
        const path = `defaultActivities.${accountId}`;
        return this.get(path, "");
    };

    public setDefaultActivity = (name: string, accountId: string) => {
        this.set(`defaultActivities.${accountId}`, name);
    };

    public setColumnsWidth = (entity: string, columnsWidth) => {
        const allColumnsWidth = this.get("columnsWidth", {});
        allColumnsWidth[entity] = columnsWidth;
        this.set(`columnsWidth`, allColumnsWidth);
    };

    public getColumnsWidth = (entity: string) => {
        const allColumnsWidth = this.get("columnsWidth", {});
        return allColumnsWidth[entity];
    };

    public setDashboardData = (dashboardData) => {
        this.set(`dashboardData`, dashboardData);
    };

    public getDashboardData = () => {
        return this.get("dashboardData", {});
    };

    public getReportingSumOnTop = () => {
        return this.get(`reportingSumOnTop`, true);
    };

    public setReportingSumOnTop = (reportingSumOnTop: boolean) => {
        this.set(`reportingSumOnTop`, reportingSumOnTop);
    };
}

export default new StorageService();
