import { observable, action } from "mobx";

export default class UIStore {

    @observable public hiddenAppHeader: boolean;

    constructor() {
        this.hiddenAppHeader = false;
        this.setAppHeaderHiddenState = this.setAppHeaderHiddenState.bind(this);
    }

    @action public setAppHeaderHiddenState(isHidden: boolean) {
        this.hiddenAppHeader = isHidden;
    }
}
