import * as React from "react";
import { PageMessage } from "@vidazoo/ui";

export default () => (
    <div className="spread flex align-center justify-center">
        <PageMessage
            iconName="icon-rejected-status"
            title="Opps... It looks like you're lost"
            description="You don't have permissions to view this section"
        />
    </div>
);
