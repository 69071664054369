import {BaseSocketService} from "@vidazoo/ui-framework";
import {sessionStore} from "stores";

class SocketService extends BaseSocketService {

    protected onSocketError(err) {
        if (err === "unauthorized") {
            window.__sdk__.auth.logout();
        }
    }

    public getReport(data) {
        return this.request("getReport", {...data, selectedAccount: sessionStore.selectedAccounts[0]}, 15 * 60 * 1000);
    }

    public getReportMulti(data) {
        return this.request("getReportMulti", data);
    }

    public getCombinedReport(data) {
        return this.request("getCombinedReport", {...data, selectedAccount: sessionStore.selectedAccounts[0]});
    }

    public connectToSiteListRoom() {
        return this.request("connectToSiteListRoom", {selectedAccount: sessionStore.selectedAccounts[0]});
    }

    public disconnectToSiteListRoom() {
        return this.request("disconnectToSiteListRoom", {selectedAccount: sessionStore.selectedAccounts[0]});
    }

    public startListenToSiteListRoomStatusUpdate(fn: (data: any) => void) {
        this.socket.on(`status_update_${sessionStore.selectedAccounts[0]}`, fn);
    }

    public stopListenToSiteListRoomStatusUpdate(fn: (data: any) => void) {
        this.socket.off(`status_update_${sessionStore.selectedAccounts[0]}`, fn);
    }

    public connectToAccountRoom(accountId: string) {
        return this.request("connectToAccountRoom", {accountId});
    }

    public disconnectFromAccountRoom(accountId: string) {
        return this.request("disconnectFromAccountRoom", {accountId});
    }

    public startListenToMlkProCollectionUpdates(fn: (data: any) => void) {
        this.socket.on(`collection_update`, fn);
    }

    public stopListenToMlkProCollectionUpdates(fn: (data: any) => void) {
        this.socket.off(`collection_update`, fn);
    }
}

export default new SocketService();
