import { BaseAPI } from "@vidazoo/ui-framework";
import ITestPage from "../interfaces/ITestPage";

class TestPagesAPI extends BaseAPI {
    public getAll(page: number = 1, pageSize: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/test_pages/",
            params: { page, pageSize, filter, fields, sort }
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/test_pages/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: "/api/test_pages/",
            data
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/test_pages/update_site/${id}/`,
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/test_pages/${id}/`
        });
    }

}

export default new TestPagesAPI((window as any).__BASE_API_URL__, true);
