import BaseFilter from "./BaseFilter";
import ReportingFilterType from "../ReportingFilterType";

export default class PublisherFilter extends BaseFilter {

    public static type: ReportingFilterType = ReportingFilterType.Publisher;
    private promise: Promise<any>;

    constructor() {
        super();

        this.allowNew = false;
        this.labelKey = "name";
        this.valueKey = "_id";
    }

    public initialize(activity: any): Promise<any> {
        if (this.initialized) {
            return Promise.resolve();
        } else if (this.isLoading && this.promise) {
            return this.promise;
        }

        return this.promise = activity.loadPublishers()
            .then((res) => {
                this.items = res;
                this.isLoading = false;
                this.initialized = true;
            });
    }
}
