import { BaseAPI } from "@vidazoo/ui-framework";
import {Moment} from "moment-timezone";
import {IFilterLog, IReportingLog} from "../interfaces/ILog";

class ReportingLogsAPI extends BaseAPI {

    public async getLogs(from: Moment, to: Moment, searchQuery: string, filter: IFilterLog): Promise<{ data: { results: IReportingLog[] } }> {
        return this.request({
            method: "patch",
            url: `/api/reporting_logs/`,
            data: {from: from.toISOString(), to: to.toISOString(), searchQuery, filter}
        });
    }

}

export default new ReportingLogsAPI(window.__BASE_API_URL__);
