import InitialState from "./InitialState";
import NavigationStore from "./NavigationStore";
import {
    BaseNotificationsStore,
    ProfileStore,
    AccountCredentialsStore,
    UserNotificationsStore,
    UserNotificationsSettingsStore,
    UserNotificationsSocketService
} from "@vidazoo/ui-framework";
import SessionStore from "./SessionStore";
import CurrentUserStore from "./CurrentUserStore";
import DashboardStore from "./DashboardStore";
import ReportingStore from "./reporting/ReportingStore";
import AccountsStore from "./AccountsStore";
import MilkshakeUsersStore from "./MilkshakeUsersStore";
import CreateAccountStore from "./CreateAccountStore";
import EditAccountStore from "./EditAccountStore";
import PublishersStore from "./PublishersStore";
import ScheduleReportsStore from "./scheduleReport/ScheduleReportsStore";
import ScheduleReportStore from "./scheduleReport/ScheduleReportStore";
import CreateScheduleReportStore from "./scheduleReport/CreateScheduleReportStore";
import ScheduleReportsAdminLogsStore from "./scheduleReport/ScheduleReportsAdminLogsStore";
import ScheduleReportsAdminAllStore from "./scheduleReport/ScheduleReportsAdminAllStore";
import DemoWidgetStore from "./DemoWidgetStore";
import DownloadAdsTxtStore from "./DownloadAdsTxtStore";
import SubmitSiteStore from "./site/SubmitSiteStore";
import SitesStore from "./site/SitesStore";
import SiteStore from "./site/SiteStore";
import WidgetsStore from "./WidgetsStore";
import ConnectionsStore from './ConnectionsStore';
import DemandPartnersStore from './DemandPartnersStore';
import UIStore from "./UIStore";
import AccountManagementStore from "./AccountManagementStore";
import TestPagesStore from "./testPages/TestPagesStore";
import VerticalAccountsStore from "./VerticalAccountsStore";
import ReportingLogsStore from "./ReportingLogsStore";
import MediaCatalogStore from "./mediaCatalog/MediaCatalogStore";
import UpdateMetricsStore from "./updateMetrics/UpdateMetricsStore";
import UpdateDimensionsStore from "./updateDimensions/UpdateDimensionsStore";
import GlobalPresetsReportsStore from "./presetReport/GlobalPresetsReportsStore";
import GlobalPresetReportStore from "./presetReport/GlobalPresetReportStore";
import CreateGlobalPresetReportStore from "./presetReport/CreateGlobalPresetReportStore";
import UpdatePointOfContactStore from "./updatePointOfContact/UpdatePointOfContactStore";
import {storageService} from "../services";
import {EditConnectionStore} from './EditConnectionStore';
import {CreateConnectionStore} from './CreateConnectionStore';
import VideosStore from "./VideosStore";
import {ConnectionsMultiCreateStore} from "./ConnectionsMultiCreateStore";
import DomainsListsStore from "./domains/DomainsListsStore";
import {EditDomainListStore} from "./domains/EditDomainListStore";
import CountryListsStore from "./countries/CountryListsStore";
import CountryListStore from "./countries/CountryListStore";

export {default as ActivityContext} from "./ActivityContext";
export {default as RemotePasswordValidator} from "./RemotePasswordValidator";
export const initialState = new InitialState();
export const navigationStore = new NavigationStore();
export const notificationsStore = new BaseNotificationsStore();
export const sessionStore = new SessionStore();
export const currentUserStore = new CurrentUserStore();
export const accountCredentialsStore = new AccountCredentialsStore(notificationsStore, currentUserStore);
export const dashboardStore = new DashboardStore();
export const profileStore = new ProfileStore(notificationsStore);
export const reportingStore = new ReportingStore();
export const accountsStore = new AccountsStore();
export const milkshakeUsersStore = new MilkshakeUsersStore();
export const createAccountStore = new CreateAccountStore();
export const editAccountStore = new EditAccountStore();
export const publishersStore = new PublishersStore();
export const scheduleReportsStore = new ScheduleReportsStore();
export const scheduleReportStore = new ScheduleReportStore();
export const createScheduleReportStore = new CreateScheduleReportStore();
export const scheduleReportsAdminLogsStore = new ScheduleReportsAdminLogsStore();
export const scheduleReportsAdminAllStore = new ScheduleReportsAdminAllStore();
export const submitSiteStore = new SubmitSiteStore(notificationsStore);
export const sitesStore = new SitesStore();
export const siteStore = new SiteStore();
export const demoWidgetStore = new DemoWidgetStore();
export const downloadAdsTxtStore = new DownloadAdsTxtStore();
export const widgetsStore = new WidgetsStore();
export const connectionsStore = new ConnectionsStore();
export const demandPartnersStore = new DemandPartnersStore();
export const videosStore = new VideosStore();
export const editConnectionStore = new EditConnectionStore();
export const createConnectionStore = new CreateConnectionStore();
export const connectionsMultiCreateStore = new ConnectionsMultiCreateStore();
export const uiStore = new UIStore();
export const accountManagementStore = new AccountManagementStore();
export const testPagesStore = new TestPagesStore();
export const verticalAccountsStore = new VerticalAccountsStore();
export const reportingLogsStore = new ReportingLogsStore();
export const mediaCatalogStore = new MediaCatalogStore();
export const updateMetricsStore = new UpdateMetricsStore(notificationsStore);
export const updateDimensionsStore = new UpdateDimensionsStore(notificationsStore);
export const globalPresetsReportsStore = new GlobalPresetsReportsStore();
export const globalPresetReportStore = new GlobalPresetReportStore();
export const createGlobalPresetReportStore = new CreateGlobalPresetReportStore();
export const updatePointOfContactStore = new UpdatePointOfContactStore(notificationsStore);
export const userNotificationsStore = new UserNotificationsStore(UserNotificationsSocketService);
export const userNotificationsSettingsStore = new UserNotificationsSettingsStore(UserNotificationsSocketService, notificationsStore, storageService);
export const domainsListsStore = new DomainsListsStore();
export const editDomainListStore = new EditDomainListStore();
export const countryListsStore = new CountryListsStore();
export const countryListStore = new CountryListStore();
