import {BaseAPI} from "@vidazoo/ui-framework";

class CmsAPI extends BaseAPI {
    public getAccounts() {
        return this.request({
            method: "get",
            url: "/api/accounts/",
        });
    }
}

export default new CmsAPI(window.__CMS_API_URL__, false, ",", 5 * 60 * 1000, true);
