import { action, transaction } from "mobx";
import * as _ from "lodash";
import { BaseListStore } from "@vidazoo/ui-framework";
import { scheduleReportsAPI } from "api";
import { IScheduleReport } from "interfaces/IScheduleReport";
import IUser from "interfaces/IUser";
import { notificationsStore } from "stores";

const SCHEDULE_REPORTS_SEARCHABLE_FIELDS = ["name", "isActive", "schedule", "dateRange", "lastSchedule", "nextSchedule", "user.username"];
const SCHEDULE_REPORTS_LIST_FIELDS = {
    name: 1,
    isActive: 1,
    schedule: 1,
    dateRange: 1,
    lastSchedule: 1,
    nextSchedule: 1,
    created: 1,
    errorCount: 1,
    timezone: 1
};

export default class ScheduleReportsStore extends BaseListStore<IScheduleReport, IUser, string> {
    constructor() {
        super(notificationsStore, SCHEDULE_REPORTS_SEARCHABLE_FIELDS, "schedule report", "name");
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        scheduleReportsAPI.getAll(1, 10000, {}, SCHEDULE_REPORTS_LIST_FIELDS, sort)
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    @action public toggleActiveState = (item: IScheduleReport) => {
        item.isActive = !item.isActive;

        scheduleReportsAPI.active(item._id, item.isActive)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action protected deleteItem = () => {
        scheduleReportsAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    }
}
