import {BaseListStore, MemoryClipboard} from "@vidazoo/ui-framework";
import IWidget from "../interfaces/IWidget";
import {currentUserStore, notificationsStore} from "./index";
import {action, observable, transaction} from "mobx";
import widgetsAPI from "../api/widgetsAPI";
import {storageService} from "../services";

const WIDGETS_SEARCHABLE_FIELDS = ["name"];

export default class WidgetsStore extends BaseListStore<IWidget, any, string> {
    private lastFetch: number;
    @observable public embedScriptItem: IWidget;
    @observable public activityNameByPublisherIds: any;

    constructor() {
        super(notificationsStore, WIDGETS_SEARCHABLE_FIELDS, "widget", "name");
    }

    @action public reset() {
        transaction(() => {
            super.reset();
            this.selectedValues = [];
            this.embedScriptItem = null;
            this.activityNameByPublisherIds = {};
        });
    }

    @action public getItems = (withTtl: number = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this._getActivityNames();
            this.selectedValues = [];
            this.resetSearchQueries();
            this.embedScriptItem = null;
        });

        widgetsAPI.getAll()
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    @action public setEmbedScriptItem = (item) => {
        this.embedScriptItem = item;
    }

    public getEmbedScript(item: IWidget): string {
        return `<script src="https://static.vidazoo.com/basev/vwpt.js" data-widget-id=${item._id}></script>`;
    }

    public copyToClipboard = (item: string) => {
        MemoryClipboard.copy(item);
        notificationsStore.pushNotification({
            title: "copy to Clipboard",
            timeout: 2000,
            success: true
        });
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`widgets`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`widgets`);
        return columnsWidth || {0: 829, 1: 173, 2: 304, 3: 348, 4: 247, 5: 150};
    };

    private _getActivityNames() {
        for (const activity of currentUserStore.currentAccount.activities) {
            for (const publisherId of activity.publisherIds) {
                this.activityNameByPublisherIds[publisherId] = activity.name;
            }
        }
    }
}
