import { BaseAPI } from "@vidazoo/ui-framework";

class UpdatePointOfContactAPI extends BaseAPI {
    public updatePoc(data: any) {
        return this.request({
            method: "patch",
            url: `/api/update_poc/`,
            data
        });
    }
}

export default new UpdatePointOfContactAPI((window as any).__BASE_API_URL__, true);
