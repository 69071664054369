import {observable, action, transaction, computed} from "mobx";
import {activitiesService} from "services";
import {guid} from "@vidazoo/ui-framework";
import IActivity from "interfaces/IActivity";
import IAccount from "interfaces/IAccount";
import {notificationsStore} from "stores";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {accountsAPI, cmsAPI, sellersJsonAPI} from "../../api";
import isValidDomain from "is-valid-domain";
import {ICollection} from "../../interfaces/ICollection";
import {ISeller} from "../../interfaces/ISeller";
import {getDuplicated} from '../../common/utils';
import connectionsAPI from "../../api/connectionsAPI";

export default abstract class BaseAccountStore extends BaseItemStore<IAccount> {

    constructor() {
        super(notificationsStore, "account");
    }

    @observable public selectedActivity: IActivity;
    @observable public vidazooUsers: Array<{ _id: string, email: string }>;
    @observable public isLoadingVidazooUsers: boolean;
    @observable public mlkProCollection: ICollection;

    @observable public cmsAccounts: IAccount[];
    @observable public isLoadingCmsAccounts: boolean;

    @observable public sellers: ISeller[];
    @observable public isLoadingSellers: boolean;

    @observable public possibleOwners: Array<{ value: string, label: string }>;
    @observable public isLoadingPossibleOwners: boolean;

    @observable public connections: Array<{ _id: string, name: string }>;
    @observable public isLoadingConnections: boolean;

    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
                username: "",
                adsTxtPublisherId: "",
                sellerJson: "",
                isActive: true,
                activities: observable([]),
                isInSellers: false,
                disableChangeIsInSellers: false,
                companyDomain: "",
                customName: "",
                vidazooContacts: observable([]),
                allowContentLibrary: false,
                sellerType: "PUBLISHER",
                contactEmail: "",
                contactPhone: "",
                contactAddress: "",
                gamNetworkCode: "",
                gamAdministratorEmail: "",
                contactFullName: "",
                thirdPartyPublishers: false
            };
        });
        this.possibleOwners = observable([]);
        this.isLoadingPossibleOwners = false;

        this.isLoadingVidazooUsers = false;
        this.vidazooUsers = observable([]);

        this.isLoadingCmsAccounts = false;
        this.cmsAccounts = observable.array([], {deep: false});

        this.isLoadingSellers = false;
        this.sellers = observable.array([], {deep: false});

        this.selectedActivity = null;
        this.mlkProCollection = {} as any;

        this.isLoadingConnections = false;
        this.connections = observable.array([], {deep: false});
    }

    @action public addActivity = (type: string, select?: boolean) => {
        const model = activitiesService.getActivity(type);

        if (!model) {
            return;
        }

        transaction(() => {
            const newActivity = {
                guid: guid(),
                type: model.type,
                name: model.defaultName,
                verticalType: model.verticalType,
                groups: model.dimensions,
                fields: model.metrics,
                publisherIds: [],
                dashboards: model.dashboards,
                isNotCreated: true,
                accountIds: []
            } as any;
            this.item.activities = this.item.activities.concat(newActivity);

            if (select) {
                this.selectedActivity = this.item.activities[this.item.activities.length - 1];
            }
        });
    };

    @action public removeActivity = (id: string) => {
        transaction(() => {
            this.item.activities = this.item.activities.filter((activity) => activity._id !== id);

            if (this.item.activities.length === 0) {
                this.selectedActivity = null;
            } else {
                this.selectedActivity = this.item.activities[0];
            }
        });
    };

    @action
    public setSelectedActivity(activity: IActivity) {
        this.selectedActivity = activity;
    }

    @action public setSelectedActivityById = (id: string) => {
        const activity = this.item.activities.find((x) => x._id === id);

        if (activity) {
            this.setSelectedActivity(activity);
        }
    };

    @action public setActivityParam = (activity: IActivity, key: string, value: any) => {
        activity[key] = value;
    };

    @action public addToActivityCollection = (activity: IActivity, collection: string, value: any) => {
        activity[collection] = (activity[collection] || []).concat(value);
    };

    @action public addToActivityAccountId = (activity: IActivity, value: any) => {
        if (value) {
            activity.accountIds = [value];
        } else {
            activity.accountIds = [];
        }
    };

    @action public removeFromActivityCollection = (activity: IActivity, collection: string, value: any) => {
        activity[collection] = activity[collection].filter((x) => x !== value);
    };

    @action public addKeyValuePairToActivityCollection = (activity: IActivity, collection: string) => {
        this.addToActivityCollection(activity, collection, {
            label: "",
            value: ""
        });
    };

    @action public setItemParam = (item: any, key: string, value: any) => {
        item[key] = value;
    };

    @action
    public getVidazooUsers() {
        this.isLoadingVidazooUsers = true;
        accountsAPI.getVidazooUsers()
            .then((res) => this.setVidazooUsers(res))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    private setVidazooUsers(res) {
        this.vidazooUsers = res.data;
        this.isLoadingVidazooUsers = false;
    }

    @action public createNetworkAndCollections = (activity: IActivity) => {

        if ((activity as any).isNotCreated) {
            accountsAPI.update(this.item._id, this.item)
                .then(() => {
                    accountsAPI.getOne(this.item._id)
                        .then((item) => {
                            this.setItem(item.data);
                            this.createCollections(activity);
                        })
                        .catch((err) => this.onLoadFailed(err));
                })
                .catch((err) => this.onSubmitFailed(err));
            return;
        }

        this.createCollections(activity);
    };

    @action public createCollections = (activity: IActivity) => {
        activity.more = {
            isStartCreateNetwork: true
        };
        accountsAPI.createNetworkAndCollections(this.item._id)
            .then((res) => this.startCreateNetworkAndCollections)
            .catch((err) => this.onLoadFailed(err));
    };

    public startCreateNetworkAndCollections = () => {
        notificationsStore.pushSuccessNotification({
            title: "Start Create Network And Collections"
        });
    };

    @computed
    public get isValidCompanyDomain(): boolean {
        if (this.item && this.item.companyDomain) {
            return !isValidDomain(this.item.companyDomain);
        }
    }

    @action public getCmsAccounts = async () => {
        this.isLoadingCmsAccounts = true;
        const res = await cmsAPI.getAccounts();
        this.setCmsAccounts(res);
    };

    @action
    private setCmsAccounts(res) {
        this.cmsAccounts = res.data.results;
        this.isLoadingCmsAccounts = false;
    }

    @action public getSellers = async () => {
        this.isLoadingSellers = true;
        const res = await sellersJsonAPI.getAll();
        this.setSellers(res);
    };

    @action
    private setSellers(res) {
        this.sellers = res.data.results;
        this.isLoadingSellers = false;
    }

    @action
    public getPossibleOwners(id: string) {
        this.isLoadingPossibleOwners = true;

        accountsAPI.getPossibleOwners(id)
            .then((item) => this.setPossibleOwners(item.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    public setPossibleOwners(possibleOwners: Array<{ value: string, label: string }>) {
        transaction(() => {
            this.possibleOwners = possibleOwners;
            this.isLoadingPossibleOwners = false;
        });
    }

    @action
    protected validateNoDuplicatedActivityKeyValue(): boolean {
        let duplicatedGroups = [];
        let duplicatedFields = [];
        this.item.activities.forEach((activity) => {
            duplicatedGroups = duplicatedGroups.concat(getDuplicated<string>(activity.groups.map((g) => g.value)));
            duplicatedFields = duplicatedFields.concat(getDuplicated<string>(activity.fields.map((f) => f.value)));
        });

        console.log(duplicatedGroups, duplicatedFields);
        if (duplicatedGroups.length > 0 || duplicatedFields.length > 0) {
            notificationsStore.pushErrorNotification({
                text: `Duplicated values in activity fields${duplicatedFields.length > 0 ? ` (${duplicatedFields.join(",")})` : ""} or groups${duplicatedGroups.length > 0 ? ` (${duplicatedGroups.join(",")})` : ""}`,
                title: "Update Failed"
            });
            return false;
        }
        return true;
    }

    @action
    public getConnections = (id: string) => {
        this.isLoadingConnections = true;
        connectionsAPI.adminConnections(id)
            .then((res) => this.setConnections(res))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    private setConnections(res) {
        this.connections = [{_id: "", name: "None"}].concat(res.data.results);
        this.isLoadingConnections = false;
    }
}
