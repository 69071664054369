import {action, keys, observable} from "mobx";
import {IUpdateMetrics} from "../../interfaces/IUpdateMetrics";
import {ActivityType} from "../../common/enums";
import {updateMetricsAPI} from "../../api";
import {navigationStore} from "../index";
import {appUrlsService} from "../../services";
import {BaseNotificationsStore} from "@vidazoo/ui-framework";

export default class UpdateMetricsStore {

    @observable public item: IUpdateMetrics;

    constructor(private notificationsStore: BaseNotificationsStore) {
        this.reset();
    }

    @action
    public reset() {
        this.item = {
            activityType: ActivityType.MILKSHAKE_PRO,
            fields: observable.array([], {deep: false})
        };
        this.addField();
    }

    @action public addField = () => {
        this.item.fields.push(observable({value: "", label: ""}));
    };

    @action public deleteField = (index: number) => {
        this.item.fields.splice(index, 1);
    };

    @action public updateField = (index: number, key: string, value: any) => {
        this.item.fields[index][key] = value;
    };

    @action public updateParam = (key: string, value: any) => {
        this.item[key] = value;
    };

    @action
    public submit = (): void => {
        updateMetricsAPI.addFields(this.item)
            .then((res) => this.onSubmitSuccess(res));
    };

    public onSubmitSuccess = (res) => {
        this.notificationsStore.pushSuccessNotification({
            title: "Update Metrics Success",
            timeout: 5000
        });
        navigationStore.push(appUrlsService.accounts());
    };

}
