import {action, keys, observable} from "mobx";
import {ActivityType} from "../../common/enums";
import {updateDimensionsAPI} from "../../api";
import {navigationStore} from "../index";
import {appUrlsService} from "../../services";
import {BaseNotificationsStore} from "@vidazoo/ui-framework";
import {IUpdateDimensions} from "../../interfaces/IUpdateDimensions";

export default class UpdateDimensionsStore {

    @observable public item: IUpdateDimensions;

    constructor(private notificationsStore: BaseNotificationsStore) {
        this.reset();
    }

    @action
    public reset() {
        this.item = {
            activityType: ActivityType.MILKSHAKE_PRO,
            groups: observable.array([], {deep: false})
        };
        this.addGroup();
    }

    @action public addGroup = () => {
        this.item.groups.push(observable({value: "", label: ""}));
    };

    @action public deleteGroup = (index: number) => {
        this.item.groups.splice(index, 1);
    };

    @action public updateGroup = (index: number, key: string, value: any) => {
        this.item.groups[index][key] = value;
    };

    @action public updateParam = (key: string, value: any) => {
        this.item[key] = value;
    };

    @action
    public submit = (): void => {
        updateDimensionsAPI.addGroups(this.item)
            .then((res) => this.onSubmitSuccess(res));
    };

    public onSubmitSuccess = (res) => {
        this.notificationsStore.pushSuccessNotification({
            title: "Update Dimensions Success",
            timeout: 5000
        });
        navigationStore.push(appUrlsService.accounts());
    };

}
