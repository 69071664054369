import * as _ from "lodash";
import { observable, action, computed, transaction, toJS } from "mobx";
import { guid, fastClone } from "@vidazoo/ui-framework";
import { scheduleReportsAPI } from "api";
import { IScheduleReport } from "interfaces/IScheduleReport";
import {currentUserStore, notificationsStore, navigationStore, createScheduleReportStore, sessionStore} from "stores";
import BaseScheduleReportStore, { IBaseScheduleReportStore } from "./BaseScheduleReportStore";
import { appUrlsService } from "services";

export interface IScheduleReportStore extends IBaseScheduleReportStore {
    getItem: (id: string) => void;
    duplicate: () => void;
}

export default class ScheduleReportStore extends BaseScheduleReportStore implements IScheduleReportStore {
    @action public getItem(id: string) {
        this.isLoading = true;

        scheduleReportsAPI.getOne(id)
            .then((res) => this.prepareSetItem(res.data))
            .then((item) => this.unpack(item))
            .then((item) => this.setItem(item, true))
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public duplicate() {
        const item = fastClone(this.item);

        _.unset(item, "_id");

        item.name = "";
        item.description = "";

        createScheduleReportStore.reset();
        createScheduleReportStore.setItem(item);

        navigationStore.push({ pathname: appUrlsService.createScheduleReport(), query: { duplicate: true } });
    }

    @action public submit() {
        scheduleReportsAPI.update(this.item._id, this.submitify())
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public runNow() {
        let data = this.submitify();
        data = {...data, recipients: this.runNowRecipients}
        scheduleReportsAPI.runNow(data._id, data)
            .then(() => this.notificationsStore.pushSuccessNotification({title: "Operation Complete", text: "Preparing your report", timeout: 3000}))
            .catch((err) => this.notificationsStore.pushSuccessNotification({title: "Operation Failed", text: "Failed to get your report", timeout: 3000}))
    }

    @action public delete() {
        scheduleReportsAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.scheduleReports()))
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public async prepareSetItem(item: IScheduleReport) {
        await this._checkItemBelong(item);
        this.setActivity(item.activity);
        this.resetEntries();
        return item;
    }

    @action private async _checkItemBelong(item: IScheduleReport) {
        const user = item.user._id || item.user;
        if (user.toString() !== currentUserStore.currentAccountId) {
            await sessionStore.initializeAccount(user.toString());
        }
    }

    /**
     * backward compatibility with some old connections-client shit :(
     *
     * @private
     * @param {IScheduleReport} scheduleReport
     * @returns
     * @memberof ScheduleReportStore
     */
    private unpack(scheduleReport: IScheduleReport) {
        if (scheduleReport.timezone === "Etc/GMT") {
            scheduleReport.timezone = "Etc/GMT+0";
        }

        if (scheduleReport.constraints.length) {
            _.forEach(scheduleReport.constraints, (constraint) => {
                constraint.id = guid();

                if (constraint.op === "==") {
                    constraint.op = "=";
                }
            });
        }

        if (scheduleReport.filters.length) {
            _.forEach(scheduleReport.filters, (filter) => {
                filter.id = guid();

                switch (filter.key) {
                    case "publisherId": filter.key = "publisherName"; break;
                    case "scenarioId": filter.key = "scenarioName"; break;
                    // case "connectionId": filter.key = "connectionName"; break;
                    case "tagId": filter.key = "tagName"; break;
                    case "abTestId": filter.key = "abTestName"; break;
                }
            });
        }

        return scheduleReport;
    }
}
