import * as _ from "lodash";
import IActivityTypeModel from "interfaces/IActivityTypeModel";

class ActivitiesService {

    private _index;

    public initialize(activities: IActivityTypeModel[]) {
        this._index = _.keyBy<IActivityTypeModel>(activities, "type");
    }

    public getActivity(type: string): IActivityTypeModel {
        const activity = this._index[type];
        if (!activity) {
            throw new Error("activity type not found: " + type);
        }
        return activity;
    }

}

export default new ActivitiesService();
