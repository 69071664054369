import {currentUserStore} from "stores";

class AppUrlsService {
    public app(): string {
        return `/`;
    }

    public accounts(): string {
        return `/accounts`;
    }

    public createAccount(): string {
        return `${this.account()}/create`;
    }

    public editAccount(id = ":id"): string {
        return `${this.account()}/${id}/edit`;
    }

    public account(): string {
        return `/account`;
    }

    public tags(): string {
        return `/tags`;
    }

    public dashboardActivity(): string {
        return `/dashboard/:activity?`;
    }

    public reportingActivity(): string {
        return `/reporting/:activity?`;
    }

    public dashboard(name: string): string {
        return `/dashboard/${name}`;
    }

    public reporting(name: string): string {
        return `/reporting/${name}`;
    }

    public defaultDashboard(path: string) {
        return path ? `/dashboard/${path}` : "";
    }

    public scheduleReports(): string {
        return `/schedule-reports`;
    }

    public scheduleReport(): string {
        return `/schedule-report`;
    }

    public createScheduleReport(): string {
        return `${this.scheduleReport()}/create`;
    }

    public editScheduleReport(id = ":id"): string {
        return `${this.scheduleReport()}/${id}/edit`;
    }

    public onboardingIntro(): string {
        return `/onboarding-intro`;
    }

    public onboardingDownloadAdsTxt(): string {
        return `/onboarding-download-ads-txt`;
    }

    public onboardingDemoWidget(): string {
        return `/onboarding-demo-widget`;
    }

    public onboardingCompanyLegal(): string {
        return `/onboarding-company-legal-info`;
    }

    public demoWidget(): string {
        return `/demo-widget`;
    }

    public submitSite(): string {
        return `/submit-site-list`;
    }

    public siteList(): string {
        return `/site-list`;
    }

    public site(): string {
        return `/site`;
    }

    public editSite(id = ":id"): string {
        return `${this.site()}/${id}/edit`;
    }

    public downloadAdsTxt(): string {
        return `/download-ads-txt`;
    }

    public widgets(): string {
        return `/widgets`;
    }

    public connections(): string {
        return `/connections`;
    }

    public connection(): string {
        return `/connection`;
    }

    public createConnection(): string {
        return `${this.connection()}/create`;
    }

    public createConnectionMulti(): string {
        return `${this.connection()}/create_multi`;
    }

    public editConnection(id = ":id"): string {
        return `${this.connection()}/${id}/edit`;
    }

    public domainsLists(): string {
        return `/domains_lists`;
    }

    public domainsList(): string {
        return `/domains_list`;
    }

    public editDomainsList(id = ":id"): string {
        return `${this.domainsList()}/${id}/edit`;
    }

    public countryLists(): string {
        return `/country_lists`;
    }

    public countryList(): string {
        return `/country_list`;
    }

    public editCountryList(id = ":id"): string {
        return `${this.countryList()}/${id}/edit`;
    }

    public demandPartners(): string {
        return `/demand_partners`;
    }

    public milkshakeUsers(): string {
        return `/milkshake-users`;
    }

    public accountManagement(): string {
        return `/account-management`;
    }

    public adminLogsScheduleReports(): string {
        return `/schedule-reports-admin-logs`;
    }

    public adminScheduleReportsAll(): string {
        return `/schedule-reports-admin-all`;
    }

    public testPages(): string {
        return `/test-pages`;
    }

    public testPage(): string {
        return `/test-page`;
    }

    public createTestPage(): string {
        return `${this.testPage()}/create`;
    }

    public editTestPage(id = ":id"): string {
        return `${this.testPage()}/${id}/edit`;
    }

    public publishersReportingLogs(): string {
        return `/reporting-logs`;
    }

    public contentLibrary(): string {
        return `/content-library`;
    }

    public cms(activityName = ":activityName"): string {
        return `/cms/${activityName}`;
    }

    public updateMetrics(): string {
        return `/update-metrics`;
    }

    public updateDimensions(): string {
        return `/update-dimensions`;
    }

    public globalPresetsReports(): string {
        return `/global-presets-reports`;
    }

    public globalPresetReport(): string {
        return `/global-preset-report`;
    }

    public createGlobalPresetReport(): string {
        return `${this.globalPresetReport()}/create`;
    }

    public editGlobalPresetReport(id = ":id"): string {
        return `${this.globalPresetReport()}/${id}/edit`;
    }

    public updatePointOfContact(): string {
        return `/update-point-of-contact`;
    }

    public notificationCenter(): string {
        return `/notification-center`;
    }

    public notificationSettings(): string {
        return `/notification-settings`;
    }

    public videos(): string {
        return `/videos`;
    }
}

export default new AppUrlsService();
