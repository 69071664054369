import { BaseAPI } from "@vidazoo/ui-framework";

class MediaCatalogAPI extends BaseAPI {

    public getCatalog(language: string, filters: any) {
        return this.request({
            method: "get",
            url: "/api/cms/catalog",
            params: {language, filters}
        });
    }

}

export default new MediaCatalogAPI(window.__BASE_API_URL__, true);
