import { BaseAPI } from "@vidazoo/ui-framework";

class VideosAPI extends BaseAPI {

    public getAll(pubDate: number) {
        return this.request({
            method: "get",
            url: "/api/videos/",
            params: { pubDate }
        });
    }
}

export default new VideosAPI((window as any).__BASE_API_URL__, true);
