import {observable, action, transaction, computed} from "mobx";
import {socketService, activitiesService, reportingService} from "services";
import {currentUserStore, dashboardStore, navigationStore, reportingStore} from "stores";
import IUser from "interfaces/IUser";
import {accountsAPI, viewAsAPI} from "api";
import IAccount from "interfaces/IAccount";
import * as _ from "lodash";
import IActivityTypeModel from "interfaces/IActivityTypeModel";
import {IReportingMetadataByVertical} from "../interfaces/reporting/IReportingMetadata";
import {UserNotificationsSocketService} from "@vidazoo/ui-framework";

export default class SessionStore {
    @observable public isAuthenticated: boolean;
    @observable public isAuthenticating: boolean;
    @observable public accessToken: string;
    @observable public authError: any;
    @observable public selectedAccounts: string[];
    @observable public usersToViewAs: Array<{ email: string, _id: string }>;
    public accounts: IAccount[];
    public indexAccounts: any;
    private scopeApiUrl: string;

    constructor() {
        (window as any).__sdk__.auth.on("session:change", (err, res, reload) => this.onSessionChange(err, res, reload));

        this.isAuthenticated = false;
        this.isAuthenticating = true;
        this.accessToken = null;
        this.authError = null;
    }

    @action public setSelectedAccounts = async (accounts: string[], reload?: boolean) => {
        window.__sdk__.auth.setSelectedAccounts(accounts);

        const account = this.accounts.find((item) => item._id === accounts[0]);
        currentUserStore.updateCurrentAccountActivities(account.activities);
        currentUserStore.updateCurrentAccountOnboardingStatus(account.onboardingStatus);
        this.selectedAccounts = accounts;

        window.location.pathname.includes("dashboard") && navigationStore.push("/");
        if (reload) {
            window.location.reload();
        }
    };

    @action
    private async onSessionChange(err, response, reload) {
        if (reload) {
            window.location.reload();
        } else if (response) {

            if (process.env.NODE_ENV === "production" || process.env.PROD_API) {
                const scope = window.__sdk__.auth.session.user.scopes.find((scopeRow) => scopeRow.scope === window.__sdk__.auth._scope);
                if (scope && scope.apiUrl) {
                    window.__sdk__.network.changeInterceptUrls([new RegExp(scope.apiUrl), new RegExp((window as any).__CMS_API_URL__)]);
                    this.scopeApiUrl = scope.apiUrl;
                    window.__BASE_API_URL__ = scope.apiUrl;
                }
            }

            this.accessToken = response.accessToken;

            const account = await accountsAPI.me();

            this.checkForViewAsQuery(response, account);

            this.onLogin(response.accessToken, account.data);
        }
    }

    @action
    public initialize() {
        (window as any).__sdk__.auth.authorize();
    }

    @action public logout = () => {
        (window as any).__sdk__.auth.logout();
    };

    @computed
    public get userAccounts(): IAccount[] {
        return this.accounts;
    }

    @computed
    public get getSelectedAccountsNames(): string {
        const selectedAccountsNames = this.selectedAccounts.map((id) => this.indexAccounts[id].username);
        return selectedAccountsNames.join(" | ");
    }

    @action
    private async onLogin(accessToken: string, data: { auth: IUser, accounts: IAccount[], activities: IActivityTypeModel[], reportingMetaData: IReportingMetadataByVertical }) {

        this.selectedAccounts = (window as any).__sdk__.auth.selectedAccounts;

        if (!this.selectedAccounts.length) {
            this.setSelectedAccounts([data.accounts[0]._id], false);
        }

        this.accounts = data.accounts;
        this.indexAccounts = _.keyBy(this.accounts, "_id");

        reportingService.initialize(data.reportingMetaData);

        activitiesService.initialize(data.activities);

        const currentAccount = this.indexAccounts[this.selectedAccounts[0]];

        currentUserStore.initialize(data.auth, currentAccount);

        if (currentUserStore.isVidazooOrganization) {
            try {
                await UserNotificationsSocketService.initialize((window.__USER_NOTIFICATIONS_API_URL__), this.accessToken, false);

            } catch (err) {
                this.onLoginFailed(err);
            }
        }

        socketService.initialize((this.scopeApiUrl || (window as any).__BASE_API_URL__), this.accessToken, false)
            .then(() => {
                this.onSocketConnected();
                currentUserStore.getDefaultActivityName();
                reportingStore.reset();
                reportingStore.initAfterAllInitialize();
                reportingStore.initializeColumnsWidth();
            })
            .catch((err) => this.onLoginFailed(err));

    }

    private checkForViewAsQuery(response, account) {
        const search = location.search;
        const searchSplited = search ? search.split("?") : [];
        if (searchSplited.length > 1) {
            const splited = searchSplited[1].split("=");
            if (splited[0] === "viewas") {
                if (response.params && response.params.isViewAs) {
                    window.location.search = "";
                } else {
                    if (account.data.auth.roles.find((role) => role === "VIEW_AS")) {
                        window.__sdk__.auth.viewAs(splited[1]);
                    }
                }
            }
        }
    }

    @action
    private onSocketConnected() {
        transaction(() => {
            this.isAuthenticated = true;
            this.isAuthenticating = false;
        });
    }

    @action
    private onLoginFailed(err) {
        this.accessToken = null;
        this.isAuthenticated = false;
        this.isAuthenticating = false;
        this.authError = err;
    }

    public hasAccountPermission = (id: string): boolean => {
        return this.accounts.some((account) => account._id === id);
    };

    @action public getUsersToViewAs = () => {
        if (this.usersToViewAs) {
            return;
        }
        viewAsAPI.getUsersToViewAs()
            .then((res) => this.setUsersToViewAs(res));
    };

    @action private setUsersToViewAs = (res) => {
        this.usersToViewAs = res.data;
    };

    @action public initializeAccount = async (id: string) => {
        const currentAccount = this.indexAccounts[id];
        if (currentAccount) {
            await this.setSelectedAccounts([currentAccount._id], true);
        }
    };
}
