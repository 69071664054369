import BaseFilter from "./BaseFilter";
import ReportingFilterType from "../ReportingFilterType";
import countries from "@vidazoo/ui-framework/lib/common/countries";

export default class CountryFilter extends BaseFilter {

    public static type: ReportingFilterType = ReportingFilterType.Country;

    constructor() {
        super();

        this.items = countries;
        this.labelKey = "name";
        this.valueKey = "code";
        this.allowNew = false;
    }
}
