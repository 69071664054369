import {observable, action, computed, transaction} from "mobx";
import * as _ from "lodash";
import BaseItemStore, {IBaseItemStore} from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {currentUserStore, notificationsStore, navigationStore} from "..";
import exportToCsvService from "services/exportToCsvService";
import {ICountryList, ICountry} from "interfaces/ICountryList";
import countries from "@vidazoo/ui-framework/lib/common/countries";
import * as Papa from "papaparse";
import {countryListsAPI} from "../../api";
import {appUrlsService} from "../../services";

export interface IBaseCountryListStore extends IBaseItemStore<ICountryList> {
    removeCountry: (code: string) => void;
    addCountry: (country: ICountry) => void;
    uploadFile: (e: any) => void;
    downloadCSV: () => void;
}

export default class CountryListStore extends BaseItemStore<ICountryList> implements IBaseCountryListStore {

    constructor() {
        super(notificationsStore, "country list");
    }

    @action
    public getItem(id: string) {
        this.isLoading = true;
        this.loadError = false;

        countryListsAPI.getOne(id)
            .then((item) => this.setItem(item.data));
    }

    @action
    public setItem(item) {
        if (!item.totalCountries) {
            item.totalCountries = item.countries.length;
        }
        this.item = item;
        this.isLoading = false;
    }

    @action
    public submit() {
        countryListsAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public addCountry = (country: ICountry) => {
        this.item.countries = [...this.item.countries, country];
    };

    @action public removeCountry = (code: string) => {
        this.item.countries = this.item.countries.filter((item) => item.code !== code);
    };

    @action public uploadFile = (e) => {
        const files = e.target.files;

        if (!files || !files.length) {
            return;
        }

        const reader = new FileReader();
        reader.readAsText(files[0], "UTF-8");

        reader.onloadend = this.onLoadFile.bind(this);
    };

    @action private onLoadFile = (event) => {
        const nameIndex = _.keyBy(countries, (country) => country.name.toUpperCase());

        const codeIndex = _.keyBy(countries, "code");

        const csv: { data: any[] } = Papa.parse(event.target.result);

        transaction(() => {
            csv.data.forEach(action((line: string[]) => {

                if (line) {
                    const country: any = nameIndex[line[0].toUpperCase()] || codeIndex[line[0].toUpperCase()];

                    country && (this.item.countries = [...this.item.countries, country]);
                }
            }));
            this.uniqCountries();
        });
    };

    @action public downloadCSV = () => {
        exportToCsvService.exportCountryList(this.item.countries);
    };

    @action public uniqCountries = () => {
        this.item.countries = _.uniqBy(this.item.countries, "code");
    };
}
