import * as React from "react";
import {DataGridRow, DataGridColumn} from "@vidazoo/ui";
import * as moment from "moment";
import {Link} from "react-router-dom";
import {appUrlsService} from "services";
import IMilkshakeUsers from "interfaces/IMilkshakeUsers";
import * as theme from "../../../accounts/components/accountRow/theme.scss";

export interface IMilkshakeUserRowProps {
    item: IMilkshakeUsers;
    sortBy: string;
    hasAccountPermission?: (id: string) => boolean;
    columnsWidth: any;
    freezeColumnIndex: number;
    index: number;
}

export interface IMilkshakeUserRowState {

}

export default class MilkshakeUserRow extends React.Component<IMilkshakeUserRowProps, IMilkshakeUserRowState> {

    constructor(props, context) {
        super(props, context);
    }

    public render(): JSX.Element {
        const {item, hasAccountPermission, index, columnsWidth, freezeColumnIndex} = this.props;

        return (
            <DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index} freezeColumnIndex={freezeColumnIndex}>
                <DataGridColumn className={theme.column}>{item.email}</DataGridColumn>
                <DataGridColumn className={theme.column}>{item.firstName}</DataGridColumn>
                <DataGridColumn className={theme.column}>{item.lastName}</DataGridColumn>
                <DataGridColumn className={theme.column}>
                    {(hasAccountPermission(item.accountId) ? (
                        <Link to={appUrlsService.editAccount(item.accountId)}>{item.accountName}</Link>
                    ) : item.accountName)}
                </DataGridColumn>
                <DataGridColumn className={theme.column}>{item.lastLogin ? moment(item.lastLogin).format("MMM DD, YYYY, HH:mm") : "Not Signin"}</DataGridColumn>
                <DataGridColumn className={theme.column}>{moment(item.created).format("MMM DD, YYYY, HH:mm")}</DataGridColumn>
            </DataGridRow>
        );
    }
}
