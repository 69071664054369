import { BaseAPI } from "@vidazoo/ui-framework";

class UpdateMetricsAPI extends BaseAPI {
    public addFields(data: any) {
        return this.request({
            method: "patch",
            url: `/api/update_metrics/`,
            data
        });
    }
}

export default new UpdateMetricsAPI((window as any).__BASE_API_URL__, true);
