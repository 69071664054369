import {BaseListStore} from '@vidazoo/ui-framework';
import {action, transaction} from 'mobx';
import {notificationsStore} from '../index';
import {storageService} from '../../services';
import {countryListsAPI} from "../../api";
import {ICountryList} from "../../interfaces/ICountryList";

const SEARCHABLE_FIELDS = ["name"];

export default class CountryListsStore extends BaseListStore<ICountryList, any, string> {

    private lastFetch: number;

    constructor() {
        super(notificationsStore, SEARCHABLE_FIELDS, "countryLists", "updated");
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();
            this.selectedValues = [];
        });
    }

    @action public getItems = (withTtl = 3000) => {
        if (this.isLoading) {
            return;
        }

        const now = Date.now();

        if (now - this.lastFetch <= withTtl) {
            return;
        }

        this.lastFetch = now;

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
        });

        countryListsAPI.getAll()
            .then((res) => {
                this.onLoadItemsSuccess(res);
            })
            .catch((res) => this.onLoadItemsError(res));
    };

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`countryLists`, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const columnsWidth = storageService.getColumnsWidth(`countryLists`);
        return columnsWidth || {0: 500, 1: 200, 2: 200, 3: 200, 4: 150};
    };
}

